import React from "react";
import { BrowserRouter, Route, Link } from 'react-router-dom'
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBInput, MDBChip, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModal
} from 'mdbreact';
import ItemListCell from '../../components/Item/ItemListCell';
import ItemImage from '../../components/Item/ItemImage';
import "./ItemsTableList.css";
import CartSelect from "../CartSelect/CartSelect";
import OtasukeCartSelect from "../CartSelect/OtasukeCartSelect";
import FavoriteCartSelect from "../CartSelect/FavoriteCartSelect";
import firebase from "../../firebase";
import FILELink from './FILELink'
import * as Excel from "exceljs";
import moment from "moment";
import { toast } from 'react-toastify';
import Typesense from "typesense";
import DataGrid, {
  Column,
  ColumnChooser,
  Paging,
  FilterRow, ColumnFixing, Scrolling, Export, Pager, ColumnChooserSearch, Sorting,RemoteOperations
} from "devextreme-react/data-grid";
import {saveAs} from "file-saver";
const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);
class ItemsListV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbItems: [],
      last: null,
      itemsPerPage: 12,
      changed: false,
      searchword: '',
      searchwordA: '',
      algoria: false,
      algoriaPage: 1,

      searchwordA1: '',
      algoria1: false,
      algoriaPage1: 1,

      searchwordA2: '',
      algoria2: false,
      algoriaPage2: 1,

      cartselect: false,
      otasukeCartSelect: false,
      favoriteCartSelect: false,
      selectedItem: {},
      loadedAllItem: false,
      selectedItemList: [],
      cn: null,

      lastUpdateItem: null,
      lastStockItem: null,

      selectSDS: false,
      selectSDSItem: {},
      sdsManagementModal: false,
      months: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false
      },
      sdsManagementConfirmModal: false,
      sdsManagementCompleteModal: false,
      excelLoading: false,
      isSearch: false,
    }
  }

  getItems = (reset = false, word = '') => {
    if (this.state.loaded) {
      return;
    }
    if (this.state.loading) {
      return;
    }
    if(!this.props.userOrg){
      return;
    }
    if (this.state.loadedAllItem) {
      return;
    }

    //console.log("last",this.state.last);

    let searchWords = [];
    word = word.toUpperCase().replace(/[\!-\~]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
    let words = word.split(/\s+/);
    for (  var i = 0;  i < words.length;  i++  ) {
      for (  var j = 0;  j < words[i].length - 1;  j++  ) {
        searchWords.push(words[i][j] + words[i][j+1]);
      }
    }

    this.setState({loading: true, algoria: false, algoria1: false, algoria2: false,})
    let first;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      first = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
          .limit(this.state.itemsPerPage);

      if (this.state.selectSDS) {
        first = first.where('files_names', 'array-contains-any', ["ＳＤＳ", "sds", "SDS", "ｓｄｓ", "ＳＤＳ１"])
      }

      if(searchWords.length > 0){
        searchWords.forEach(word => {
          first = first.where(`tokenMap.${word}`, '==', true);
        });
      }
      // else{
      //   first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      // }

      if (!this.state.selectSDS && searchWords.length === 0) {
        first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      }

      if(this.state.last && !reset){
        first = first.startAfter(this.state.last)
      }
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      first = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
          .limit(this.state.itemsPerPage);

      if (this.state.selectSDS) {
        first = first.where('files_names', 'array-contains-any', ["ＳＤＳ", "sds", "SDS", "ｓｄｓ", "ＳＤＳ１"])
      }

      if(searchWords.length > 0){
        searchWords.forEach(word => {
          first = first.where(`tokenMap.${word}`, '==', true);
        });
      }
      // else{
      //   first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      // }

      if (!this.state.selectSDS && searchWords.length === 0) {
        first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      }

      if(this.state.last && !reset){
        first = first.startAfter(this.state.last)
      }
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      // console.log(1,this.props.userOrg.id)
      first = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
          .limit(this.state.itemsPerPage);

      if (this.state.selectSDS) {
        first = first.where('files_names', 'array-contains-any', ["ＳＤＳ", "sds", "SDS", "ｓｄｓ", "ＳＤＳ１"])
      }

      if(searchWords.length > 0){
        searchWords.forEach(word => {
          first = first.where(`tokenMap.${word}`, '==', true);
        });
      }
      // else{
      //   first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      // }

      if (!this.state.selectSDS && searchWords.length === 0) {
        first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      }

      if(this.state.last && !reset){
        first = first.startAfter(this.state.last)
      }
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      // console.log(2,this.props.org.id)
      first = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
          .limit(this.state.itemsPerPage);

      if (this.state.selectSDS) {
        first = first.where('files_names', 'array-contains-any', ["ＳＤＳ", "sds", "SDS", "ｓｄｓ", "ＳＤＳ１"])
      }
      // else {
      //   first = first.where('sds', '==', null)
      // }

      if(searchWords.length > 0){
        searchWords.forEach(word => {
          first = first.where(`tokenMap.${word}`, '==', true);
        });
      }
      // else{
      //   first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      // }

      if (!this.state.selectSDS && searchWords.length === 0) {
        first = first.orderBy('P4CLOUD商品名ふりがな','asc')
      }

      if(this.state.last && !reset){
        first = first.startAfter(this.state.last)
      }
    }

    if(first){
      // console.log('first', this.state.dbItems.length);
      // if (this.state.dbItems.length > 0) {
      //   this.setState({selectedItemList: []})
      // }
      let paginate = first.get()
          .then((snapshot) => {
            this.setState({loading: false})
            if(snapshot.docs.length > 0) {
              let last = snapshot.docs[snapshot.docs.length - 1];

              this.setState({last: last})

              let items = this.state.dbItems;
              snapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                items.push(data)
              })
              // console.log('items', items)
              this.setState({dbItems: items})
            } else {
              console.log('no doc more');
              this.setState({loadedAllItem: true});
            }
          });
    }
    else {
      this.setState({loading: false, selectedItemList:[]})
    }

  }

  // updateExpireDate = async () => {
  //   let itemsSell;
  //   if (this.state.lastUpdateItem) {
  //     itemsSell = await this.props.db.collection('items_sell').startAfter(this.state.lastUpdateItem).limit(1).get();
  //   } else {
  //     itemsSell = await this.props.db.collection('items_sell').limit(1).get();
  //   }
  //   // return;
  //   let promises = [];
  //   for (let i=0; i<itemsSell.docs.length; i++) {
  //     let doc = itemsSell.docs[i];
  //     let lastUpdateItem = itemsSell.docs[itemsSell.docs.length - 1];
  //     this.setState({lastUpdateItem: lastUpdateItem});
  //
  //     console.log('itemsSell', doc.id, itemsSell.docs.length);
  //
  //     let items = await this.props.db.collection('items_sell').doc(doc.id).collection('items').get();
  //     console.log(items.docs.length)
  //     for (let a=0; a<items.docs.length; a++) {
  //       let sub_doc = items.docs[a]
  //       let data = sub_doc.data();
  //       // console.log(data)
  //       if (data.price && data.price.length > 0 &&
  //           data.price[0].hasOwnProperty('price') &&
  //           data.price[0].hasOwnProperty('expiration_date') &&
  //           data.price[0].expiration_date
  //       ) {
  //         // console.log(data.price[0].expiration_date)
  //         promises.push(sub_doc.ref.set({price: [{expiration_date: firebase.firestore.Timestamp.fromDate(new Date("2099-12-01")), price: data.price[0].price}]}, {merge: true}))
  //         // console.log(data, {price: [{expiration_date: firebase.firestore.Timestamp.fromDate(new Date("2099-12-01")), price: data.price[0].price}]})
  //       }
  //     }
  //   }
  //
  //   await Promise.all(promises);
  //   console.log('update all items expiration_date');
  // }

  updateItemStockExpireDate = async () => {
    let itemStock;
    let itemStockRef;
    if (this.state.lastStockItem) {
      // this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
      itemStockRef = this.props.db.collection('items_stock')
      itemStock = await itemStockRef.startAfter(this.state.lastStockItem).get();
    } else {
      itemStockRef = this.props.db.collection('items_stock')
      itemStock = await itemStockRef.get();
    }

    let promises = [];
    for (let i=0; i<itemStock.docs.length; i++) {
      let doc = itemStock.docs[i];
      console.log(doc.id)
      let buyers =  await itemStockRef.doc(doc.id).collection('buyers').get();
      console.log(buyers.docs.length)
      for (let a=0; a<buyers.docs.length; a++) {
        let buyer_doc = buyers.docs[a];
        console.log(buyer_doc.id)
        let items = await itemStockRef.doc(doc.id).collection('buyers').doc(buyer_doc.id).collection('items').get();
        console.log(items.docs.length)

        for (let b=0; b<items.docs.length; b++) {
          let item_doc = items.docs[b];
          let data = item_doc.data();

          if (data.price && data.price.length > 0 &&
              data.price[0].hasOwnProperty('price') &&
              data.price[0].hasOwnProperty('expiration_date') &&
              data.price[0].expiration_date
          ) {
            if (data.price[0].expiration_date.seconds !== 4099766400) {
              promises.push(item_doc.ref.set({price: [{expiration_date: firebase.firestore.Timestamp.fromDate(new Date("2099-12-01")), price: data.price[0].price}]}, {merge: true}))
            }
            // console.log(data.price[0].expiration_date.seconds === 4099766400)
            // console.log(data, {price: [{expiration_date: firebase.firestore.Timestamp.fromDate(new Date("2099-12-01")), price: data.price[0].price}]})
          }
        }
      }
    }

    await Promise.all(promises);
    console.log('update all itemStock expiration_date', promises.length);
  }

  updateItemStockSDS = async () => {
    let itemStock;
    let itemStockRef;
    if (this.state.lastStockItem) {
      // this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
      itemStockRef = this.props.db.collection('items_stock')
      itemStock = await itemStockRef.startAfter(this.state.lastStockItem).get();
    } else {
      itemStockRef = this.props.db.collection('items_stock')
      itemStock = await itemStockRef.get();
    }

    let promises = [];
    for (let i=0; i<itemStock.docs.length; i++) {
      let doc = itemStock.docs[i];
      // console.log(doc.id)
      let buyers =  await itemStockRef.doc(doc.id).collection('buyers').get();
      console.log(buyers.docs.length)
      for (let a=0; a<buyers.docs.length; a++) {
        let buyer_doc = buyers.docs[a];
        // console.log(buyer_doc.id)
        let items = await itemStockRef.doc(doc.id).collection('buyers').doc(buyer_doc.id).collection('items').get();
        // console.log(items.docs.length)

        for (let b=0; b<items.docs.length; b++) {
          let item_doc = items.docs[b];
          let data = item_doc.data();
          // P4CLOUD登録日, カタログ１発行年月日, 新規登録依頼日
          let fValue = 'P4CLOUD登録日';
          if ((typeof data[fValue]) != 'object' && data[fValue]) {
            // console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
            // console.log(data[fValue])
            if (data[fValue] === 1) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2018-01-01 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 44719) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2022-06-07 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 44700) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2022-05-19 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 44816) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2022-09-12 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 44699) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2022-05-18 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 201701) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2017-01-31 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 201802) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2018-02-28 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 201807) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2018-07-31 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 2020325) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2020-03-31 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 2021042) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2021-04-30 00:00:00")}, {merge: true}));
            }

            if (data[fValue] === 2021070) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue])
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date("2021-07-31 00:00:00")}, {merge: true}));
            }

            if (data[fValue].toString().length === 8) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue], data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(6,8)+" 00:00:00")
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date(data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(6,8)+" 00:00:00")}, {merge: true}));
            }

            if (data[fValue].toString().length === 9) {
              console.log(doc.id, buyer_doc.id, item_doc.id, data[fValue], data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(7,9)+" 00:00:00")
              promises.push(item_doc.ref.set({'P4CLOUD登録日': new Date(data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(7,9)+" 00:00:00")}, {merge: true}));
            }
          }

          // if (data.sdsManagement) {
          //   // console.log('sdsManagement itemId', item_doc.id)
          //   let snapSDS = await this.props.db.collection("sds_management").where("itemId", "==", item_doc.id).get();
          //   if (snapSDS.docs.length > 0) {
          //     let sdsManagementData = snapSDS.docs[0].data();
          //     console.log(sdsManagementData.item, data)
          //     // if (snapSDS.docs[0].data()) {}
          //     snapSDS.docs[0].ref.set({item: data}, {merge: true});
          //   }
          // }

          // if (data.files && data.files.length > 0) {
          //   // for (let i=0; i<data.files.length; i++) {
          //   //   let file = data.files[i];
          //   //   if (file.name && !file.file) {
          //   //     let files = data.files.filter(item => item.file);
          //   //     promises.push(item_doc.ref.set({files: files}, {merge: true}));
          //   //     // let names = data.files.filter(item => item.file).map(function(item) {
          //   //     //   return item['name'];
          //   //     // });
          //   //     // promises.push(item_doc.ref.set({files_names: names}, {merge: true}));
          //   //   }
          //   // }
          //
          //   if (!data.files_names) {
          //     console.log('not found files_names stock')
          //     let names = data.files.filter(item => item.file).map(function(item) {
          //       return item['name'].trim();
          //     });
          //     promises.push(item_doc.ref.set({files_names: names}, {merge: true}));
          //   }
          // } else {
          //   if (!data.files_names) {
          //     promises.push(item_doc.ref.set({files_names: []}, {merge: true}));
          //   }
          // }
        }
      }
    }

    console.log('promises start', promises.length)
    // await Promise.all(promises);
    console.log('update all itemStock expiration_date', promises.length);
  }

  updateSDSitemSell = async () => {
    let itemsSell;
    if (this.state.lastUpdateItem) {
      itemsSell = await this.props.db.collection('items_sell').startAfter(this.state.lastUpdateItem).get();
    } else {
      itemsSell = await this.props.db.collection('items_sell').get();
    }
    // return;
    let promises = [];
    console.log('length', itemsSell.docs.length)
    for (let i=0; i<itemsSell.docs.length; i++) {
      let doc = itemsSell.docs[i];
      let lastUpdateItem = itemsSell.docs[itemsSell.docs.length - 1];
      this.setState({lastUpdateItem: lastUpdateItem});

      // console.log('itemsSell', doc.id, itemsSell.docs.length);

      let items = await this.props.db.collection('items_sell').doc(doc.id).collection('items').get();
      // console.log(items.docs.length)
      for (let a=0; a<items.docs.length; a++) {
        let sub_doc = items.docs[a]
        let data = sub_doc.data();
        // console.log((typeof data[fValue])) // P4CLOUD登録日, カタログ１発行年月日, 新規登録依頼日
        let fValue = 'P4CLOUD登録日';
        if ((typeof data[fValue]) != 'object' && data[fValue]) {
          console.log(doc.id, sub_doc.id, data[fValue])

          if (doc.id === 'L2xgX5dzpRRY8dqxS2Ie' && data[fValue] === 1) {
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2023-09-14 00:00:00")}, {merge: true}))
          }

          if (doc.id === 'TcXTP1MCS4nYzPE1ZBP5' && data[fValue] === 1) {
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2018-01-01 00:00:00")}, {merge: true}))
          }

          if (data[fValue] === 1) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2018-01-01 00:00:00")}, {merge: true}));
          }
          if (data[fValue] === 1) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2018-01-01 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 44719) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2022-06-07 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 44700) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2022-05-19 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 44816) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2022-09-12 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 44699) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2022-05-18 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 201701) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2017-01-31 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 201802) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2018-02-28 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 201807) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2018-07-31 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 2020325) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2020-03-31 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 2021042) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2021-04-30 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 2021070) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2021-07-31 00:00:00")}, {merge: true}));
          }

          if (data[fValue] === 2020902) {
            console.log(doc.id, sub_doc.id, data[fValue])
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date("2020-09-02 00:00:00")}, {merge: true}));
          }

          if (data[fValue].toString().length === 8) {
            console.log(doc.id, sub_doc.id, data[fValue], data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(6,8)+" 00:00:00")
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date(data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(6,8)+" 00:00:00")}, {merge: true}));
          }

          if (data[fValue].toString().length === 9) {
            console.log(doc.id, sub_doc.id, data[fValue], data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(7,9)+" 00:00:00")
            promises.push(sub_doc.ref.set({'P4CLOUD登録日': new Date(data[fValue].toString().slice(0,4)+"-"+data[fValue].toString().slice(4,6)+"-"+data[fValue].toString().slice(7,9)+" 00:00:00")}, {merge: true}));
          }
        }

        // if (data.sdsManagement) {
        //   console.log('sdsManagement itemId', sub_doc.id)
        //   let snapSDS = await this.props.db.collection("sds_management").where("itemId", "==", sub_doc.id).get();
        //   if (snapSDS.docs.length > 0) {
        //     // let sdsManagementData = snapSDS.docs[0].data();
        //     // console.log(sdsManagementData.item, data)
        //     // if (snapSDS.docs[0].data()) {}
        //     snapSDS.docs[0].ref.set({item: data}, {merge: true});
        //   }
        // }

        // if (data.files && data.files.length > 0) {
        //   // for (let i=0; i<data.files.length; i++) {
        //   //   let file = data.files[i];
        //   //   if (file.name && !file.file) {
        //   //     //TODO: clear empty file
        //   //
        //   //     // let files = data.files.filter(item => item.file);
        //   //     // console.log(data.files, files)
        //   //     // promises.push(sub_doc.ref.set({files: files}, {merge: true}));
        //   //
        //   //     let names = data.files.filter(item => item.file).map(function(item) {
        //   //       return item['name'].trim();
        //   //     });
        //   //     promises.push(sub_doc.ref.set({files_names: names}, {merge: true}));
        //   //   }
        //   // }
        //
        //   if (!data.files_names) {
        //     console.log('files_names not found')
        //     let names = data.files.filter(item => item.file).map(function(item) {
        //       return item['name'].trim();
        //     });
        //     promises.push(sub_doc.ref.set({files_names: names}, {merge: true}));
        //   }
        // } else {
        //   if (!data.files_names) {
        //     promises.push(sub_doc.ref.set({files_names: []}, {merge: true}));
        //   }
        // }
      }
    }

    console.log('update start', promises.length)
    // await Promise.all(promises);
    console.log('update all items expiration_date', promises.length);
  }

  sdsCheck = async () => {

  }

  dateFormatter = (date) => {
    return `${date.toDate().getFullYear()}/${(date.toDate().getMonth() + 1).toString().padStart(2, '0')}/${date.toDate().getDate().toString().padStart(2, '0')}, ${date.toDate().getHours().toString().padStart(2, '0')}:${date.toDate().getMinutes().toString().padStart(2, '0')}:${date.toDate().getSeconds().toString().padStart(2, '0')}`
  }

  loadFullData = async () => {
    let querySnapshot;
    this.setState({ excelLoading: true });
    if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      querySnapshot = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items');
    } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      querySnapshot = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items');
    } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      querySnapshot = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items');
    } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      querySnapshot = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items');
    }
    try {
      const ds = await querySnapshot.get();
      const data = ds.docs.map(doc => ({ ...doc.data(), real_id: doc.id }));

      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet('Data');

      worksheet.getCell('A1').value = 'No';
      worksheet.getCell('B1').value = 'Document_ID';
      worksheet.getCell('C1').value = 'Purchase_Side_Org_ID';
      worksheet.getCell('D1').value = 'Sales_Side_Org_ID';
      worksheet.getCell('E1').value = 'P4CLOUD商品名';
      worksheet.getCell("F1").value = 'P4CLOUD商品名ふりがな';
      worksheet.getCell("G1").value = 'price';
      worksheet.getCell("H1").value = 'expiration_date';
      worksheet.getCell("I1").value = '売価';
      worksheet.getCell("J1").value = '消費税率';
      worksheet.getCell("K1").value = '正式メーカー商品名';
      worksheet.getCell("L1").value = '正式メーカー商品名かな';
      worksheet.getCell("M1").value = 'メーカー商品コード';
      worksheet.getCell("N1").value = '正式メーカー名';
      worksheet.getCell("O1").value = 'メーカー法人番号';
      worksheet.getCell("P1").value = '荷姿名称１';
      worksheet.getCell("Q1").value = '単位';
      worksheet.getCell("R1").value = '容量・入数';
      worksheet.getCell("S1").value = '発注単位';
      worksheet.getCell("T1").value = '発注数';
      worksheet.getCell("U1").value = '発注数量';
      worksheet.getCell("V1").value = '劇毒物区分名';
      worksheet.getCell("W1").value = '危険物区分名';
      worksheet.getCell("X1").value = '大分類';
      worksheet.getCell("Y1").value = '中分類';
      worksheet.getCell("Z1").value = '小分類';
      worksheet.getCell("AA1").value = '仕入先（本社）';
      worksheet.getCell("AB1").value = '仕入先事業所コード';
      worksheet.getCell("AC1").value = '仕入先事業所名';
      worksheet.getCell("AD1").value = '仕入先法人番号';
      worksheet.getCell("AE1").value = '画像１ふりがな';
      worksheet.getCell("AF1").value = '画像１タイトル';
      worksheet.getCell("AG1").value = '画像１ファイル名';
      worksheet.getCell("AH1").value = '画像１表示順';
      worksheet.getCell("AI1").value = '画像２ふりがな';
      worksheet.getCell("AJ1").value = '画像２タイトル';
      worksheet.getCell("AK1").value = '画像２ファイル名';
      worksheet.getCell("AL1").value = '画像２表示順';
      worksheet.getCell("AM1").value = 'SDS１ふりがな';
      worksheet.getCell("AN1").value = 'SDS１タイトル';
      worksheet.getCell("AO1").value = 'SDS１ファイル名';
      worksheet.getCell("AP1").value = 'SDS１ボタン名';
      worksheet.getCell("AQ1").value = 'SDS２ふりがな';
      worksheet.getCell("AR1").value = 'SDS２タイトル';
      worksheet.getCell("AS1").value = 'SDS２ファイル名';
      worksheet.getCell("AT1").value = 'SDS２ボタン名';
      worksheet.getCell("AU1").value = 'SDS３ふりがな';
      worksheet.getCell("AV1").value = 'SDS３タイトル';
      worksheet.getCell("AW1").value = 'SDS３ファイル名';
      worksheet.getCell("AX1").value = 'SDS３ボタン名';
      worksheet.getCell("AY1").value = 'カタログ１ふりがな';
      worksheet.getCell("AZ1").value = 'カタログ１タイトル';
      worksheet.getCell("BA1").value = 'カタログ１ファイル名';
      worksheet.getCell("BB1").value = 'カタログ１ボタン名';
      worksheet.getCell("BC1").value = 'カタログ１発行年月日';
      worksheet.getCell("BD1").value = 'カタログ２ふりがな';
      worksheet.getCell("BE1").value = 'カタログ２タイトル';
      worksheet.getCell("BF1").value = 'カタログ２ファイル名';
      worksheet.getCell("BG1").value = 'カタログ２ボタン名';
      worksheet.getCell("BH1").value = 'カタログ２発行年月日';
      worksheet.getCell("BI1").value = 'カタログ３ふりがな';
      worksheet.getCell("BJ1").value = 'カタログ３タイトル';
      worksheet.getCell("BK1").value = 'カタログ３ファイル名';
      worksheet.getCell("BL1").value = 'カタログ３ボタン名';
      worksheet.getCell("BM1").value = 'カタログ３発行年月日';
      worksheet.getCell("BN1").value = '取説１ふりがな';
      worksheet.getCell("BO1").value = '取説１タイトル';
      worksheet.getCell("BP1").value = '取説１ファイル名';
      worksheet.getCell("BQ1").value = '取説１ファイル名';
      worksheet.getCell("BR1").value = '取説１ボタン名';
      worksheet.getCell("BS1").value = '取説１発行年月日';
      worksheet.getCell("BT1").value = '取説２ふりがな';
      worksheet.getCell("BU1").value = '取説２タイトル';
      worksheet.getCell("BV1").value = '取説２ファイル名';
      worksheet.getCell("BW1").value = '取説２ファイル名';
      worksheet.getCell("BX1").value = '取説２ボタン名';
      worksheet.getCell("BY1").value = '取説２発行年月日';
      worksheet.getCell("BZ1").value = '取説３ふりがな';
      worksheet.getCell("CA1").value = '取説３タイトル';
      worksheet.getCell("CB1").value = '取説３ファイル名';
      worksheet.getCell("CC1").value = '取説３ファイル名';
      worksheet.getCell("CD1").value = '取説３ボタン名';
      worksheet.getCell("CE1").value = '取説３発行年月日';
      worksheet.getCell("CF1").value = '一般公開可否';
      worksheet.getCell("CG1").value = 'P4CLOUD登録日';
      worksheet.getCell("CH1").value = '新規登録依頼日';

      let row = 2;
      for await (const item of data) {
        worksheet.getCell(`A${row}`).value = item['No'];
        worksheet.getCell(`B${row}`).value = item.real_id;
        worksheet.getCell(`C${row}`).value = this.props.userOrg.id;
        worksheet.getCell(`D${row}`).value = this.props.org.id;
        worksheet.getCell(`E${row}`).value = item['P4CLOUD商品名'];
        worksheet.getCell(`F${row}`).value = item['P4CLOUD商品名ふりがな'];
        worksheet.getCell(`G${row}`).value = item.price && item.price[0].price;
        worksheet.getCell(`H${row}`).value = item.price && this.dateFormatter(item.price[0].expiration_date);
        worksheet.getCell(`I${row}`).value = item['売価'];
        worksheet.getCell(`J${row}`).value = item['消費税率'];
        worksheet.getCell(`K${row}`).value = item['正式メーカー商品名'];
        worksheet.getCell(`L${row}`).value = item['正式メーカー商品名かな'];
        worksheet.getCell(`M${row}`).value = item['メーカー商品コード'];
        worksheet.getCell(`N${row}`).value = item['正式メーカー名'];
        worksheet.getCell(`O${row}`).value = item['メーカー法人番号'];
        worksheet.getCell(`P${row}`).value = item['荷姿名称１'];
        worksheet.getCell(`Q${row}`).value = item['単位'];
        worksheet.getCell(`R${row}`).value = item['容量・入数'];
        worksheet.getCell(`S${row}`).value = item['発注単位'];
        worksheet.getCell(`T${row}`).value = item['発注数'];
        worksheet.getCell(`U${row}`).value = item['発注数量'];
        worksheet.getCell(`V${row}`).value = item['劇毒物区分名'];
        worksheet.getCell(`W${row}`).value = item['危険物区分名'];
        worksheet.getCell(`X${row}`).value = item['大分類'];
        worksheet.getCell(`Y${row}`).value = item['中分類'];
        worksheet.getCell(`Z${row}`).value = item['小分類'];
        worksheet.getCell(`AA${row}`).value = item['仕入先（本社）'];
        worksheet.getCell(`AB${row}`).value = item['仕入先事業所コード'];
        worksheet.getCell(`AC${row}`).value = item['仕入先事業所名'];
        worksheet.getCell(`AD${row}`).value = item['仕入先法人番号'];
        worksheet.getCell(`AE${row}`).value = item['画像１ふりがな'];
        worksheet.getCell(`AF${row}`).value = item['画像１タイトル'];
        worksheet.getCell(`AG${row}`).value = item['画像１ファイル名'];
        worksheet.getCell(`AH${row}`).value = item['画像１表示順'];
        worksheet.getCell(`AI${row}`).value = item['画像２ふりがな'];
        worksheet.getCell(`AJ${row}`).value = item['画像２タイトル'];
        worksheet.getCell(`AK${row}`).value = item['画像２ファイル名'];
        worksheet.getCell(`AL${row}`).value = item['画像２表示順'];
        worksheet.getCell(`AM${row}`).value = item['SDS１ふりがな'];
        worksheet.getCell(`AN${row}`).value = item['SDS１タイトル'];
        worksheet.getCell(`AO${row}`).value = item['SDS１ファイル名'];
        worksheet.getCell(`AP${row}`).value = item['SDS１ボタン名'];
        worksheet.getCell(`AQ${row}`).value = item['SDS２ふりがな'];
        worksheet.getCell(`AR${row}`).value = item['SDS２タイトル'];
        worksheet.getCell(`AS${row}`).value = item['SDS２ファイル名'];
        worksheet.getCell(`AT${row}`).value = item['SDS２ボタン名'];
        worksheet.getCell(`AU${row}`).value = item['SDS３ふりがな'];
        worksheet.getCell(`AV${row}`).value = item['SDS３タイトル'];
        worksheet.getCell(`AW${row}`).value = item['SDS３ファイル名'];
        worksheet.getCell(`AX${row}`).value = item['SDS３ボタン名'];
        worksheet.getCell(`AY${row}`).value = item['カタログ１ふりがな'];
        worksheet.getCell(`AZ${row}`).value = item['カタログ１タイトル'];
        worksheet.getCell(`BA${row}`).value = item['カタログ１ファイル名'];
        worksheet.getCell(`BB${row}`).value = item['カタログ１ボタン名'];
        worksheet.getCell(`BC${row}`).value = item['カタログ１発行年月日'] && this.dateFormatter(item['カタログ１発行年月日']);
        worksheet.getCell(`BD${row}`).value = item['カタログ２ふりがな'];
        worksheet.getCell(`BE${row}`).value = item['カタログ２タイトル'];
        worksheet.getCell(`BF${row}`).value = item['カタログ２ファイル名'];
        worksheet.getCell(`BG${row}`).value = item['カタログ２ボタン名'];
        worksheet.getCell(`BH${row}`).value = item['カタログ２発行年月日'];
        worksheet.getCell(`BI${row}`).value = item['カタログ３ふりがな'];
        worksheet.getCell(`BJ${row}`).value = item['カタログ３タイトル'];
        worksheet.getCell(`BK${row}`).value = item['カタログ３ファイル名'];
        worksheet.getCell(`BL${row}`).value = item['カタログ３ボタン名'];
        worksheet.getCell(`BM${row}`).value = item['カタログ３発行年月日'];
        worksheet.getCell(`BN${row}`).value = item['取説１ふりがな'];
        worksheet.getCell(`BO${row}`).value = item['取説１タイトル'];
        worksheet.getCell(`BP${row}`).value = item['取説１ファイル名'];
        worksheet.getCell(`BQ${row}`).value = item['取説１ファイル名'];
        worksheet.getCell(`BR${row}`).value = item['取説１ボタン名'];
        worksheet.getCell(`BS${row}`).value = item['取説１発行年月日'];
        worksheet.getCell(`BT${row}`).value = item['取説２ふりがな'];
        worksheet.getCell(`BU${row}`).value = item['取説２タイトル'];
        worksheet.getCell(`BV${row}`).value = item['取説２ファイル名'];
        worksheet.getCell(`BW${row}`).value = item['取説２ファイル名'];
        worksheet.getCell(`BX${row}`).value = item['取説２ボタン名'];
        worksheet.getCell(`BY${row}`).value = item['取説２発行年月日'];
        worksheet.getCell(`BZ${row}`).value = item['取説３ふりがな'];
        worksheet.getCell(`CA${row}`).value = item['取説３タイトル'];
        worksheet.getCell(`CB${row}`).value = item['取説３ファイル名'];
        worksheet.getCell(`CC${row}`).value = item['取説３ファイル名'];
        worksheet.getCell(`CD${row}`).value = item['取説３ボタン名'];
        worksheet.getCell(`CE${row}`).value = item['取説３発行年月日'];
        worksheet.getCell(`CF${row}`).value = item['一般公開可否'];
        worksheet.getCell(`CG${row}`).value = item['P4CLOUD登録日'] && this.dateFormatter(item['P4CLOUD登録日']);
        worksheet.getCell(`CH${row}`).value = item['新規登録依頼日'] && this.dateFormatter(item['新規登録依頼日']);

        row++;
      }

      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer]);
        saveAs(blob, `itemsList-${this.props.userOrg.id}-${this.props.org.id}-${new moment().format('YYYY-MM-DD_HHmmss')}.xlsx`)
      })


      this.setState({ excelLoading: false });
    } catch (e) {
      console.log('e -> ', e.message);
      alert('エラー');
      this.setState({ excelLoading: false });
    }
  }

  componentDidMount() {
    // console.log('didMount')
    let cn = new URLSearchParams(this.props.search).get("cn");
    this.setState({
      cn: cn
    })
    this.getItems();
    // this.updateItemStockSDS();
    // this.updateSDSitemSell();
    // console.log('this.props.user.organization', this.props.user.organization)
   // window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    if (this.props.org !== nextProps.org) {
      //console.log('componentWillReceiveProps : 1');
      this.setState({changed: true,isSearch: false})
    }
    if (this.props.userOrg !== nextProps.userOrg) {
      //console.log('componentWillReceiveProps : 2');
      this.setState({changed: true,isSearch: false})
    }
  }

  componentDidUpdate() {
    // console.log('didUpdate', this.state.changed)
    if(this.state.changed){
      this.setState({
        changed: false,
        loadedAllItem: false,
        dbItems: [],
      }, () => {
        this.getItems(true);
      });

    }
  }

  componentWillUnmount() {
   // window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  /*watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +1 >= document.body.clientHeight) {
      // console.log('reach end', window.innerHeight, this.scrollTop(), document.body.clientHeight);
      this.onReachEnd();
      window.scrollTo(0, window.innerHeight -20)

    }
  }*/

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
    );
  }

  onReachEnd = () => {
    // console.log('onReachEnd')
    if(this.state.algoria){
      this.getItemsA(true, this.state.searchwordA);
    } if (this.state.algoria1) {
      this.getItemsA1(this.props, false, this.state.searchwordA1)
    } if(this.state.algoria2) {
      this.getItemsA2(this.props, false, this.state.searchwordA2)
    }
    else{
      this.getItems(false, this.state.searchword);
    }
  }

  handleSearchwordChange = (e) => {
    this.setState({
      searchword: e.currentTarget.value,isSearch: true
    });
    /*
    if(e.currentTarget.value.length > 1 || e.currentTarget.value.length==0){
      this.setState({
        dbItems: [],
        last: null,
      });
      this.getItems(true, e.currentTarget.value);
    }
    */
  }
  handleSearchwordKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        dbItems: [],
        last: null,
        loadedAllItem: false,
        algoria: false,isSearch: false
        // selectSDS: false
      }, () => {
        console.log('handleSearchwordKeyDown');
        this.getItems(true, this.state.searchword);
      });
    }
  }

  handleSearchwordAChange1 = (e) => {
    this.setState({
      searchwordA1: e.currentTarget.value,isSearch: true
    });
  }

  handleSearchwordAChange2 = (e) => {
    this.setState({
      searchwordA2: e.currentTarget.value,isSearch: true
    });
  }

  handleSearchwordAKeyDown1 = (e) => {
    let val = this.state.searchwordA1;
    if (e.key === 'Enter') {
      this.setState({
        dbItems: [],
        last: null,
        algoria1: true,
        algoriaPage1: 1,isSearch: false
        // selectSDS: false
      }, ()=>{
        console.log('val',val)
        if (val) {
          this.getItemsA1(this.props, true, val);
        } else {
          this.getItems(true);
        }
      });
    }
  }

  handleSearchwordAKeyDown2 = (e) => {
    let val = this.state.searchwordA2;
    if (e.key === 'Enter') {
      this.setState({
        dbItems: [],
        last: null,
        algoria2: true,
        algoriaPage2: 1,isSearch: false
        // selectSDS: false
      }, ()=>{
        if (val) {
          this.getItemsA2(this.props, true, val);
        } else {
          this.getItems(true);
        }
      });
    }
  }

  handleSearchwordAChange = (e) => {
    console.log('handleSearchwordAChange', e);
    //e.renderAsync= false;
    //console.log('e.currentTarget.value',e, e.key === 'Enter' && e.currentTarget.value);
     this.setState({
      searchwordA: e.currentTarget.value,isSearch: true
    });
    /*
    if(e.currentTarget.value.length > 1 || e.currentTarget.value.length==0){
      this.setState({
        dbItems: [],
        last: null,
      });
      this.getItems(true, e.currentTarget.value);
    }
    */
  }
  handleSearchwordAKeyDown = (e) => {
    e.stopPropagation();
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        dbItems: [],
        last: null,
        algoria: true,
        algoriaPage: 1,isSearch: false
        // selectSDS: false
      }, ()=>{
        //this.getItemsA(true, val);
        if (val) {
          this.getItemsA(true, val);
        } else {
          this.getItems(true);
        }
      });
    }
  }

  getItemsA2 = async (props, newOrg, word) => {
    //console.log('getItemsA2');
    if (this.state.loading) {
      return;
    }

    let tempResults = [];
    let organization = '';
    let otherOrganization = ''
    let index = "items_sell";

    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      organization = this.props.userOrg.id;
      otherOrganization = this.props.org.id;
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      organization = this.props.org.id;
      otherOrganization = this.props.userOrg.id;
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      organization = this.props.userOrg.id;
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      organization = this.props.org.id;
    }

    this.setState({loading: true, algoria1: false, algoria: false, algoria2: true, });

    let filters = 'organization:' + organization;

    if (otherOrganization) {
      filters += (' && otherOrganization:' + otherOrganization)
      index = "items_stock"
    }

    if (this.state.selectSDS) {
      filters += " && (files_names:SDS || files_names:ＳＤＳ || files_names:sds OR files_names:ｓｄｓ || files_names:ＳＤＳ１)"
    }

    // console.log(index, filters, word)

    let searchParameters = {
      'q'         : word,
      'query_by'  : '正式メーカー名',
      'filter_by' : filters,
      'sort_by'   :  'No:desc',
      'page'      : this.state.algoriaPage2,
      'per_page'  : this.state.itemsPerPage
    }
    await typesense.collections(index).documents().search(searchParameters).then((docs) => {
      //console.log('docs A2', docs);
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });

    let nextPage = this.state.algoriaPage2 + 1;
    let newResults = this.state.dbItems.concat(tempResults);

    this.setState({
      algoriaPage2: nextPage,
      dbItems: newResults,
      loading: false,isSearch: false
    });
  }


  getItemsA1 = async (props, newOrg, word) => {
    //console.log('getItemsA1');
    if (this.state.loading) {
      return;
    }

    let tempResults = [];
    let organization = '';
    let otherOrganization = ''
    let index = "items_sell";

    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      organization = this.props.userOrg.id;
      otherOrganization = this.props.org.id;
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      organization = this.props.org.id;
      otherOrganization = this.props.userOrg.id;
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      organization = this.props.userOrg.id;
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      organization = this.props.org.id;
    }

    this.setState({loading: true, algoria1: true, algoria: false, algoria2: false, });

    let filters = 'organization:' + organization;

    if (otherOrganization) {
      filters += (' && otherOrganization:' + otherOrganization)
      index = "items_stock"
    }

    if (this.state.selectSDS) {
      filters += " && (files_names:SDS || files_names:ＳＤＳ OR files_names:sds || files_names:ｓｄｓ || files_names:ＳＤＳ１)"
    }
    let searchParameters = {
      'q'         : word,
      'query_by'  : 'P4CLOUD商品名,P4CLOUD商品名ふりがな,正式メーカー商品名,正式メーカー商品名かな',
      'filter_by' : filters,
      'sort_by'   :  'No:desc',
      'page'      : this.state.algoriaPage1,
      'per_page'  : this.state.itemsPerPage
    }
    await typesense.collections(index).documents().search(searchParameters).then((docs) => {
      //console.log('docs A1', docs);
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });
    let nextPage = this.state.algoriaPage1 + 1;
    let newResults = this.state.dbItems.concat(tempResults);

    this.setState({
      algoriaPage1: nextPage,
      dbItems: newResults,
      loading: false,isSearch: false
    });
  }

  getItemsA = async(reset = false, word = '') => {
    //console.log('getItemsA type');
    if (this.state.loading) {
      return;
    }
    this.setState({
      loading: true,
      algoria1: false,
      algoria2: false,
      algoria: true,
    });
    let tempResults = [];
    let organization='';
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      organization = this.props.userOrg.id;
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      organization = this.props.org.id;
    }

    let filters = 'organization:' + organization; // + " AND files_names:SDS"
    if (this.state.selectSDS) {
      filters += " && (files_names:SDS || files_names:ＳＤＳ || files_names:sds OR files_names:ｓｄｓ || files_names:ＳＤＳ１)"
    }

    let searchParameters = {
      'q'         : word,
      'query_by'  : 'P4CLOUD商品名,P4CLOUD商品名ふりがな,容量・入数,正式メーカー商品名,SDS１ふりがな,SDS１タイトル,SDS１ファイル名,正式メーカー名,正式メーカー商品名,正式メーカー商品名かな,price.price,data.title,data.value',
      'filter_by' : filters,
      //'locale'    : 'ja',
      'sort_by'   :  'No:desc',
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.itemsPerPage
    }
    await typesense.collections('items_sell').documents().search(searchParameters).then((docs) => {
      docs.hits.forEach((data) => {
        tempResults.push(data.document);
      })
    });

    let nextPage = this.state.algoriaPage + 1;
    let newResults = this.state.dbItems.concat(tempResults);

    this.setState({
      algoriaPage: nextPage,
      dbItems: newResults,
      loading: false,isSearch: false
    });
    //console.log('set');
  }

  addCart = (item) => {
    let self = this;
    // console.log('item',item.id, item.objectID);
    this.setState({selectedItem: item}, () => {
      self.setState({cartselect: true});
    });
  }

  addFavoriteCart = (item) => {
    let self = this;

    if (this.state.cn === null) {
      this.setState({selectedItem: item}, () => {
        self.setState({favoriteCartSelect: true})
      })
    } else {
      let cartId = this.state.cn;
      this.props.db.collection('favorite_set').doc(this.props.user.id)
          .collection('favorite_set').doc(this.props.org.id)
          .get()
          .then((doc) => {
            if(doc.exists) {
              let data = doc.data();
              // console.log(data)

              let carts = data;
              if(!carts[cartId]){
                carts[cartId] = {items: []}
              }

              let cart = carts[cartId].items;
              if(!cart){
                cart=[];
              }

              let date = firebase.firestore.Timestamp.fromDate(new Date());

              if (item.constructor.name === "Array") {
                let dataArray = item;
                for (let i=0; i<item.length; i++) {
                  const cart_filter_result = cart.filter(it => it.name === item[i]['P4CLOUD商品名'])
                  if (cart_filter_result.length === 0) {
                    cart.push({
                      name: item[i]['P4CLOUD商品名'] ? item[i]['P4CLOUD商品名'] : '',
                      maker: item[i]['正式メーカー名'] ? item[i]['正式メーカー名'] : '',
                      code: item[i]['販売企業商品コード'] ? item[i]['販売企業商品コード'] : '',
                      sellercode: item[i]['仕入先事業所コード'] ? item[i]['仕入先事業所コード'] : '',
                      sellername: item[i]['仕入先事業所名'] ? item[i]['仕入先事業所名'] : '',
                      makeredicode: item[i]['仕入先EDIコード商品コード'] ? item[i]['仕入先EDIコード商品コード'] : '',
                      makerordercode: item[i]['仕入先発注コード'] ? item[i]['仕入先発注コード'] : '',
                      price: item[i]['price'][0]['price'] ? item[i]['price'][0]['price'] : 0,
                      tax: item[i]['消費税率'] ? item[i]['消費税率'] : 0,
                      id: item[i].id ? item[i].id : item[i].objectID,
                      count: 1,
                      update: date,
                    });
                  } else {
                    cart_filter_result[0].count = cart_filter_result[0].count + 1;
                    cart_filter_result[0].update = date;
                  }
                }
              } else {
                const cart_filter_result =  cart.filter(it => it.name === item['P4CLOUD商品名'])
                if (cart_filter_result.length === 0) {
                  cart.push({
                    name: item['P4CLOUD商品名'] ? item['P4CLOUD商品名'] : '',
                    maker: item['正式メーカー名'] ? item['正式メーカー名'] : '',
                    code: item['販売企業商品コード'] ? item['販売企業商品コード'] : '',
                    sellercode: item['仕入先事業所コード'] ? item['仕入先事業所コード'] : '',
                    sellername: item['仕入先事業所名'] ? item['仕入先事業所名'] : '',
                    makeredicode: item['仕入先EDIコード商品コード'] ? item['仕入先EDIコード商品コード'] : '',
                    makerordercode: item['仕入先発注コード'] ? item['仕入先発注コード'] : '',
                    price: item['price'][0]['price'] ? item['price'][0]['price'] : 0,
                    tax: item['消費税率'] ? item['消費税率'] : 0,
                    id: item.id ? item.id : item.objectID,
                    count:1,
                    update: date,
                  });
                } else {
                  cart_filter_result[0].count = cart_filter_result[0].count+1;
                  cart_filter_result[0].update = date;
                }
              }

              carts[cartId].items = cart;
              carts[cartId].rejected = false;
              carts[cartId].created = date;
              carts[cartId].latestUpdate = date;

              if (!carts[cartId].creatorUser) {
                carts[cartId].creatorUser = this.props.user.id;
                carts[cartId].creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;
              }

              let cartMainRef = this.props.db.collection('favorite_set').doc(this.props.user.id);
              let cartRef =cartMainRef.collection('favorite_set').doc(this.props.org.id);

              cartMainRef.set({
                dummy: 'test'
              }, {merge: true})

              let self = this;
              let setWithMerge = cartRef.set({
                [cartId]: carts[cartId],
                update: date,
              }, { merge: true })
                  .then(function () {
                    self.props.cProps.history.push('/favorite/cart/'+(self.state.cn*1+1))
                  })
                  .catch(function (error) {
                    console.error("Error set document: ", error);
                  });

              // console.log(cart)
            }
          })
    }

  }

  addOtasukeCart = (item) => {
    let self = this;

    if (this.state.cn === null) {
      this.setState({selectedItem: item}, () => {
        self.setState({otasukeCartSelect: true})
      })
    } else {
      let cartId = this.state.cn;
      this.props.db.collection('otasuke_carts').doc(this.props.user.id)
          .collection('otasuke_carts').doc(this.props.org.id)
          .get()
          .then((doc) => {
            if(doc.exists) {
              let data = doc.data();
              // console.log(data)

              let carts = data;
              if(!carts[cartId]){
                carts[cartId] = {items: []}
              }

              let cart = carts[cartId].items;
              if(!cart){
                cart=[];
              }

              let date = firebase.firestore.Timestamp.fromDate(new Date());

              if (item.constructor.name === "Array") {
                let dataArray = item;
                for (let i=0; i<item.length; i++) {
                  const cart_filter_result = cart.filter(it => it.name === item[i]['P4CLOUD商品名'])
                  if (cart_filter_result.length === 0) {
                    cart.push({
                      name: item[i]['P4CLOUD商品名'] ? item[i]['P4CLOUD商品名'] : '',
                      maker: item[i]['正式メーカー名'] ? item[i]['正式メーカー名'] : '',
                      code: item[i]['販売企業商品コード'] ? item[i]['販売企業商品コード'] : '',
                      sellercode: item[i]['仕入先事業所コード'] ? item[i]['仕入先事業所コード'] : '',
                      sellername: item[i]['仕入先事業所名'] ? item[i]['仕入先事業所名'] : '',
                      makeredicode: item[i]['仕入先EDIコード商品コード'] ? item[i]['仕入先EDIコード商品コード'] : '',
                      makerordercode: item[i]['仕入先発注コード'] ? item[i]['仕入先発注コード'] : '',
                      price: item[i]['price'][0]['price'] ? item[i]['price'][0]['price'] : 0,
                      tax: item[i]['消費税率'] ? item[i]['消費税率'] : 0,
                      id: item[i].id ? item[i].id : item[i].objectID,
                      count: 1,
                      update: date,
                    });
                  } else {
                    cart_filter_result[0].count = cart_filter_result[0].count + 1;
                    cart_filter_result[0].update = date;
                  }
                }
              } else {
                const cart_filter_result =  cart.filter(it => it.name === item['P4CLOUD商品名'])
                if (cart_filter_result.length === 0) {
                  cart.push({
                    name: item['P4CLOUD商品名'] ? item['P4CLOUD商品名'] : '',
                    maker: item['正式メーカー名'] ? item['正式メーカー名'] : '',
                    code: item['販売企業商品コード'] ? item['販売企業商品コード'] : '',
                    sellercode: item['仕入先事業所コード'] ? item['仕入先事業所コード'] : '',
                    sellername: item['仕入先事業所名'] ? item['仕入先事業所名'] : '',
                    makeredicode: item['仕入先EDIコード商品コード'] ? item['仕入先EDIコード商品コード'] : '',
                    makerordercode: item['仕入先発注コード'] ? item['仕入先発注コード'] : '',
                    price: item['price'][0]['price'] ? item['price'][0]['price'] : 0,
                    tax: item['消費税率'] ? item['消費税率'] : 0,
                    id: item.id ? item.id : item.objectID,
                    count:1,
                    update: date,
                  });
                } else {
                  cart_filter_result[0].count = cart_filter_result[0].count+1;
                  cart_filter_result[0].update = date;
                }
              }

              carts[cartId].items = cart;
              carts[cartId].rejected = false;
              carts[cartId].created = date;
              carts[cartId].latestUpdate = date;

              if (!carts[cartId].creatorUser) {
                carts[cartId].creatorUser = this.props.user.id;
                carts[cartId].creatorUserName = this.props.user.last_name + " " + this.props.user.first_name;
              }

              let cartMainRef = this.props.db.collection('otasuke_carts').doc(this.props.user.id);
              let cartRef = cartMainRef.collection('otasuke_carts').doc(this.props.org.id);

              cartMainRef.set({
                dummy: 'test'
              }, {merge: true})

              let self = this;
              let setWithMerge = cartRef.set({
                [cartId]: carts[cartId],
                update: date,
              }, { merge: true })
                  .then(function () {
                    self.props.cProps.history.push('/otasuke/cart/'+(self.state.cn*1+1))
                  })
                  .catch(function (error) {
                    console.error("Error set document: ", error);
                  });

              // console.log(cart)
            }
          })
    }

  }

  addQuoteCart = (item) => {
    // let self = this;
    // // console.log('item',item.id, item.objectID);
    // this.setState({selectedItem: item}, () => {
    //   self.setState({cartselect: true});
    // });
  }

  showHideCart = (val, added) => {
    this.setState({cartselect: val});
    if (added === true) {
      toast.success("カートへの追加が完了しました。", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  showHideOtasukeCart = (val, added) => {
    this.setState({otasukeCartSelect: val});
    if (added === true) {
      toast.success("お助けカートへの追加が完了しました。", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  showHideFavoriteCart = (val, added) => {
    this.setState({favoriteCartSelect: val});
    if (added === true) {
      toast.success("お気に入りへの追加が完了しました。", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  itemIsSelected = (doc) => {
    let filtered = [];
    if (doc.objectID) {
      filtered = this.state.selectedItemList.filter(item => item.objectID === doc.objectID);
    } else if (doc.id) {
      filtered = this.state.selectedItemList.filter(item => item.id === doc.id);
    }

    return filtered.length > 0
  };

  itemSelect = (selected, item) => {
     //console.log(selected, this.state.selectedItemList, item);
    let selectedItemList;
    if (selected) {
      selectedItemList = [...this.state.selectedItemList, item];
    } else {
      if (item.objectID) {
        selectedItemList = this.state.selectedItemList.filter( sItem => sItem.objectID !== item.objectID );
      } else if (item.id) {
        selectedItemList = this.state.selectedItemList.filter( sItem => sItem.id !== item.id );
      }

    }

    this.setState({
      selectedItemList: selectedItemList
    })
  }

  uncheckSelectedItems = () => {
    this.setState({
      selectedItemList: []
    })
  }

  addSelectedItems = () => {
    this.addOtasukeCart(this.state.selectedItemList)
  }

  addSelectedItemsFavorite = () => {
    this.addFavoriteCart(this.state.selectedItemList)
  }

  selectSDSItem = (bool) => {
    this.setState({selectSDS: bool});
  }

  filter = () => {
    let updateData = {
      dbItems: [],
      last: null,
      loadedAllItem: false,
      algoriaPage: 1,
      algoriaPage1: 1,
      algoriaPage2: 1,
    };

    if (this.state.searchwordA) {
      updateData.algoria = true;
      updateData.algoria1 = false;
      updateData.algoria2 = false;
      updateData.searchwordA1 = '';
      updateData.searchwordA2 = '';
    }

    if (this.state.searchwordA1) {
      updateData.algoria = false;
      updateData.algoria2 = false;
      updateData.algoria1 = true;
      updateData.searchwordA = '';
      updateData.searchwordA2 = '';
    }

    if (this.state.searchwordA2) {
      updateData.algoria = false;
      updateData.algoria2 = true;
      updateData.algoria1 = false;
      updateData.searchwordA = '';
      updateData.searchwordA1 = '';
    }

    this.setState(updateData, () => {
      if(this.state.algoria){
        this.getItemsA(true, this.state.searchwordA);
      } else if (this.state.algoria1) {
        this.getItemsA1(this.props, false, this.state.searchwordA1)
      } else if (this.state.algoria2) {
        this.getItemsA2(this.props, false, this.state.searchwordA2)
      } else{
        this.getItems(true, this.state.searchword);
      }
    })
    // console.log('filter')
  }

  sdsManagementComplete = (item) => {
    this.setState({
      sdsManagementCompleteModal: false
    })
  }

  sdsManagementCompleteClose = (e) => {

    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true'
    if (isConfirm) {
      this.setState({
        sdsManagementCompleteModal: false
      })

      this.props.cProps.history.push('/sdsManagement');
    } else {
      this.setState({
        sdsManagementCompleteModal: false
      })
    }

  }

  sdsManagementConfirm = (item) => {
    this.setState({
      sdsManagementConfirmModal: true
    });
  }

  sdsManagementConfirmClose = (e) => {
    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true'
    if (isConfirm) {
      let sdsManagementRef = this.props.db.collection('sds_management').doc();

      // this.props.org.id //picked org
      // this.props.userOrg.id //user org
      // this.props.user.organization //user org
      let itemId = this.state.selectSDSItem.id || this.state.selectSDSItem.objectID

      let setWithMerge = sdsManagementRef.set({
        status: 'waiting',
        item: this.state.selectSDSItem,
        itemId: itemId,
        applicationDate: firebase.firestore.Timestamp.fromDate(new Date()),
        applicationUser: this.props.user.id,
        applicationUserName: this.props.user.last_name + ' ' + this.props.user.first_name,
        applicationOrganization: this.props.user.organization,
        receptionDate: null,
        receptionUser: null,
        receptionUserName: null,
        receptionOrganization: null,
        reception: false,
        fromOrganization: this.props.org.id,
        toOrganization: this.props.userOrg.id,
        preferredDate: this.state.months,
        created: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let itemRef = this.props.db.collection('items_sell').doc(this.props.user.organization).collection('items').doc(itemId);
      itemRef.set({
        sdsManagement: true
      }, {merge: true});

      let items = this.state.dbItems;
      items.forEach((data) => {
        if (data.id === itemId) {
          data.sdsManagement = true;
        }
      })
      this.setState({dbItems: items})

      this.props.loadBadge();

      this.setState({
        sdsManagementConfirmModal: false,
        sdsManagementCompleteModal: true
      });
    } else {
      this.setState({
        sdsManagementConfirmModal: false,
        sdsManagementCompleteModal: false
      });
    }
  }

  sdsManagementDirect = (item) => {
    this.props.cProps.history.push('/sdsManagement');
  }

  sdsManagement = (item) => {
    // console.log('sdsManagement',item);
    // console.log('sds orgs: ', this.props.org.id, this.props.userOrg.id, this.props.user.organization)


    this.setState({
      selectSDSItem: item,
      sdsManagementModal: true
    })
  }

  sdsManagementClose = (e) => {
    let isConfirm = (e && e.currentTarget.dataset.confirm) === 'true'
    // console.log('isConfirm', isConfirm, this.state.months)

    if (isConfirm) {
      this.setState({
        sdsManagementModal: false,
        sdsManagementConfirmModal: true
      });
    } else {
      this.setState({
        sdsManagementModal: false,
        sdsManagementConfirmModal: false
      });
    }

  }

  monthSelect = (value, month) => {
    // console.log(value, month);

    let tempMonths = this.state.months;
    tempMonths[month] = value;
    this.setState({
      months: tempMonths
    })
  }
  onCellPreparedHandler = (e) => {
    if (e.rowType === 'header') {
      e.cellElement.wordWrapEnabled = true;
      e.cellElement.style.color = "#fff";
      e.cellElement.style.alignment = "middle";
      e.cellElement.style.verticalAlign = "middle";
      e.cellElement.style.backgroundColor = "#2E75B6";
      // e.cellElement.style.alignSelf = "center";
    }
    if (e.rowType === 'data') {
      e.cellElement.style.verticalAlign = "middle";
    }

  }
   checkCell = (data) => {
     let checked = this.itemIsSelected(data.data);
     //console.log('checkCell', data.data);
     return <div style={{width: 50}}>
       <MDBInput label=" " type="checkbox" checked={checked} id={"checkbox" + data.rowIndex} onChange={(e) => this.itemSelect(e.target.checked, data.data)}/>
     </div>
  }
  imageCell =(data) =>{
    let item= data.data;
    //console.log('imageCell', item);
    return  <ItemImage src={item.image} storage={this.props.storage} className="img-thumbnail"
                       style={{width: "100px"}} alt=""/>
  }
  priceCell =(data) =>{
    let item= data.data;
    let priceText = '';
    let orderButtonText = '再発注';

    if (!item['price']) {
      priceText = '要見積';
    } else if (item['price'] && item['price'][0] && !item['price'][0]['price']) {
      priceText = '要見積';
    } else if (item['price'] && item['price'][0] && !item['price'][0]['expiration_date']) {
      priceText = '要見積';
    } else if (item['price'] && item['price'][0] && item['price'][0]['expiration_date']) {
      let expiration_date = item['price'][0]['expiration_date'];
      let expDate;
      if (this.state.algoria || this.state.algoria1 || this.state.algoria2) {
        expDate = new Date(expiration_date._seconds * 1000 + expiration_date._nanoseconds / 1000000);
      } else if (expiration_date) {
        expDate = new Date(expiration_date._seconds * 1000 + expiration_date._nanoseconds / 1000000);
        //expDate = expDate.toDate();
      }

      if ((expDate.getTime() - this.props.dateNow) < 0) {
        priceText = '価格有効期限切れ要見積';
        // console.log(expDate, expDate.getTime(), this.props.dateNow)
      }
    }

    return (priceText ? <p className="text-right" style={{color: "red"}}>{priceText}</p> :
        <p className="text-right">{item['price'][0]['price'].toLocaleString() + "円"}</p>)
  }
  btnCell =(data) => {
    let item= data.data;
    let isBuyer = false;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      // maker
      // console.log('maker')
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      // sale/purchase
      // console.log('sale/purchase')
      isBuyer = true;
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      // purchase
      // console.log('purchase')
      isBuyer = true;
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      // sale/sale
      // console.log('sale/sale')
    }

    //console.log('btnCell', item);
    let disableButton = false;
    let orderButtonText = '再発注';
    if (!item['price']) {
      disableButton = true;
    } else if (item['price'] && item['price'][0] && !item['price'][0]['price']) {
      disableButton = true;
    } else if (item['price'] && item['price'][0] && !item['price'][0]['expiration_date']) {
      disableButton = true;
    } else if (item['price'] && item['price'][0] && item['price'][0]['expiration_date']) {
      let expiration_date = item['price'][0]['expiration_date'];
      let expDate;
      if (this.state.algoria || this.state.algoria1 || this.state.algoria2) {
        expDate = new Date(expiration_date._seconds * 1000 + expiration_date._nanoseconds / 1000000);
      } else if (expiration_date) {
        expDate = new Date(expiration_date._seconds * 1000 + expiration_date._nanoseconds / 1000000);
        //expDate = expDate.toDate();
      }
      if ((expDate.getTime() - this.props.dateNow) < 0) {
        // if (isBuyer) {
        disableButton = true;
        orderButtonText = '価格有効期限切れ要見積';
        // }
        // console.log(expDate, expDate.getTime(), this.props.dateNow)
      }
    }

    let sds = [];
    let files = [];
    for (var i in item['files']) {
      let e = item['files'][i];
      // console.log('files',e)
      if (e) {
        if (e.name && (e.name.toLowerCase().indexOf("ｓｄｓ") !== -1 || e.name.toLowerCase().indexOf("sds") !== -1)) {
          // console.log(item.id, item['sdsManagement'])
          let sdsBtn = '';
          if (isBuyer) {
            sdsBtn = <MDBBtn size={'sm'} color={''} style={{
              color: 'black',
              padding: '0.3rem 0.7rem',
              whiteSpace: 'nowrap',
              flex: 1,
              borderRadius: 15
            }} onClick={(e) => this.sdsManagement(item)}>SDS管理申込</MDBBtn>
          }

          if (item['sdsManagement']) {
            sdsBtn = <MDBBtn size={'sm'} color={''} style={{
              color: 'black',
              padding: '0.3rem 0.7rem',
              whiteSpace: 'nowrap',
              flex: 1,
              borderRadius: 15
            }} onClick={(e) => this.sdsManagementDirect(item)}>SDS管理中</MDBBtn>
          }

          sds.push(
              <>
                <FILELink src={e['file']} storage={this.props.storage} target="_blank"
                          style={{display: 'flex', flex: 1}}>
                  <MDBBtn size={'sm'} color={''} style={{
                    color: 'black',
                    padding: '0.3rem 0.7rem',
                    whiteSpace: 'nowrap',
                    flex: 1,
                    borderRadius: 15
                  }}>{e.name}</MDBBtn>
                </FILELink>
                {sdsBtn}
              </>
          );
        } else {
          files.push(
              <>
                <FILELink src={e['file']} storage={this.props.storage} target="_blank">
                  <MDBBtn size={'sm'} color={''} style={{
                    color: 'black',
                    padding: '0.3rem 0.7rem',
                    whiteSpace: 'nowrap',
                    width: 'auto',
                    borderRadius: 15
                  }}>{e.name}</MDBBtn>
                </FILELink>
              </>
          );
        }
      }
    }
   let returnData =null;
    if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      returnData = <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div className={'custom-button-group-long'}>
          <Link to={"/items/" + (item.id || item.objectID)} target="_blank">
            <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                    id={(item.id || item.objectID)}>商品詳細</MDBBtn>
          </Link>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: 'black', padding: '0.26em 1em'}}
                  onClick={() => this.addOtasukeCart(item)}>お助けカートに入れる</MDBBtn>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: 'black', padding: '0.26em 1em'}}
                  onClick={() => this.addFavoriteCart(item)}>お気に入りセット 登録</MDBBtn>
        </div>
        <div className={'custom-button-group-long'} style={{textAlign: 'right', display: 'flex'}}>
          {sds}
        </div>
        {files.length > 0 &&
            <div style={{textAlign: 'right', height: 37, position: 'relative', width: 397}}>
              <div style={{
                position: 'absolute',
                display: 'flex',
                left: -303,
                width: 700,
                justifyContent: 'flex-end'
              }}>
                {files}
              </div>
            </div>
        }
      </div>
    }
    else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      returnData = <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div className={'custom-button-group-long'}>
          <Link to={"/items/" + (item.id || item.objectID)} target="_blank">
            <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                    id={(item.id || item.objectID)}>商品詳細</MDBBtn>
          </Link>
          <MDBBtn size="sm4"
                  className={[orderButtonText.length > 3 && 'twoRow', disableButton && 'custom-button-grey']}
                  disabled={disableButton} onClick={() => this.addCart(item)}>{orderButtonText}</MDBBtn>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: 'black', padding: '0.26em 1em'}}
                  onClick={() => this.addFavoriteCart(item)}>お気に入りセット 登録</MDBBtn>
            </div>
            <div className={'custom-button-group-long'} style={{textAlign: 'right', display: 'flex'}}>
          {sds}
            </div>
          {files.length > 0 &&
            <div style={{textAlign: 'right', height: 37, position: 'relative', width: 397}}>
            <div style={{
            position: 'absolute',
            display: 'flex',
            left: -303,
            width: 700,
            justifyContent: 'flex-end'
          }}>
          {files}
            </div>
            </div>}
            </div>
    }
    else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      returnData = <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div className={'custom-button-group-long'}>
          <Link to={"/items/" + (item.id || item.objectID)} target="_blank">
            <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                    id={(item.id || item.objectID)}>商品詳細</MDBBtn>
          </Link>
          <MDBBtn size="sm4"
                  className={[orderButtonText.length > 3 && 'twoRow', disableButton && 'custom-button-grey']}
                  disabled={disableButton} onClick={() => this.addCart(item)}>{orderButtonText}</MDBBtn>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: '#212529', padding: '0.26em 1em'}}
                  onClick={() => this.addFavoriteCart(item)}>お気に入りセット 登録</MDBBtn>
        </div>
        <div className={'custom-button-group-long'} style={{textAlign: 'right', display: 'flex'}}>
          {sds}
        </div>
        {files.length > 0 &&
            <div style={{textAlign: 'right', height: 37, position: 'relative', width: 397}}>
              <div style={{
                position: 'absolute',
                display: 'flex',
                left: -303,
                width: 700,
                justifyContent: 'flex-end'
              }}>
                {files}
              </div>
            </div>
        }
      </div>
    }
    else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
     returnData = <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div className={'custom-button-group-long'}>
          <Link to={"/items/" + (item.id || item.objectID)} target="_blank">
            <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                    id={item.id || item.objectID}>商品詳細</MDBBtn>
          </Link>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: 'black', padding: '0.26em 1em'}}
                  onClick={() => this.addFavoriteCart(item)}>お気に入りセット 登録</MDBBtn>
          <MDBBtn size="sm4" color={''} disabled={disableButton} style={{color: 'black', padding: '0.26em 1em'}}
                  onClick={() => this.addOtasukeCart(item)}>お助けカートに入れる</MDBBtn>
        </div>
        <div className={'custom-button-group-long'} style={{textAlign: 'right', display: 'flex'}}>
          {sds}
        </div>
        {files.length > 0 &&
            <div style={{textAlign: 'right', height: 37, position: 'relative', width: 397}}>
              <div style={{
                position: 'absolute',
                display: 'flex',
                left: -303,
                width: 700,
                justifyContent: 'flex-end'
              }}>
                {files}
              </div>
            </div>
        }
      </div>
    }
    return returnData
  }
   /*onSelectionChanged = ({selectedRowsData}) => {
    console.log("selectttt",selectedRowsData);
     let checked = this.itemIsSelected(selectedRowsData[0]);
     console.log('checked', checked);
     //let check =
     this.itemSelect(checked, selectedRowsData[0])

   }*/
  render() {
    if (! this.props.org ) {
      return (
          <>
          </>
      )
    }
    const items = [] //!this.state.isSearch ? [] : this.dataGrid.props.dataSource;
    //console.log(this.state.isSearch && this.dataGrid);

    let isBuyer = false;

    let alogriaSearch = false;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // メーカー
      // maker
      // console.log('maker')
    }else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・仕入れモード
      // sale/purchase
      // console.log('sale/purchase')
      isBuyer = true;
    }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // 購入ユーザー
      // purchase
      // console.log('purchase')
      isBuyer = true;
      alogriaSearch = true;
    }else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // 販社・販売モード
      // sale/sale
      // console.log('sale/sale')
      alogriaSearch = true;
    }

    //if (!this.state.isSearch) {
    //items.push(this.state.dbItems);
    this.state.dbItems.forEach((item, index) => {
      if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
        console.log('メーカー Manufacturer');
        // メーカー Manufacturer
        //let checked = this.itemIsSelected(item);
        items.push({
          id: item.id,
          objectID: item.objectID !==undefined ? item.objectID : null,
          price: item['price'],
          files: item['files'],
          sdsManagement: item['sdsManagement'],
          image: item.image,
          '品名': item['P4CLOUD商品名'],
          'メーカー名': item['正式メーカー名'],
        })
      } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
        // 販社・仕入れモード Sales company/purchase mode
        console.log('販社・仕入れモード Sales company/purchase mode');
        let checked = this.itemIsSelected(item);
        items.push({
          id: item.id,
          objectID: item.objectID,
          price: item['price'],
          files: item['files'],
          sdsManagement: item['sdsManagement'],
          image: item.image,
          '品名': item['P4CLOUD商品名'],
          'メーカー名': item['正式メーカー名'],
        })
      } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
        // 購入ユーザー  Purchasing user     }else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
        //let checked = this.itemIsSelected(item);
        console.log('Purchasing user ');
        items.push({
          id: item.id,
          objectID: item.objectID !==undefined ? item.objectID : null,
          price: item['price'],
          files: item['files'],
          sdsManagement: item['sdsManagement'],
          image: item.image,
          '品名': item['P4CLOUD商品名'],
          'メーカー名': item['正式メーカー名'],
        })
      } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller)
        items.push({
          id: item.id,
          objectID: item.objectID,
          price: item['price'],
          files: item['files'],
          sdsManagement: item['sdsManagement'],
          image: item.image,
          '品名': item['P4CLOUD商品名'],
          'メーカー名': item['正式メーカー名'],
        })
    });
    this.state.loading && this.dataGrid.instance.pageIndex(this.dataGrid.instance.pageCount());
    //}
    let monthChecked = true;
    Object.keys(this.state.months).forEach((value => {
      if (this.state.months[value]) {
        // console.log('value', value, true)
        return monthChecked = false;
      }
    }))
    // console.log('alogriaSearch',! alogriaSearch)

    return (
        <>
          <MDBContainer>
            <MDBRow style={{alignItems: 'center'}}>
              {/*<MDBBtn size="sm4" onClick={() => this.updateExpireDate()}>updateItem</MDBBtn>*/}
              { ! alogriaSearch &&
                  <MDBCol md="2">
                    <div className="input-group md-form form-sm form-1 pl-0">
                      <div className="input-group-prepend">
            <span className="input-group-text lighten-3" id="basic-text1">
              <MDBIcon className="text-white" icon="search" />
            </span>
                      </div>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder="全文検索"
                          aria-label="全文検索"
                          onChange={this.handleSearchwordChange}
                          onKeyDown={this.handleSearchwordKeyDown}
                          value={this.state.searchword}
                      />
                    </div>
                  </MDBCol>
              }
              { alogriaSearch &&
                  <MDBCol md="2">
                    <div className="input-group md-form form-sm form-1 pl-0">
                      <div className="input-group-prepend">
            <span className="input-group-text lighten-3" id="basic-text1">
              <MDBIcon className="text-white" icon="search" />
            </span>
                      </div>
                      <input
                          className="form-control my-0 py-1"
                          type="text"
                          placeholder="全文検索"
                          aria-label="全文検索"
                          onChange={this.handleSearchwordAChange}
                          onKeyDown={this.handleSearchwordAKeyDown}
                          value={this.state.searchwordA}
                      />
                    </div>
                  </MDBCol>
              }

              <MDBCol md="2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
                  </div>
                  <input
                      className="form-control my-0 py-1"
                      type="text"
                      placeholder="商品名検索"
                      aria-label="商品名検索"
                      onChange={this.handleSearchwordAChange1}
                      onKeyDown={this.handleSearchwordAKeyDown1}
                      value={this.state.searchwordA1}
                  />
                </div>
              </MDBCol>
              <MDBCol md="2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
                  </div>
                  <input
                      className="form-control my-0 py-1"
                      type="text"
                      placeholder="正式メーカー商品名"
                      aria-label="正式メーカー商品名"
                      onChange={this.handleSearchwordAChange2}
                      onKeyDown={this.handleSearchwordAKeyDown2}
                      value={this.state.searchwordA2}
                  />
                </div>
              </MDBCol>
              <MDBCol md="6" style={{textAlign: 'right'}}>
                <div className="form-check form-check-inline">
                  <input type="checkbox" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample"
                         checked={this.state.selectSDS === true} onClick={() => this.selectSDSItem(!this.state.selectSDS)}/>
                  <label className="form-check-label" htmlFor="materialInline2">SDS登録商品</label>
                </div>

                <div className={'form-check form-check-inline'}>
                  <MDBBtn color="" size={'sm'} style={{backgroundColor: '#4285F4', padding: '0.3rem 1rem', fontSize: '0.9rem'}} onClick={() => this.filter()}>
                    表示
                  </MDBBtn>
                  <button type="button" className="btn btn-default btn-sm" disabled={this.state.excelLoading} onClick={() => this.loadFullData()}>
                    商品一覧 ダウンロード
                    &nbsp;
                    {this.state.excelLoading && <div className="spinner-border spinner-border-sm" role="status">
                    </div>}
                  </button>
                </div>


                {/*<div className="form-check form-check-inline" style={{marginLeft: 20}}>*/}
                {/*  絞込み*/}
                {/*</div>*/}

                {/*<div className="form-check form-check-inline">*/}
                {/*  <select className="browser-default custom-select" style={{width: 200}} value={this.state.productType} onChange={(e) => this.selectProductType(e)}>*/}
                {/*    <option value={null}>すべて</option>*/}
                {/*    <option value={1}>SDS登録商品</option>*/}
                {/*    <option value={2}>SDS未登録商品</option>*/}
                {/*    /!*<option value={3}>要見積商品</option>*!/*/}
                {/*    /!*<option value={4}>価格有効期限切れ商品</option>*!/*/}
                {/*  </select>*/}
                {/*</div>*/}

                {/*<div className={'form-check form-check-inline'}>*/}
                {/*  <MDBBtn*/}
                {/*      color="default" style={{padding: 10}} flat={true} outline={true} onClick={() => this.clearFilter()}>*/}
                {/*    リセット*/}
                {/*  </MDBBtn>*/}
                {/*</div>*/}
              </MDBCol>
            </MDBRow>
            <CartSelect isOpen={this.state.cartselect} isOpenEvent={(val, added) => this.showHideCart(val, added)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
            <OtasukeCartSelect isOpen={this.state.otasukeCartSelect} isOpenEvent={(val, added) => this.showHideOtasukeCart(val, added)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
            <FavoriteCartSelect isOpen={this.state.favoriteCartSelect} isOpenEvent={(val, added) => this.showHideFavoriteCart(val, added)} db={this.props.db} user={this.props.user} data={this.state.selectedItem}　org={this.props.org} userOrg={this.props.userOrg} dateNow={this.props.dateNow} />
          </MDBContainer>

          {
           <DataGrid //id={"div2"}
                  keyExpr={"id"}
                  noDataText="No Data"
                  dataSource={items}
                  allowColumnResizing={true}
                  wordWrapEnabled={true}
                  ref={(ref) => (this.dataGrid = ref)}
                  focusedRowEnabled={true}
                  //columnAutoWidth={true}
                  //columnWidth={'auto'}
                  //focusedRowEnabled={true}
                  rowAlternationEnabled={true}
                  style={{alignment: 'center', alignItems: 'center'}}
                  width={'100%'}
                  showBorders={false} sorting={{ascendingText:'昇順',descendingText:'降順',clearText:'並び替え解除'}}
                  showColumnLines={false}
                  showRowLines={true}
                  //onOptionChanged={this.handleOptionChange}
                  paging={{enabled: true,pageSize:12}} //renderAsync={true}
                  onCellPrepared={this.onCellPreparedHandler}
                  //onSelectionChanged={this.onSelectionChanged}
                  //allowColumnReordering={true}
              >     <Export enabled={false} allowExportSelectedData={false} texts={{exportAll:'すべてのデータを Excel にエクスポートする'}}/>
             <RemoteOperations
                 filtering={true}
                 paging={true}
                 sorting={true}
                 summary={true}
                 grouping={true}
                 groupPaging={true}
             />
                <Sorting mode="none" />
                <Scrolling
                    mode={'standard'} showScrollbar={'always'} useNative={'false'}
                    renderAsync={true}
                    preloadEnabled = {true} rowRenderingMode={'virtual'} columnRenderingMode={'virtual'}
                />
                <ColumnChooser
                    enabled={true} height={"80%"} allowSearch={true}
                    mode="select" title={'列セレクター'}>
                  <ColumnChooserSearch
                      editorOptions={{ placeholder: '見出し検索', mode: 'text'}}
                      enabled={true}
                      timeout={800}
                  />
                </ColumnChooser>
                <ColumnFixing enabled={true} texts={{fix:'固定',leftPosition:'最左列に',rightPosition:'最右列に',unfix:'固定解除'}}/>
                <FilterRow visible={true} operationDescriptions={{contains:'次の語を含む',notContains:'次の語を含まない',startsWith:'次の語から始まる',endsWith:'次の語で終わる',equal:'等しい',notEqual:'等しくない',}} resetOperationText={'リセット'} />
                <Pager
                visible={!this.state.loading ? true : false}
                allowedPageSizes={[12]}
                displayMode={'full'}
                //showPageSizeSelector={true}
                showInfo={true}
                infoText={`{0}/{1} ページ (全 {2} 品)`}
                showNavigationButtons={true}/>
             {/*<Selection
                 mode="multiple"
                 selectAllMode={'allPages'}
                 showCheckBoxesMode={'onClick'}
             />*/}
             <Column
                    alignment="center"
                    caption={''}
                    width={50} allowSorting={false} allowFiltering={false}
                    cellRender={this.checkCell}
                    renderAsync={true}
               />
                {/*<Template name="checkTemplate" render={(item) => {return item.data['check']}}/>*/}
                 <Column
                caption={"画像"}
                width={120} allowSorting={false} allowFiltering={false}
                //width={120}
                alignment="left"
                cellRender={this.imageCell} renderAsync={false}
            />
             <Column
                 caption={"品名"}
                 minWidth={100}
                 alignment="left"
                 dataField={'品名'}
                 /*cellRender={(item) => {
                   return item.data['品名']
                 }}*/
                 calculateCellValue={(item) => {
                   return item && `${item['品名']}`
                 }}
                 renderAsync={false}
             />
             <Column
                 caption={"メーカー名"}
                 width={120}
                 alignment="left"
                 dataField={'メーカー名'}
                 /*cellRender={(item) => {
                   return item.data['メーカー名']
                 }}*/
                 calculateCellValue={(item) => {
                   return item && `${item['メーカー名']}`
                 }}
             />
             <Column
                 caption={this.props.userOrg && this.props.userOrg.maker ? '価格' : '価格'}
                 width={120}
                 //minwidth={120}
                 alignment="left"
                 //dataField={'価格'}
                 cellRender={this.priceCell}
                 renderAsync={false}
             />
             <Column
                 caption={""}
                 minWidth={450}
                 //width={450}
                 alignment="left"
                 //dataField={'btn'}
                 allowSorting={false} allowFiltering={false}
                 cellRender={this.btnCell}
                 renderAsync={false}
             />
                <Paging enabled={true} defaultPageSize={12}/>
              </DataGrid>
          }
          <div style={{display:'flex',justifyContent:'flex-end'}} hidden={this.state.notMore || items.length < 12 ? true : false}>
            <MDBBtn
                color="default" style={{padding: 10}} flat={true} outline={true}
                onClick={this.onReachEnd}>
              次のページ
            </MDBBtn>
          </div>
          {this.state.selectedItemList.length > 0 &&
              <div className={'item-footer'}>
                <MDBBtn color="mdb-color" style={{margin: 25, height: 50, paddingTop: 10}} onClick={this.uncheckSelectedItems}>
                  <MDBIcon icon="minus-square" size={"lg"} /><span style={{fontSize: 14}}> チェックを外す</span>
                </MDBBtn>
                <MDBBtn
                    color="amber"
                    bgColor="warning-color-dark"
                    text="white"
                    className="text-nowrap"
                    style={{margin: 25, height: 50}}
                    onClick={this.addSelectedItemsFavorite}
                ><img src={"/folder-star-white.png"} className={"folder-star"} style={{width: 20}}/><span style={{fontSize: 14}}> まとめてお気に入りセット登録</span></MDBBtn>
                {
                    !isBuyer && <MDBBtn
                        color="white"
                        bgColor="warning-color-dark"
                        text="black"
                        className="text-nowrap"
                        style={{margin: 25, height: 50, paddingTop: 10}}
                        onClick={this.addSelectedItems}
                    ><MDBIcon icon="shopping-cart" size="lg"/><span style={{fontSize: 14}}> まとめてお助けカートに入れる</span></MDBBtn>
                }
              </div>
          }

          <MDBModal isOpen={this.state.sdsManagementModal} toggle={this.sdsManagementClose}>
            <MDBModalHeader toggle={this.sdsManagementClose}>SDS管理申込　入力</MDBModalHeader>
            <MDBModalBody>
              <div>商品名：{this.state.selectSDSItem['P4CLOUD商品名']}</div>
              <br/>
              <div>希望チェック月を入力してください。</div>
              <br/>
              <div>定期チェック希望月（毎年）</div>
              <div style={{display: 'flex', flex: 1, marginBottom: 7}}>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[1]} className="custom-control-input" id="checkboxMonth1" onChange={(e) => this.monthSelect(e.target.checked, 1)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth1">1月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[2]} className="custom-control-input" id="checkboxMonth2" onChange={(e) => this.monthSelect(e.target.checked, 2)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth2">2月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[3]} className="custom-control-input" id="checkboxMonth3" onChange={(e) => this.monthSelect(e.target.checked, 3)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth3">3月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[4]} className="custom-control-input" id="checkboxMonth4" onChange={(e) => this.monthSelect(e.target.checked, 4)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth4">4月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[5]} className="custom-control-input" id="checkboxMonth5" onChange={(e) => this.monthSelect(e.target.checked, 5)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth5">5月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[6]} className="custom-control-input" id="checkboxMonth6" onChange={(e) => this.monthSelect(e.target.checked, 6)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth6">6月</label>
                </div>
              </div>
              <div style={{display: 'flex', flex: 1}}>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[7]} className="custom-control-input" id="checkboxMonth7" onChange={(e) => this.monthSelect(e.target.checked, 7)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth7">7月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[8]} className="custom-control-input" id="checkboxMonth8" onChange={(e) => this.monthSelect(e.target.checked, 8)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth8">8月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[9]} className="custom-control-input" id="checkboxMonth9" onChange={(e) => this.monthSelect(e.target.checked, 9)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth9">9月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[10]} className="custom-control-input" id="checkboxMonth10" onChange={(e) => this.monthSelect(e.target.checked, 10)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth10">10月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[11]} className="custom-control-input" id="checkboxMonth11" onChange={(e) => this.monthSelect(e.target.checked, 11)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth11">11月</label>
                </div>
                <div className="custom-control custom-checkbox" style={{flex: 1}}>
                  <input type="checkbox" checked={this.state.months[12]} className="custom-control-input" id="checkboxMonth12" onChange={(e) => this.monthSelect(e.target.checked, 12)}/>
                  <label className="custom-control-label" htmlFor="checkboxMonth12">12月</label>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={this.sdsManagementClose} color="" className={'custom-button-grey'}>戻る</MDBBtn>
              <MDBBtn onClick={this.sdsManagementClose} color="default" data-confirm={true} disabled={monthChecked} >申込確認</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.sdsManagementConfirmModal} toggle={this.sdsManagementConfirmClose}>
            <MDBModalHeader toggle={this.sdsManagementConfirmClose}>SDS管理申込　内容確認</MDBModalHeader>
            <MDBModalBody>
              <div>商品名：{this.state.selectSDSItem['P4CLOUD商品名']}</div>
              <br/>
              <div>以下の内容で申し込みます。</div>
              <br/>
              <div>定期チェック希望月（毎年）</div>
              <div style={{display: 'flex', flex: 1}}>
                {Object.keys(this.state.months).map((v, i) => {
                  if (this.state.months[v]) {
                    return <div key={i} style={{width: 50}}>
                      {v}月
                    </div>
                  }
                })}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={this.sdsManagementConfirmClose} color="" className={'custom-button-grey'}>戻る</MDBBtn>
              <MDBBtn onClick={this.sdsManagementConfirmClose} color="default" data-confirm={true} >申込</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.sdsManagementCompleteModal} toggle={this.sdsManagementCompleteClose}>
            <MDBModalHeader toggle={this.sdsManagementCompleteClose}>SDS管理申込　申込完了</MDBModalHeader>
            <MDBModalBody>
              <div>商品名：{this.state.selectSDSItem['P4CLOUD商品名']}</div>
              <br/>
              <div>以下の内容で申し込みました。</div>
              <br/>
              <div>定期チェック希望月（毎年）</div>
              <div style={{display: 'flex', flex: 1}}>
                {Object.keys(this.state.months).map((v, i) => {
                  if (this.state.months[v]) {
                    return <div key={i} style={{width: 50}}>
                      {v}月
                    </div>
                  }
                })}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={this.sdsManagementCompleteClose} color="default" data-confirm={true} >SDS管理一覧へ</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
        </>
    )

  }
}

export default ItemsListV2;
