import React from "react";
import { MDBSpinner, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter} from 'mdbreact';
import StorageLink from './StorageLink'

class CatalogTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbCatalogs: [],
      last: null,
      catalogPerPage: 12,
    }
  }

  getCatalogs = (props) => {
    console.log("###### getCatalogs ########", props)

    if (this.state.loading) {
      return;
    }

    let fromOrg = "";
    let toOrg = "";
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      fromOrg = this.props.user.organization;
      toOrg = props.org.id;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      fromOrg = props.org.id;
      toOrg = this.props.user.organization;
    }
    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let first;
    this.setState({loading: true})
    if(this.state.last){
      first = this.props.db.collection('catalog')
      .where("fromOrganization", "==", fromOrg)
      .where("toOrganization", "==", toOrg)
      .orderBy('date','desc')
      .startAfter(this.state.last)
      .limit(this.state.catalogPerPage);
    }else{
      first = this.props.db.collection("catalog")
      .where("fromOrganization", "==", fromOrg)
      .where("toOrganization", "==", toOrg)
      .orderBy('date','desc')
      .limit(this.state.catalogPerPage);
    }

    let paginate = first.get()
    .then((snapshot) => {
      this.setState({loading: false})
      if(snapshot.docs.length > 0) {
        let last = snapshot.docs[snapshot.docs.length - 1];

        this.setState({last: last})


        let catalogs = this.state.dbCatalogs;
        snapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          catalogs.push(data)
        })
        this.setState({dbCatalogs: catalogs})
      }else{

      }

    });

  }

  componentDidMount() {
    //this.getCatalogs();
    this.getCatalogs(this.props);
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {
    this.getCatalogs(nextProps);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    console.log("LAST:" , this.state.last);
    this.getCatalogs(this.props);
  }

  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);
  }


  render() {
    const catalogs = [];

    this.state.dbCatalogs.forEach((catalog,index) => {
      const date = catalog['date'].toDate().getFullYear() + '/'
        + (catalog['date'].toDate().getMonth()+1) + '/'
        + catalog['date'].toDate().getDate() + '';


      let data = {
        '発行日':<span className="text-nowrap">{date}</span>,
        'タイトル': <div className="overflow-hidden" style={{ height: "1.5em" }}>{catalog['title']}</div>,
        '内容表示': <StorageLink src={catalog['file']} storage={this.props.storage} target="_blank"><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={catalog.id} onClick={this.openFile} >内容表示</MDBChip></StorageLink>,
      };

      catalogs.push(data);

    });

    const data = {
      columns: [
        {
          label: '発行日',
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: 'タイトル',
          field: 'タイトル',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: '内容表示',
          field: '内容表示',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
      ],
      rows: catalogs
    }


    return (
      <>
      <h2>カタログ一覧</h2>
      <MDBTable responsive btn small>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

      </>
    )

  }
}

export default CatalogTableList;
