import React from "react";
import "./EmailSettings.css";
import {
  MDBSpinner,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBChip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
    MDBSwitch
} from 'mdbreact';

class EmailSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      authorizerApproveEmail: true,
      authorizerRejectEmail: true,
      shippingEmail: true,
      sellerReceiveEmail: true,
      receiveAuthorizerEmail: true,
      sellerEstimateApprovalEmail: false,
      sellerEstimateRejectedEmail: false,
      billRegisterEmail: false,
      billDeletedEmail: false,
      estimates2RegisterEmail: false,
      estimates2DeletedEmail: false,
      reportRegisterEmail: false,
      reportDeletedEmail: false,
      estimatesRegisterEmail: false,
      estimatesDeletedEmail: false,
      deliveryNotesRegisterEmail: false,
      deliveryNotesDeletedEmail: false,
    }
  }

  componentDidMount() {
    console.log(this.props.user)
    this.setState({
      authorizerApproveEmail: this.props.user.authorizerApproveEmail !== false,
      authorizerRejectEmail: this.props.user.authorizerRejectEmail !== false,
      shippingEmail: this.props.user.shippingEmail !== false,
      sellerReceiveEmail: this.props.user.sellerReceiveEmail !== false,
      receiveAuthorizerEmail: this.props.user.receiveAuthorizerEmail !== false,
      sellerEstimateApprovalEmail: this.props.user.sellerEstimateApprovalEmail !== false,
      sellerEstimateRejectedEmail: this.props.user.sellerEstimateRejectedEmail !== false,
      billRegisterEmail: this.props.user.billRegisterEmail !== false,
      billDeletedEmail: this.props.user.billDeletedEmail !== false,
      estimates2RegisterEmail: this.props.user.estimates2RegisterEmail !== false,
      estimates2DeletedEmail: this.props.user.estimates2DeletedEmail !== false,
      reportRegisterEmail: this.props.user.reportRegisterEmail !== false,
      reportDeletedEmail: this.props.user.reportDeletedEmail !== false,
      estimatesRegisterEmail: this.props.user.estimatesRegisterEmail !== false,
      estimatesDeletedEmail: this.props.user.estimatesDeletedEmail !== false,
      deliveryNotesRegisterEmail: this.props.user.deliveryNotesRegisterEmail !== false,
      deliveryNotesDeletedEmail: this.props.user.deliveryNotesDeletedEmail !== false,

    })
  }
  componentWillReceiveProps(nextProps) {}
  componentWillUnmount() {
    // window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  confirmClose = (e) => {
    this.setState({
      confirmModal: false
    });

    if (e) {
      let cId = e.currentTarget.dataset.id;
      if (cId) {
        let usersRef = this.props.db.collection('users').doc(cId);
        console.log('deliveryNotesRegisterEmail', this.state.deliveryNotesRegisterEmail);
        usersRef.set({
          authorizerApproveEmail: this.state.authorizerApproveEmail,
          authorizerRejectEmail: this.state.authorizerRejectEmail,
          shippingEmail: this.state.shippingEmail,
          sellerReceiveEmail: this.state.sellerReceiveEmail,
          receiveAuthorizerEmail: this.state.receiveAuthorizerEmail,
          sellerEstimateApprovalEmail: this.state.sellerEstimateApprovalEmail,
          sellerEstimateRejectedEmail: this.state.sellerEstimateRejectedEmail,
          billRegisterEmail: this.state.billRegisterEmail,
          billDeletedEmail: this.state.billDeletedEmail,
          estimates2RegisterEmail: this.state.estimates2RegisterEmail,
          estimates2DeletedEmail: this.state.estimates2DeletedEmail,
          reportRegisterEmail: this.state.reportRegisterEmail,
          reportDeletedEmail: this.state.reportDeletedEmail,
          estimatesRegisterEmail: this.state.estimatesRegisterEmail,
          estimatesDeletedEmail: this.state.estimatesDeletedEmail,
          deliveryNotesRegisterEmail: this.state.deliveryNotesRegisterEmail,
          deliveryNotesDeletedEmail: this.state.deliveryNotesDeletedEmail
        }, { merge: true });

        this.props.userEmailSetting({
          authorizerApproveEmail: this.state.authorizerApproveEmail,
          authorizerRejectEmail: this.state.authorizerRejectEmail,
          shippingEmail: this.state.shippingEmail,
          sellerReceiveEmail: this.state.sellerReceiveEmail,
          receiveAuthorizerEmail: this.state.receiveAuthorizerEmail,
          sellerEstimateApprovalEmail: this.state.sellerEstimateApprovalEmail,
          sellerEstimateRejectedEmail: this.state.sellerEstimateRejectedEmail,
          billRegisterEmail: this.state.billRegisterEmail,
          billDeletedEmail: this.state.billDeletedEmail,
          estimates2RegisterEmail: this.state.estimates2RegisterEmail,
          estimates2DeletedEmail: this.state.estimates2DeletedEmail,
          reportRegisterEmail: this.state.reportRegisterEmail,
          reportDeletedEmail: this.state.reportDeletedEmail,
          estimatesRegisterEmail: this.state.estimatesRegisterEmail,
          estimatesDeletedEmail: this.state.estimatesDeletedEmail,
          deliveryNotesRegisterEmail: this.state.deliveryNotesRegisterEmail,
          deliveryNotesDeletedEmail: this.state.deliveryNotesDeletedEmail
        })
      }
    }

  };

  render() {

    const data = {
      columns: [
        {
          label: '通知メール受信設定',
          field: '通知メール受信設定',
          sort: 'asc',
          // width: 80,
          minimal: 'sm'
        },
        {
          label: '',
          field: 'toggle',
          sort: 'asc',
          width: 30,
          align: 'right',
          minimal: 'sm'
        },
      ]
    }


    let userType = '';
    let authorizer = this.props.user && this.props.user.authorizer

    if(this.props.userOrg && this.props.userOrg.reseller) {
      userType = "seller";
    }
    if(this.props.userOrg && this.props.userOrg.maker) {
      userType = "maker";
    }
    if(this.props.userOrg && this.props.userOrg.buyer) {
      userType = "buyer";
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
        || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
        || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    console.log(kaigawa, urigawa, userType)

    return (
      <>
      {/*<h2>通知メール受信設定</h2>*/}

        <MDBTable className={'email-settings-table'}>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody>
            {(userType === 'seller' || userType === 'buyer') && authorizer === true &&
                <tr>
                  <td>
                    <div className={'emailSettings'}>
                      <label className={'title'}>
                        上長承認依頼時{userType === 'seller' && '（仕入モード側受信）'}
                      </label>
                      <br/>
                      <label className={'desc'}>
                        ※ カート作成者からの上長承認依頼を通知
                      </label>
                    </div>
                  </td>
                  <td>
                    <MDBSwitch
                        checked={this.state.receiveAuthorizerEmail}
                        getValue={(e) => this.setState({receiveAuthorizerEmail: e})}
                        labelLeft=""
                        labelRight=""
                    />
                  </td>
                </tr>
            }
            {(userType === 'seller' || userType === 'buyer') &&
              <>
                <tr>
                  <td>
                    <div className={'emailSettings'}>
                      <label className={'title'}>
                        上長却下時{userType === 'seller' && '（仕入モード側受信）'}
                      </label>
                      <br/>
                      <label className={'desc'}>
                        ※ 仕入モード側カート作成者に通知
                      </label>
                    </div>
                  </td>
                  <td>
                    <MDBSwitch
                        checked={this.state.authorizerRejectEmail}
                        getValue={(e) => this.setState({authorizerRejectEmail: e})}
                        labelLeft=""
                        labelRight=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={'emailSettings'}>
                      <label className={'title'}>
                        上長承認時{userType === 'seller' && '（仕入モード側受信）'}
                      </label>
                      <br/>
                      <label className={'desc'}>
                        ※ 仕入モード側カート作成者（上長承認依頼送信者）に通知
                      </label>
                    </div>
                  </td>
                  <td>
                    <MDBSwitch
                        checked={this.state.authorizerApproveEmail}
                        getValue={(e) => this.setState({authorizerApproveEmail: e})}
                        labelLeft=""
                        labelRight=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={'emailSettings'}>
                      <label className={'title'}>
                        出荷日送り状番号送信時{userType === 'seller' && '（仕入モード側受信）'}
                      </label>
                      <br/>
                      <label className={'desc'}>
                        ※ 仕入モード側カート作成者に通知
                      </label>
                    </div>
                  </td>
                  <td>
                    <MDBSwitch
                        checked={this.state.shippingEmail}
                        getValue={(e) => this.setState({shippingEmail: e})}
                        labelLeft=""
                        labelRight=""
                    />
                  </td>
                </tr>
              </>
            }
            {(userType === "seller" || userType === 'maker') &&
                <>
                  <tr>
                    <td>
                      <div className={'emailSettings'}>
                        <label className={'title'}>
                          新規注文受取時{userType === 'seller' && '（販売モード側受信）'}
                        </label>
                        <br/>
                        <label className={'desc'}>
                          ※ 発注側カート内で指定された販売側担当者宛てに発注通知
                        </label>
                      </div>
                    </td>
                    <td>
                      <MDBSwitch
                          checked={this.state.sellerReceiveEmail}
                          getValue={(e) => this.setState({sellerReceiveEmail: e})}
                          labelLeft=""
                          labelRight=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className={'emailSettings'}>
                        <label className={'title'}>
                          工事見積書　仕入側承認[発注]{userType === 'seller' && '（販売モード側受信）'}
                        </label>
                        <br/>
                        <label className={'desc'}>
                          ※ 工事見積書を登録した販売側アカウントに通知
                        </label>
                      </div>
                    </td>
                    <td>
                      <MDBSwitch
                          checked={this.state.sellerEstimateApprovalEmail}
                          getValue={(e) => this.setState({sellerEstimateApprovalEmail: e})}
                          labelLeft=""
                          labelRight=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className={'emailSettings'}>
                        <label className={'title'}>
                          工事見積書　仕入側却下{userType === 'seller' && '（販売モード側受信）'}
                        </label>
                        <br/>
                        <label className={'desc'}>
                          ※ 工事見積書を登録した販売側アカウントに通知
                        </label>
                      </div>
                    </td>
                    <td>
                      <MDBSwitch
                          checked={this.state.sellerEstimateRejectedEmail}
                          getValue={(e) => this.setState({sellerEstimateRejectedEmail: e})}
                          labelLeft=""
                          labelRight=""
                      />
                    </td>
                  </tr>
                </>
            }

            {kaigawa && <>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      請求書　登録{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から請求書が登録された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.billRegisterEmail}
                      getValue={(e) => this.setState({billRegisterEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      請求書　削除{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から請求書が削除された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.billDeletedEmail}
                      getValue={(e) => this.setState({billDeletedEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      納品書　登録{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から納品書が登録された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.deliveryNotesRegisterEmail}
                      getValue={(e) => this.setState({deliveryNotesRegisterEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      納品書　削除{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から納品書が削除された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.deliveryNotesDeletedEmail}
                      getValue={(e) => this.setState({deliveryNotesDeletedEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      見積書　登録{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から見積書が登録された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.estimates2RegisterEmail}
                      getValue={(e) => this.setState({estimates2RegisterEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      見積書　削除{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から見積書が削除された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.estimates2DeletedEmail}
                      getValue={(e) => this.setState({estimates2DeletedEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      報告書　登録{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から報告書が登録された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.reportRegisterEmail}
                      getValue={(e) => this.setState({reportRegisterEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      報告書　削除{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先から報告書が削除された際に通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.reportDeletedEmail}
                      getValue={(e) => this.setState({reportDeletedEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      工事見積書　販売側発行{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先より指定された購入側担当者アカウントに通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.estimatesRegisterEmail}
                      getValue={(e) => this.setState({estimatesRegisterEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'emailSettings'}>
                    <label className={'title'}>
                      工事見積書　販売側削除{userType === 'seller' && '（仕入モード側受信）'}
                    </label>
                    <br/>
                    <label className={'desc'}>
                      ※ 仕入先より指定された購入側担当者アカウントに通知
                    </label>
                  </div>
                </td>
                <td>
                  <MDBSwitch
                      checked={this.state.estimatesDeletedEmail}
                      getValue={(e) => this.setState({estimatesDeletedEmail: e})}
                      labelLeft=""
                      labelRight=""
                  />
                </td>
              </tr>
            </>}

          </MDBTableBody>
        </MDBTable>
        <div style={{textAlign: 'center', marginTop: 40, marginBottom: 40}}>
          <MDBBtn size="sm4" className="text-nowrap" onClick={() => this.setState({confirmModal: true})}>設定する</MDBBtn>
        </div>

        <MDBModal isOpen={this.state.confirmModal} toggle={this.confirmClose}>
          <MDBModalHeader toggle={this.confirmClose}
                          className="text-center default-color white-text"
                          titleClass="w-100 font-weight-bold"
          >通知メール受信設定</MDBModalHeader>
          <MDBModalBody>
            通知メール受信設定を更新しました。
          </MDBModalBody>
          <MDBModalFooter>
            {/*<MDBBtn onClick={this.confirmClose} color="secondary">キャンセル</MDBBtn>*/}
            <MDBBtn onClick={this.confirmClose} color="default" data-id={this.props.userUID}>確　認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </>
    )

  }
}

export default EmailSettings;