import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyCywhjakVomxOw2SbX8xB6MJSrPAWU6Qsk",
  authDomain: "ds-p4cloud.firebaseapp.com",
  databaseURL: "https://ds-p4cloud.firebaseio.com",
  projectId: "ds-p4cloud",
  storageBucket: "ds-p4cloud.appspot.com",
  messagingSenderId: "69840953302",
  appId: "1:69840953302:web:cf3e9d32f93ed613620e77",
  measurementId: "G-YW8BHL6WDS"
};

firebase.initializeApp(firebaseConfig)

export default firebase
