import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBSelect
} from 'mdbreact';
import firebase from "../../firebase"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import "./Cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartNo: 0,
      carts: [],
      modal: false,
      modalDelivery: false,
      modalDeliveryDirect: false,
      carriage: 0,
      lastToUser: '',
      lastToUserName: '',
      lastDeliveryAddress: {},
      selectToUser: false,
      // orderBasyo: ''
      modalEmptyItem: false,
      modalEmptyCartItem: false,
      userList: {},
      files1: [],
      files2: [],
      loading: false,
      data: {}
    }
  }


  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if (this.props.org && collection) {
      let cid = this.props.db.collection('carts_info').doc(this.props.user.id).collection('carts_info').doc(this.props.org.id).get();
      let cRef = this.props.db.collection('carts').doc(this.props.user.id).collection('carts').doc(this.props.org.id);
      // console.log('purchaser', this.props.user.id, this.props.org.id)
      let cd = cRef.get();
      let response = await Promise.all([cid, cd]);

      let ciDoc = response[0];
      let cDoc = response[1];

      let ciData = ciDoc.data();

      if (ciData) {
        let lastToUser = ciData.lastToUser;
        let lastToUserName = ciData.lastToUserName;
        let lastDeliveryAddress = ciData.lastDeliveryAddress || {};

        this.setState({
          lastToUser: lastToUser,
          lastToUserName: lastToUserName,
          lastDeliveryAddress: lastDeliveryAddress
        })
      }



      let cData = cDoc.data();

      if (!cData) {
        return;
      }

      let userList = {};

      // let withDeletedItem = false;
      for (let i=0; i<5; i++) {
        let cart = cData[i];

        if (cart && cart.items) {
          let items = cart.items;
          delete cart['deletedItem'];

          let toUserCC = cart.toUserCC || [];
          if (toUserCC.length === 0 && ciData && ciData.lastToUserCC && ciData.lastToUserCC.length > 0) {
            toUserCC = ciData.lastToUserCC;
          }

          let toUser = cart.toUser;
          if (!toUser && ciData && ciData.lastToUser) {
            toUser = ciData.lastToUser;
          }

          userList[i] = this.props.org.users.map((user) => {
            let found = false;

            for (let i=0; i<toUserCC.length; i++) {
              if (toUserCC[i] === user.user) {
                found = true;
              }
            }

            if (found) {
              return {value: user.user, text: user.name, checked: true}
            } else {
              return {value: user.user, text: user.name}
            }
          });

          userList[i] = userList[i].filter(v => v.value !== toUser);

          await Promise.all(items.map(async (item) => {
            let itemSell = await collection.doc(item.id).get();
            let itemSellData = itemSell.data();

            // console.log('itemSellData',itemSellData);
            delete item['deletedItem'];
            delete item['deletedText'];

            if (!itemSellData) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '販売終了';
            } else if (!itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '要見積';
            } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '要見積';
            } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
              let expiration_date = itemSellData['price'][0]['expiration_date'];
              let expDate = expiration_date.toDate();

              if ((expDate.getTime() - this.props.dateNow) < 0) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積';
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
            } else {
              item.price = itemSellData['price'][0]['price'];
            }
            // if (itemSellData && itemSellData['price']) {
            //   item.price = itemSellData['price'][0]['price'];
            // } else {
            //   withDeletedItem = true;
            //   cart.items = cart.items.filter((obj) => obj.id !== item.id);
            // }
          }));
        }
      }

      // if (withDeletedItem) {
      //   cRef.set(cData, {merge: true});
      // }

      let total = 0;
      if (cData[this.state.cartNo] && cData[this.state.cartNo].items && cData[this.state.cartNo].items.length) {
        let items = cData[this.state.cartNo].items;

        items.map(async (item) => {
          if (item) {
            total += item.count * item.price;
          }
        });
      }

      // this.setState({carts: cData});

      let carriage_free = 0;
      let carriage = 0;
      if(this.props.org.maker && this.props.userOrg && this.props.userOrg.sellers){
        // メーカーU -> 販社
        this.props.userOrg.sellers.map((seller) => {
          if(seller.organization == this.props.org.id){
            carriage_free = seller.carriage_free ? seller.carriage_free : 0;
            carriage = seller.carriage ? seller.carriage : 0;
          }
        })
      }

      if(this.props.org.reseller && this.props.userOrg){
        // 販社U -> 購入U
        carriage_free = this.props.userOrg.carriage_free ? this.props.userOrg.carriage_free : 0;
        carriage = this.props.userOrg.carriage ? this.props.userOrg.carriage : 0;
      }
      let postage = total < carriage_free ? carriage : 0 ;

      this.setState({carriage: postage, carts: cData, userList})
    }
  }

  selectUsers = () => {
    let userList = {};
    let carts = this.state.carts;
    for (let i=0; i<5; i++) {
      let cart = carts[i];
      // console.log('cart', i, cart)
      if (cart) {
        let toUserCC = cart.toUserCC || [];
        userList[i] = this.props.org.users.map((user) => {
          let found = false;
          for (let i=0; i<toUserCC.length; i++) {
            if (toUserCC[i] === user.user) {
              found = true;
            }
          }
          if (found) {
            return {value: user.user, text: user.name, checked: true}
          } else {
            return {value: user.user, text: user.name}
          }
        });
      }
    }

    // let toUserCC = this.state.carts[this.state.cartNo].toUserCC || [];
    // let userList = this.props.org && this.props.org.users && this.props.org.users.map((user) => {
    //   let found = false;
    //   for (let i=0; i<toUserCC.length; i++) {
    //     if (toUserCC[i] === user.user) {
    //       found = true;
    //     }
    //   }
    //   if (found) {
    //     return {value: user.user, text: user.name, checked: true}
    //   } else {
    //     return {value: user.user, text: user.name}
    //   }
    // });

    this.setState({userList})
  }

  componentDidUpdate = (prevProps) => {
    // console.log('componentDidUpdate', this.props.id)
    if (this.props.org !== prevProps.org || this.props.userOrg !== prevProps.userOrg || this.props.id !== prevProps.id) {
      this.getCarts();
    }

    let cart = 0;
    cart = this.props.id - 1;
    if(isNaN(cart)){
      cart=0;
    }

    if (this.state.cartNo != cart){
      this.setState({cartNo: cart, selectToUser: false});
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.getCarts();
  }

  decrease = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("decrease:");
    console.log(itemId);

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        if(cart[index]['count']>0){
          cart[index]['count']--;
          cart[index]['update'] = date;
        }
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  description = (e) => {
    // console.log('ee',e);
    let value = e.currentTarget.value;
    const itemId = e.currentTarget.parentNode.dataset.id;

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId) {
        cart[index]['description'] = value;
        cart[index]['update'] = date;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  increase = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("increase:");
    console.log(itemId);

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        // console.log(cart[index])
        cart[index]['count']++;
        cart[index]['update'] = date;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  updateNum = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNum:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    console.log("Number");
    this.setState({
      numupdate: true,
    })

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
      }
    });
  }

  updateNumSave = (e) => {
    const itemId = e.currentTarget.parentNode.dataset.id;
    console.log("updateNumSave:");
    console.log(itemId);

    if( ! parseInt(e.target.value,10) > 0){
      return;
    }
    let carts = {};
    Object.assign(carts, this.state.carts);

    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    cart.forEach((item, index) => {
      if(index == itemId){
        cart[index]['count'] = parseInt(e.target.value,10);
        cart[index]['update'] = date;
      }
    });

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id)
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }


  dellCart = (e) => {
    const itemId = e.currentTarget.dataset.id;
    console.log("dellCart:");
    console.log(itemId);

    let carts = this.state.carts;
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }
    if(cartBase.requested) {
      return;
    }
    let cart = cartBase.items;
    if(!cart){
      cart=[];
    }
    let newCart = [];
    cart.forEach((item, index) => {
      if(index != itemId){
        newCart.push(cart[index]);
      }
    });
    // console.log(newCart)
    if (newCart.length > 0) {
      // console.log('item', newCart.length)
      carts[this.state.cartNo].items = newCart;

      delete carts[this.state.cartNo]['deletedItem'];
      delete carts[this.state.cartNo]['deletedText'];

      let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
      let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let setWithMerge = cartRef.set({
        [this.state.cartNo]: carts[this.state.cartNo],
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            this.getCarts();
          });
    } else {
      console.log('empty')

      let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
      let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let setWithMerge = cartRef.set({
        [this.state.cartNo]: {},
        update: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })
          .then((doc) => {
            this.getCarts();
          });

    }
  }

  destination = (toUser, toUserName) => {
    // console.log(this.props.user.id, this.props.org.id, toUser, toUserName);
    // return;
    // if (!toUser || !toUserName) {
    //   return;
    // }


    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }

    if(cartBase.requested) {
      return;
    }

    cartBase.toUser = toUser;
    cartBase.toUserName = toUserName;

    // console.log(this.props.user.id, this.props.org.id)

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  ordNoUpdate = (val) => {


    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }

    if(cartBase.requested) {
      return;
    }

    cartBase.orderNo = val;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  // goDeliveryAddress = (e) => {
  //   this.saveCart();
  //   this.props.cProps.history.push('/deliveryAddress?cn='+(this.state.cartNo)+'&ct=normal')
  // }

  updateToDB = (field, val) => {
    let date = firebase.firestore.Timestamp.fromDate(new Date());

    let carts = {};
    Object.assign(carts, this.state.carts);
    let cartBase = carts[this.state.cartNo];
    if(!cartBase){
      cartBase={items:[]};
      carts[this.state.cartNo] = cartBase;
    }

    if(cartBase.requested) {
      return;
    }

    cartBase[field] = val;

    delete carts[this.state.cartNo]['deletedItem'];
    delete carts[this.state.cartNo]['deletedText'];

    let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id);
    let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

    cartMainRef.set({
      dummy: 'test'
    }, {merge: true})

    let setWithMerge = cartRef.set({
      [this.state.cartNo]: carts[this.state.cartNo],
      update: date,
    }, { merge: true });

    let items = carts[this.state.cartNo].items;
    for (let i=0; i<items.length; i++) {
      if (items[i].deletedItem) {
        carts[this.state.cartNo]['deletedItem'] = items[i].deletedItem;
        carts[this.state.cartNo]['deletedText'] = items[i].deletedText;
      }
    }

    //this.getCarts();
    this.setState({
      carts: carts,
    })
  }

  handleOrderNoChange = (e) => {
    this.updateCartData("orderNo",e.currentTarget.value);
    // this.ordNoUpdate(e.currentTarget.value)
    this.updateToDB('orderNo', e.currentTarget.value);
  }

  userListChanger = (toUser) => {
    let cart = this.state.carts[this.state.cartNo];
    let userList = this.state.userList;

    let toUserCC = cart.toUserCC || [];
    userList[this.state.cartNo] = this.props.org.users.map((user) => {
      let found = false;

      for (let i=0; i<toUserCC.length; i++) {
        if (toUserCC[i] === user.user) {
          found = true;
        }
      }

      if (found) {
        return {value: user.user, text: user.name, checked: true}
      } else {
        return {value: user.user, text: user.name}
      }
    });

    userList[this.state.cartNo] = userList[this.state.cartNo].filter(v => v.value !== toUser);
    this.setState({userList});
  };

  handleToUserChange = (e) => {
    this.setState({
      selectToUser: true
    })
    if (e.currentTarget.selectedIndex > 0) {
      this.updateCartData("toUserName",e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name);
      this.updateCartData("toUser",e.currentTarget.value);

      this.userListChanger(e.currentTarget.value)

      this.destination(e.currentTarget.value, e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name)
    } else {
      this.updateCartData("toUserName",'');
      this.updateCartData("toUser",'');
      this.destination('', '');
      this.userListChanger('')
    }
  }

  selectPersonAlert = () => {
    toast.warning("相手先営業担当者を先に選択してください。", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  selectBusinessPersons = (v) => {
    let cartData = this.state.carts[this.state.cartNo] || {};
    // let userList = this.state.userList;

    if (cartData.requested === true) {
      let toUserCC = cartData.toUserCC || [];

      if (v.join() !== toUserCC.join()) {
        this.selectUsers();
        this.updateCartData("toUserCC", cartData.toUserCC || []);
        this.updateCartData("toUserCCName", cartData.toUserCCName || []);
      }
    } else {
      this.updateCartData("toUserCC", v);
      // let toUserCC = cartData.toUserCC;
      // console.log('this.props.org', this.props.org);
      // userList = this.props.org.users.filter(v => v.user !== toUserCC);
      // this.setState({userList});

      let toUserCCName = v.map(userId => {
        return (this.props.org.users.find(data => data.user === userId) || {}).name;
      }) || [];
      this.updateCartData("toUserCCName", toUserCCName);
    }

  }

  handleOrderNoukiChange = (e) => {
    this.updateCartData("orderNouki",e.currentTarget.value);
    this.updateToDB('orderNouki', e.currentTarget.value);
    this.updateToDB('orderNoukiDate', '');

  }
  handleOrderNoukiDayChange = (e) => {
    this.updateCartData("orderNouki","希望");
    this.updateCartData("orderNoukiDay",e.currentTarget.value);

    this.updateToDB('orderNouki', "希望");
    this.updateToDB('orderNoukiDay', e.currentTarget.value);
    this.updateToDB('orderNoukiDate', '');
  }
  handleOrderNoukiDateChange = (e) => {
    this.updateCartData('orderNouki', '日付指定');
    let date = firebase.firestore.Timestamp.fromDate(e);
    this.updateCartData('orderNoukiDate', date);

    this.updateToDB('orderNouki', "日付指定");
    this.updateToDB('orderNoukiDay', '');
    this.updateToDB('orderNoukiDate', date);
  }
  handleOrderBasyoChange = (e) => {
    console.log(e.currentTarget.value)
    this.updateCartData("orderBasyo",e.currentTarget.value);
  }
  handleOrderPropertyNameChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderPropertyName",e.currentTarget.value);
  }
  handleOrderZipcodeChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderZipcode",e.currentTarget.value);
  }
  handleOrderAddr1Change = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderAddr1",e.currentTarget.value);
  }
  handleOrderAddr2Change = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderAddr2",e.currentTarget.value);
  }
  handleOrderJigyosyoChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderJigyosyo",e.currentTarget.value);
  }
  handleOrderTantoChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderTanto",e.currentTarget.value);
  }
  handleOrderPhoneChange = (e) => {
    this.updateCartData("orderBasyo","納品先を直接入力する");
    this.updateCartData("orderPhone",e.currentTarget.value);
  }
  handleOrderBikouChange = (e) => {
    this.updateCartData("orderBikou",e.currentTarget.value);
  }

  deliveryDirectAlert = (e) => {
    console.log('direct alert')
    e.preventDefault();
    this.setState({
      modalDeliveryDirect: true
    })
  }

  deliveryAlert = (e) => {
    e.preventDefault();
    this.setState({
      modalDelivery: true
    })
  }

  emptyItemAlert = (e) => {
    e.preventDefault();
    this.setState({
      modalEmptyItem: true
    })
  }

  modalEmptyCartItemClose = (e) => {
    this.setState({
      modalEmptyCartItem: false
    })
  }

  modalEmptyItemClose = (e) => {
    // if this modal close with modal id === 1 clear 0 count item update cart and send authorizer user
    // if this modal close with modal id === 2 go back history

    let modalId = e && e.currentTarget.dataset.id;
    if (modalId == 1) {
      console.log('modal id', 1)
      this.clearEmptyItemAndRequest();

    } else if (modalId == 2) {
      console.log('modal id', 2)
    }

    this.setState({
      modalEmptyItem: false
    })
  }

  clearEmptyItemAndRequest = () => {
    this.askSuperior(true);
  }

  requestOrder = (e) => {
    e.preventDefault();
    this.setState({
      modal: true,
    })
  }

  modalDeliveryClose = (e) => {
    this.setState({
      modalDelivery: false
    })
  }

  modalDeliveryDirectClose = (e) => {
    this.setState({
      modalDeliveryDirect: false
    })
  }

  fileUpload = async (e) => {
    e.preventDefault();
    this.setState({loading: true});

    // console.log(this.state.orderFiles1, this.state.files1, this.state.orderFiles2, this.state.files2, this.state.files1, this.state.files2);

    // if (this.state.orderFiles1 && this.state.files1.length > 0) {
    //   try {
    //     let fileRef1 = this.props.storage.ref(this.state.orderFiles1);
    //     await fileRef1.delete();
    //   } catch (e) {
    //     console.log('cant delete files1 upload');
    //   }
    // }
    //
    // if (this.state.orderFiles2 && this.state.files2.length > 0) {
    //   try {
    //     let fileRef2 = this.props.storage.ref(this.state.orderFiles2);
    //     await fileRef2.delete();
    //   } catch (e) {
    //     console.log('cant delete files2 upload');
    //   }
    // }
    //
    // // let fileSnapshot = {};
    // let dateNow = new Date().getTime();
    //
    // if (this.state.files1.length > 0) {
    //   let file = this.state.files1[0];
    //   let fileRef = this.props.storage.ref().child('delivery_address/' + this.props.user.id + '/file1' + dateNow + file.name);
    //   try {
    //     await fileRef.put(file);
    //     // fileSnapshot['1'] = file;
    //     console.log('file1',fileRef.location.path);
    //     await this.updateCartData("orderFiles1", fileRef.location.path);
    //     console.log('file1 uploaded')
    //   } catch (e) {
    //     console.log('file1 upload failed', e)
    //   }
    //
    // }
    //
    // if (this.state.files2.length > 0) {
    //   let file = this.state.files2[0];
    //   let fileRef = this.props.storage.ref().child('delivery_address/' + this.props.user.id + '/file2' + dateNow + file.name);
    //   try {
    //     await fileRef.put(file);
    //     // fileSnapshot['2'] = file;
    //     console.log('file2',fileRef.location.path)
    //     await this.updateCartData("orderFiles2", fileRef.location.path);
    //     console.log('file2 uploaded');
    //   } catch (e) {
    //     console.log('file2 upload filed', e)
    //   }
    // }

    this.askSuperior(false);
    // this.dataSet(fileSnapshot);
  }

  askSuperior = async (clearEmptyItem) => {
    let carts = this.state.carts;

    let cartBase = {
      items: [],
      orderNo: '',
      toUser: '',
      toUserName: '',
      toUserCC: [],
      toUserCCName: [],
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '登録住所',
      orderPropertyName: '',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      orderFiles1: null,
      orderFiles2: null,
      requested: false,
      carriage: this.state.carriage,
    };
    if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items){
      Object.assign(cartBase, this.state.carts[this.state.cartNo]);

      if (cartBase.orderNouki === '日付指定' && !cartBase.orderNoukiDate) {
        toast.warn('日付を選択してください', {
          closeButton: true
        });
        return;
      }

      if (clearEmptyItem) {
        let result = cartBase.items.filter(item => item.count > 0) || [];
        cartBase.items = result;

        if (result.length === 0) {
          this.setState({modalEmptyCartItem: true});
        }
      }

      cartBase.requested = true;
      carts[this.state.cartNo] = cartBase;

      let orderRef = this.props.db.collection('orders').doc();
      let dueDate = new Date();
      let created = firebase.firestore.Timestamp.fromDate(new Date());

      if (!cartBase.toUser && this.state.lastToUser) {
        cartBase.toUser = this.state.lastToUser;
        cartBase.toUserName = this.state.lastToUserName;
      }
      // console.log(cartBase)
      // return;

      let deliveryAddress = {};

      if (cartBase.orderBasyo === '前回の納品先' && Object.keys(this.state.lastDeliveryAddress).length > 0) {
        let lastDeliveryAddress = this.state.lastDeliveryAddress;

        Object.assign(deliveryAddress, {
          propertyName: lastDeliveryAddress.propertyName,
          postCode: lastDeliveryAddress.postCode,
          address1: lastDeliveryAddress.address1,
          address2: lastDeliveryAddress.address2,
          officeName: lastDeliveryAddress.officeName,
          personInCharge: lastDeliveryAddress.personInCharge,
          phoneNumber: lastDeliveryAddress.phoneNumber,
          files1: lastDeliveryAddress.files1 || null,
          files2: lastDeliveryAddress.files2 || null
        });

        cartBase.deliveryAddress = {};
        cartBase.orderPropertyName = '';
        cartBase.orderZipcode = '';
        cartBase.orderAddr1 = '';
        cartBase.orderAddr2 = '';
        cartBase.orderJigyosyo = '';
        cartBase.orderTanto = '';
        cartBase.orderPhone = '';
        cartBase.orderFiles1 = null;
        cartBase.orderFiles2 = null;
        Object.assign(cartBase.deliveryAddress, deliveryAddress);

        // console.log(lastDeliveryAddress, deliveryAddress, cartBase.deliveryAddress)
      }

      if (cartBase.orderBasyo === 'アドレス帳から選択する') {
        Object.assign(deliveryAddress, cartBase.deliveryAddress)

        cartBase.orderPropertyName = '';
        cartBase.orderZipcode = '';
        cartBase.orderAddr1 = '';
        cartBase.orderAddr2 = '';
        cartBase.orderJigyosyo = '';
        cartBase.orderTanto = '';
        cartBase.orderPhone = '';
        cartBase.orderFiles1 = null;
        cartBase.orderFiles2 = null;
      }

      if (cartBase.orderBasyo === '販売側からお助けカートで指定された納品先') {
        Object.assign(deliveryAddress, cartBase.otasukeDeliveryAddress)

        // delete cartBase.otasukeDeliveryAddress
      }

      if (cartBase.orderBasyo === '納品先を直接入力する') {
        Object.assign(deliveryAddress, {
          propertyName: cartBase.orderPropertyName,
          postCode: cartBase.orderZipcode,
          address1: cartBase.orderAddr1,
          address2: cartBase.orderAddr2,
          officeName: cartBase.orderJigyosyo,
          personInCharge: cartBase.orderTanto,
          phoneNumber: cartBase.orderPhone,
          files1: cartBase.orderFiles1 || null,
          files2: cartBase.orderFiles2 || null
        })

        delete cartBase.deliveryAddress
      }

      if (cartBase.items.length > 0) {
        let orderData = {
          cart: cartBase,
          cartNo: this.state.cartNo,
          toUser: cartBase.toUser,
          toUserName: cartBase.toUserName,
          toUserCC: cartBase.toUserCC || [],
          toUserCCName: cartBase.toUserCCName || [],
          toOrganization: this.props.org.id,
          fromUser: this.props.user.id,
          fromUserName: this.props.user.last_name + this.props.user.first_name,
          fromOrganization: this.props.user.organization,
          created: created,
          dueDate: dueDate.getTime(),
          latestUpdate: created,
          sortDate: created,
          wait: {
            [this.props.user.id]: true,
          },
          requested: true,
          rejected: false,
          isShipped: false,
          newReject: false,
          newApply: false,
          readSeller: false,
          sellerConfirm: false,
          // readBuyer: false
        }

        await orderRef.set(orderData, { merge: true });
        orderData.id = orderRef.id;

        carts[this.state.cartNo]['orderId'] = orderData.id;

        // console.log('orderData', orderData, carts[this.state.cartNo]);


        delete carts[this.state.cartNo]['deletedItem'];
        delete carts[this.state.cartNo]['deletedText'];

        let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id)
        let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

        cartMainRef.set({
          dummy: 'test'
        }, {merge: true})

        let setWithMerge = cartRef.set({
          [this.state.cartNo]: carts[this.state.cartNo],
          update: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true });

        let cartInfoRef = this.props.db.collection('carts_info').doc(this.props.user.id)
            .collection('carts_info').doc(this.props.org.id);

        let setCartsInfoWithMerge = cartInfoRef.set({
          lastToUser: cartBase.toUser,
          lastToUserName: cartBase.toUserName,
          lastToUserCC: cartBase.toUserCC,
          lastToUserCCName: cartBase.toUserCCName,
          // lastDeliveryAddress: deliveryAddress,
          update: firebase.firestore.Timestamp.fromDate(new Date())
        }, { merge: true }).then((doc) => {
          this.setState({loading: false});
          this.getCarts();
        });
      } else {
        let cartMainRef = this.props.db.collection('carts').doc(this.props.user.id)
        let cartRef = cartMainRef.collection('carts').doc(this.props.org.id);

        let setWithMerge = cartRef.set({
          [this.state.cartNo]: {},
          update: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true });
        this.setState({loading: false});
      }



      // this.getCarts();
    }
  }

  modalClose = (e) => {
    this.setState({
      modal: false,
    })

    let cartId = e.currentTarget.dataset.id;
    if(cartId){
      console.log("request");
      // this.askSuperior(false)
      this.fileUpload(e);
    }

  }

  updateCartData = (key, val) => {
    let cartData = {};
    if(this.state.carts[this.state.cartNo]){
      cartData = this.state.carts[this.state.cartNo];
    }
    if(cartData.requested) {
      return;
    }
    cartData[key] = val;
    let carts = this.state.carts;
    carts[this.state.cartNo] = cartData;

    this.setState({
      carts: carts
    });
  }

  onFilesAdded1 = (files) => {
    this.setState({
      files1: files
    })
  };

  onFilesAdded2 = (files) => {
    this.setState({
      files2: files
    })
  };

  applyFile = (e) => {
    let esId = e.currentTarget.dataset.id;
    this.setState({
      applyModal: true,
      applyId: esId,
    })
  }
  rejectFile = (e) => {
    let esId = e.currentTarget.dataset.id;
    this.setState({
      rejectModal: true,
      rejectId: esId,
    })
  }

  applyClose = async (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
    });

    let esId = e.currentTarget.dataset.id;
    // console.log('applyClose id', esId);
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);

      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      let notifText = '';
      if (data['apply'] && Object.keys(data['apply']).length > 0) {
        notifText = `${data['applyUserName']}はすでに適用されています`;
      }

      if (data['reject'] && Object.keys(data['reject']).length > 0) {
        notifText = `${data['rejectUserName']}はすでに拒否されています`;
      }

      if (notifText) {
        toast.warn(notifText, {
          closeButton: true
        });

        return;
      }

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        apply: {[this.props.user.id]: true},
        applied: true,
        newApply: true,
        cart,
        applyUserName: username,
        orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);

      let deliveryAddress = {}
      if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cart.deliveryAddress;
      }

      if (cart.orderBasyo === '納品先を直接入力する') {
        deliveryAddress = {
          propertyName: cart.orderPropertyName,
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          files1: cart.orderFiles1,
          files2: cart.orderFiles2
        }
      }

      if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
          Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
          cart.otasukeDeliveryAddress.postCode &&
          cart.otasukeDeliveryAddress.address1 &&
          cart.otasukeDeliveryAddress.address2 &&
          cart.otasukeDeliveryAddress.officeName &&
          cart.otasukeDeliveryAddress.personInCharge &&
          cart.otasukeDeliveryAddress.phoneNumber
      ) {
        deliveryAddress = cart.otasukeDeliveryAddress
      }

      let setWithMerge = await cartRef.set({
        [data.cartNo]: {},
      }, { merge: true });

      let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
          .collection('carts_info').doc(data.toOrganization);

      if (Object.keys(deliveryAddress).length > 0) {
        cartInfoRef.set({
          lastDeliveryAddress: deliveryAddress,
          update: firebase.firestore.Timestamp.fromDate(new Date())
        }, {merge: true})
      }

      toast.success("承認（発注確定）しました。", {
        position: toast.POSITION.TOP_RIGHT
      });

      this.getCarts();

    }
  }

  rejectClose = async (e) => {
    this.setState({
      rejectModal: false,
      rejectId: null,
    });

    let esId = e.currentTarget.dataset.id;
    // console.log('rejectClose id', esId);

    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection) {
      let ordersRef = this.props.db.collection('orders').doc(esId);
      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      let notifText = '';
      if (data['apply'] && Object.keys(data['apply']).length > 0) {
        notifText = `${data['applyUserName']}はすでに適用されています`;
      }

      if (data['reject'] && Object.keys(data['reject']).length > 0) {
        notifText = `${data['rejectUserName']}はすでに拒否されています`;
      }

      if (notifText) {
        toast.warn(notifText, {
          closeButton: true
        });

        return;
      }

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let username = this.props.user.last_name + ' ' + this.props.user.first_name
      await ordersRef.set({
        reject: {[this.props.user.id]: true},
        rejectUserName: username,
        newReject: true,
        cart,
        rejectDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, {merge: true});

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);
      let setWithMerge = await cartRef.set({
        [data.cartNo]: {
          requested: false,
          rejected: true,
        },
      }, {merge: true});

      toast.success("却下（担当者に差戻）しました。", {
        position: toast.POSITION.TOP_RIGHT
      });

      this.getCarts();

    }
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let cart = [];
    let total = 0;
    let postage = 0;
    let emptyItem = false;
    let taxes = {};
    let taxTotal = 0;

    let cartData = {
      items: [],
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '',
      orderPropertyName: '',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      orderFiles1: null,
      orderFiles2: null,
      requested: false,
    };
    if(this.state.carts[this.state.cartNo] && this.state.carts[this.state.cartNo].items && this.state.carts[this.state.cartNo].items.length){

      Object.assign(cartData, this.state.carts[this.state.cartNo]);
      console.log('cartData',cartData)
      // console.log('cartToUser: ', cartData.toUser, 'stateToUser: ', this.state.lastToUser)

      if (!cartData.toUser && !this.state.selectToUser) {
        cartData.toUser = this.state.lastToUser
      }

      // cartData.toUser = '';

      let items = this.state.carts[this.state.cartNo].items;
      items.forEach((item, index) => {
        // console.log('item', item)
        if (item.count === 0) {
          emptyItem = true;
        }
        let date = item.update.toDate().getFullYear() + '年'
              + (item.update.toDate().getMonth() + 1) + '月'
              + item.update.toDate().getDate() + '日';

        let priceText = <><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</>;
        if (item.deletedItem) {
          priceText = <><span style={{color: 'red'}}>{item.deletedText}</span></>
        }

              cart.push({
                date: date,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count:
                    <div>
                      <div style={{textAlign: 'right', width: '8rem'}}>{priceText}</div>
                      <div className="def-number-input number-input" data-id={index}>
                        <button onClick={this.decrease} className="minus">－</button>
                        <input
                            className="quantity"
                            type="number"
                            value={item.count}
                            onChange={this.updateNum}
                            onBlur={this.updateNumSave}
                        />
                        <button onClick={this.increase} className="plus">＋</button>
                      </div>
                    </div>
                ,
                price: <div style={{textAlign: 'right'}}>{!item.deletedItem && (item.count * item.price).toLocaleString() + '円'}</div>,
                // ToDo: Songodson data garahgui bgaag zasah
                description:<div data-id={index}>
                  <textarea
                      className="form-control"
                      rows="2"
                      onChange={this.description}
                      value={item.description || ''}
                  />
                </div>,
                btn: <MDBBtn size="sm4" className="text-nowrap"
                        onClick={this.dellCart}
                        data-id={index}
                      >削除</MDBBtn>,
              })

        if (!item.deletedItem) {
          // console.log(parseInt(item.count * item.price * item.tax, 10))
          if (taxes[item.tax]) {
            taxes[item.tax] += (item.count * item.price * item.tax);
          } else {
            taxes[item.tax] = (item.count * item.price * item.tax);
          }
          taxTotal += (item.count * item.price * item.tax);
          total += item.count * item.price;
        }

      });
      // console.log('taxes', taxes);
      postage = this.state.carriage;
      total += postage;
    }

    const isDisable = cartData.toUser === '' || this.state.orderNouki === '' || cartData.orderBasyo === '' || cartData.deletedItem;

    const data = {
      columns: [
        {
          label: '年月日',
          field: 'date',
          sort: 'asc',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>
            金額
          </div>,
          field: 'price,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description,',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '削除',
          field: 'delete',
          sort: 'disabled',
          width: 150,
          minimal: 'sm'
        },
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    let deliveryAddress = {};
    let lastDeliveryAddress = {};

    if (cartData.requested) {
      if (cartData.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cartData.deliveryAddress || {};
      }

      if (cartData.orderBasyo === '前回の納品先') {
        lastDeliveryAddress = cartData.deliveryAddress || {};
      }
    } else {
      deliveryAddress = cartData.deliveryAddress || {};
      lastDeliveryAddress = this.state.lastDeliveryAddress;
    }

    let requestButton = <MDBBtn
        className="btn-lg btn-block"
        color="success"
        disabled={isDisable || this.state.loading}
        onClick={this.requestOrder}
    >{this.state.loading && <div className="spinner-border text-info spinner-border-sm spinner-white" style={{width: '1.2rem', height: '1.2rem'}} role="status"/>} 上長承認依頼</MDBBtn>

    if (cartData.orderBasyo === 'アドレス帳から選択する' && Object.keys(cartData.deliveryAddress || {}).length === 0) {
      requestButton = <MDBBtn
          className="btn-lg btn-block"
          color="success"
          disabled={isDisable}
          onClick={this.deliveryAlert}
      >上長承認依頼</MDBBtn>
    }

    // console.log(cartData)

    if (cartData.orderBasyo === '納品先を直接入力する' &&
    !(
        cartData.orderPropertyName !== '' &&
        cartData.orderZipcode !== '' &&
        cartData.orderAddr1 !== '' &&
        cartData.orderAddr2 !== '' &&
        cartData.orderJigyosyo !== '' &&
        cartData.orderTanto !== '' &&
        cartData.orderPhone !== '')) {
      requestButton = <MDBBtn
          className="btn-lg btn-block"
          color="success"
          disabled={isDisable}
          onClick={this.deliveryDirectAlert}
      >上長承認依頼</MDBBtn>
    }

    if (emptyItem) {
      requestButton = <MDBBtn
          className="btn-lg btn-block"
          color="success"
          disabled={isDisable}
          onClick={this.emptyItemAlert}>
        上長承認依頼
      </MDBBtn>
    }

    return (
      <>
        <h2><MDBIcon icon="shopping-cart" className="mr-3" />カート {this.state.cartNo + 1}</h2>
        <MDBTable btn responsive>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{total.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map(value => {
              return <tr>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

          <div className="form-group row">
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label htmlFor="orderNo" className="col-form-label">発注番号</label>
              &nbsp;
              <span className={'cart-require-any'}>任意</span>
            </div>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="orderNo"
                placeholder="発注番号"
                onChange={this.handleOrderNoChange}
                value={cartData.orderNo}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label htmlFor="orderTo" className="col-form-label">相手先営業担当者選択</label>
              &nbsp;
              <span className={'cart-require'}>必須</span>
            </div>
            <div className="col-sm-10">
              <select
                className="browser-default custom-select"
                id="billToUser"
                onChange={this.handleToUserChange}
                value={cartData.toUser}
                >
                <option>選択してください</option>
                {this.props.org && this.props.org.users &&
                  this.props.org.users.map((user) =>
                    <option key={user.user} value={user.user} data-name={user.name}>{user.name}</option>
                  )
                }
              </select>
            </div>
          </div>

          <div className={'form-group row'}>
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label htmlFor={'ccTo'} className={'col-form-label'}>相手先業務担当者選択<br/>（複数選択可）</label>
              &nbsp;
              <span className={'cart-require-any'}>任意</span>
            </div>
            <div className={'col-sm-10'}>
              <MDBSelect disabled={true} className={'cart-select custom-select'} multiple={true} options={this.state.userList[this.state.cartNo]} getValue={this.selectBusinessPersons}/>
              {!cartData.toUser && <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, cursor: 'pointer'}} onClick={() => this.selectPersonAlert()}/>}
            </div>
          </div>

          <div className="form-group row">
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label className="col-form-label">希望納期</label>
              &nbsp;
              <span className={'cart-require'}>必須</span>
            </div>
            <div className="col-sm-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki1"
                  value="最短"
                  onChange={this.handleOrderNoukiChange}
                  checked={(cartData.orderNouki=='最短' || !cartData.orderNouki) ? true : false}

                />
                <label className="form-check-label" htmlFor="orderNouki1">
                  最短
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderNouki"
                  id="orderNouki2"
                  value="希望"
                  onChange={this.handleOrderNoukiChange}
                  checked={cartData.orderNouki=='希望' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki2" style={{height: 'auto'}}>
                  <div className="form-group form-inline">
                    <input
                    type="number"
                    className="form-control"
                    id="orderNoukiDay"
                    placeholder=""
                    onChange={this.handleOrderNoukiDayChange}
                    value={cartData.orderNoukiDay}
                    />
                    日
                  </div>
                </label>
              </div>
              <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="orderNouki"
                    id="orderNouki3"
                    value="日付指定"
                    onChange={this.handleOrderNoukiChange}
                    checked={cartData.orderNouki=='日付指定' ? true : false}
                />
                <label className="form-check-label" htmlFor="orderNouki3" style={{height: 'auto'}}>
                  <div className="form-group form-inline" >
                    日付指定
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        id="orderNoukiDate"
                        className="form-control"
                        selected={cartData.orderNoukiDate ? cartData.orderNoukiDate.toDate() : ''}
                        onChange={this.handleOrderNoukiDateChange}
                        autoComplete={'off'}
                        locale="ja-JP"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label className="col-form-label">納品場所</label>
              &nbsp;
              <span className={'cart-require'}>必須</span>
            </div>
            <div className="col-sm-10">
              <div className={'form-check'} style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <div>
                  <input
                      className={'form-check-input'}
                      type={'radio'}
                      name={'orderBasyo'}
                      id={'orderBasyo1'}
                      value={'前回の納品先'}
                      onChange={this.handleOrderBasyoChange}
                      disabled={Object.keys(this.state.lastDeliveryAddress || {}).length === 0}
                      checked={cartData.orderBasyo=='前回の納品先' ? true : false}
                  />
                  <label className={'form-check-label'} htmlFor={'orderBasyo1'}>
                    前回の納品先
                  </label>
                </div>
                {Object.keys(lastDeliveryAddress).length > 0 && <div style={{marginTop: 10}}>
                  <div>物件名：{lastDeliveryAddress.propertyName}</div>
                  <div>{lastDeliveryAddress.postCode}</div>
                  <div>{lastDeliveryAddress.address1} {lastDeliveryAddress.address2} {lastDeliveryAddress.officeName}</div>
                  <div>受取担当者：{lastDeliveryAddress.personInCharge}</div>
                  <div>電話番号：{lastDeliveryAddress.phoneNumber}</div>
                  {/*<div>image: {lastDeliveryAddress.files1 && <StorageLink src={lastDeliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                  {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*  </StorageLink>}*/}
                  {/*  {lastDeliveryAddress.files2 && <StorageLink src={lastDeliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                  {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*  </StorageLink>}*/}
                  {/*</div>*/}
                </div>}

              </div>
              {Object.keys(cartData.otasukeDeliveryAddress || {}).length > 0 &&
                <div className={'form-check'} style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                  <div>
                    <input
                        className={'form-check-input'}
                        type={'radio'}
                        name={'orderBasyo'}
                        id={'orderBasyo5'}
                        value={'販売側からお助けカートで指定された納品先'}
                        onChange={this.handleOrderBasyoChange}
                        // disabled={Object.keys(this.state.lastDeliveryAddress || {}).length === 0}
                        checked={cartData.orderBasyo=='販売側からお助けカートで指定された納品先' ? true : false}
                    />
                    <label className={'form-check-label'} htmlFor={'orderBasyo5'}>
                      販売側からお助けカートで指定された納品先
                    </label>
                  </div>
                  <div style={{marginTop: 10}}>
                    <div>物件名：{cartData.otasukeDeliveryAddress.propertyName}</div>
                    <div>{cartData.otasukeDeliveryAddress.postCode}</div>
                    <div>{cartData.otasukeDeliveryAddress.address1} {cartData.otasukeDeliveryAddress.address2} {cartData.otasukeDeliveryAddress.officeName}</div>
                    <div>受取担当者：{cartData.otasukeDeliveryAddress.personInCharge}</div>
                    <div>電話番号：{cartData.otasukeDeliveryAddress.phoneNumber}</div>
                    {/*<div>image: {cartData.otasukeDeliveryAddress.files1 && <StorageLink src={cartData.otasukeDeliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*  </StorageLink>}*/}
                    {/*  {cartData.otasukeDeliveryAddress.files2 && <StorageLink src={cartData.otasukeDeliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*  </StorageLink>}*/}
                    {/*</div>*/}
                  </div>
                </div>
              }

              <div className="form-check" style={{
                border: '1px solid #ced4da',
                borderRadius: 5,
                padding: 20,
                margin: '20px 0',
                display: 'flex',
                flexDirection: 'column'
                // alignItems: 'center',x
                // justifyContent: 'space-between'
              }}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                  <input
                      className="form-check-input"
                      type="radio"
                      name="orderBasyo"
                      id="orderBasyo2"
                      value="アドレス帳から選択する"
                      onChange={this.handleOrderBasyoChange}
                      checked={(cartData.orderBasyo=='アドレス帳から選択する') ? true : false}
                  />
                  <label className="form-check-label" htmlFor="orderBasyo2">
                    アドレス帳から選択する
                  </label>
                  {cartData.requested ?
                        <MDBBtn className="btn-sm"
                                color=""
                                disabled={true}
                                style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}>
                          アドレス帳を開く
                        </MDBBtn>
                      :
                      <Link to={'/deliveryAddress?cn='+(this.state.cartNo)+'&ct=normal'}>
                        <MDBBtn className="btn-sm"
                                color=""
                                onClick={this.goDeliveryAddress}
                                style={{width: 200, backgroundColor: '#2D486A', color: '#ffffff'}}>
                          アドレス帳を開く
                        </MDBBtn>
                      </Link>
                  }

                </div>
                {Object.keys(deliveryAddress).length > 0 && <div>
                  <div>物件名：{deliveryAddress.propertyName}</div>
                  <div>{deliveryAddress.postCode}</div>
                  <div>{deliveryAddress.address1} {deliveryAddress.address2} {deliveryAddress.officeName}</div>
                  <div>受取担当者：{deliveryAddress.personInCharge}</div>
                  <div>電話番号：{deliveryAddress.phoneNumber}</div>
                  {/*<div>image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                  {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*  </StorageLink>}*/}
                  {/*  {deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                  {/*    <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*  </StorageLink>}*/}
                  {/*</div>*/}
                </div>}

              </div>
              <div className="form-check" style={{border: '1px solid #ced4da', borderRadius: 5, padding: 20, margin: '20px 0'}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="orderBasyo"
                  id="orderBasyo3"
                  value="納品先を直接入力する"
                  onChange={this.handleOrderBasyoChange}
                  checked={(cartData.orderBasyo=='納品先を直接入力する') ? true : false}
                />
                <label className="form-check-label" htmlFor="orderBasyo3">
                  納品先を直接入力する
                </label>
                <div className="form-group row">
                  <label htmlFor="orderPropertyName" className="col-sm-3 col-form-label">物件名</label>
                  <div className="col-sm-9">
                    <input
                        type="text"
                        className="form-control"
                        id="orderPropertyName"
                        onChange={this.handleOrderPropertyNameChange}
                        value={cartData.orderPropertyName}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderZipcode" className="col-sm-3 col-form-label">郵便番号</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderZipcode"
                      onChange={this.handleOrderZipcodeChange}
                      value={cartData.orderZipcode}
                      />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderAddr1" className="col-sm-3 col-form-label">住所１（都道府県・市区町村）</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderAddr1"
                      onChange={this.handleOrderAddr1Change}
                      value={cartData.orderAddr1}
                      />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderAddr2" className="col-sm-3 col-form-label">住所2（番地・建物名）</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderAddr2"
                      onChange={this.handleOrderAddr2Change}
                      value={cartData.orderAddr2}
                      />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderJigyosyo" className="col-sm-3 col-form-label">事業所名</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderJigyosyo"
                      onChange={this.handleOrderJigyosyoChange}
                      value={cartData.orderJigyosyo}
                      />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderTanto" className="col-sm-3 col-form-label">担当者名</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderTanto"
                      onChange={this.handleOrderTantoChange}
                      value={cartData.orderTanto}
                      />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="orderPhone" className="col-sm-3 col-form-label">電話番号</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="orderPhone"
                      onChange={this.handleOrderPhoneChange}
                      value={cartData.orderPhone}
                      />
                  </div>
                </div>

                {/*<div className={'form-group row'}>*/}
                {/*  <label htmlFor={'orderImage'} className={'col-sm-3 col-form-label'}>image</label>*/}
                {/*  <div className={'col-sm-9'}>*/}
                {/*    {cartData.requested && <div style={{display: 'flex'}}>*/}
                {/*      {cartData.orderFiles1 && <StorageLink src={cartData.orderFiles1} storage={this.props.storage} target="_blank">*/}
                {/*        <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                {/*      </StorageLink>}*/}
                {/*      {cartData.orderFiles2 && <StorageLink src={cartData.orderFiles2} storage={this.props.storage} target="_blank">*/}
                {/*        <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                {/*      </StorageLink>}*/}
                {/*    </div>}*/}
                {/*    {!cartData.requested && <div style={{display: 'flex'}}>*/}
                {/*      <div style={{flex: 1}}>*/}
                {/*        <Upload onFilesAdded={this.onFilesAdded1} ref="UploadComponent" />*/}
                {/*      </div>*/}
                {/*      <div style={{flex: 1}}>*/}
                {/*        <Upload onFilesAdded={this.onFilesAdded2} ref="UploadComponent" />*/}
                {/*      </div>*/}
                {/*    </div>}*/}
                {/*  </div>*/}
                {/*</div>*/}

              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className={'col-sm-2'} style={{display: 'flex', alignItems: 'flex-start'}}>
              <label htmlFor="orderBikou" className="col-form-label">備考</label>
              &nbsp;
              <span className={'cart-require-any'}>任意</span>
            </div>

            <div className="col-sm-10">
              <textarea
                className="form-control"
                id="orderBikou"
                rows="3"
                onChange={this.handleOrderBikouChange}
                value={cartData.orderBikou}
          />
            </div>
          </div>

          { cartData.rejected &&
          <div
            className="text-center p-3 mb-2 bg-warning text-dark"
          >却下（差戻）</div>
          }

          { cartData.requested ?
              <div>
                <div className="text-center p-3 mb-2 bg-warning text-dark">
                  承認依頼中
                </div>
                {this.props.user.authorizer && cartData['orderId'] && <div className="text-center">
                  <MDBBtn
                      color=""
                      // bgColor="default-color"
                      text="white"
                      className="btn-lg text-nowrap col-sm-5 customGreenButton"
                      data-id={cartData['orderId']}
                      onClick={this.applyFile}>承認（発注確定）確認</MDBBtn>

                  <MDBBtn
                      color=""
                      // bgColor="warning-color-dark"
                      text="white"
                      className="btn-lg text-nowrap col-sm-5 customOrangeButton"
                      data-id={cartData['orderId']}
                      onClick={this.rejectFile}>却下（担当者に差戻）確認</MDBBtn>
                </div>}
              </div>
              :
              requestButton
          }

        </div>
        }
        <MDBModal isOpen={this.state.modal} toggle={this.modalClose}>
          <MDBModalHeader toggle={this.modalClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
          上長に承認依頼をします。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.modalClose} color="danger" data-id={this.state.cartNo} >上長に承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalEmptyCartItem} toggle={this.modalEmptyCartItemClose}>
          <MDBModalHeader toggle={this.modalEmptyCartItemClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            数量0の商品が削除されたため、カートに商品がありません
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalEmptyCartItemClose} color={'secondary'} className="text-nowrap">カートに戻る</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalEmptyItem} toggle={this.modalEmptyItemClose}>
          <MDBModalHeader toggle={this.modalEmptyItemClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            カート内に数量「０」の商品が含まれています。<br/>
            数量「０」の商品を削除して上長承認依頼しますか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalEmptyItemClose} color={'secondary'} data-id={2} className="text-nowrap">キャンセル（カートに戻る）</MDBBtn>
            <MDBBtn onClick={this.modalEmptyItemClose} color={'danger'} data-id={1} className="text-nowrap">削除して上長承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDelivery} toggle={this.modalDeliveryClose}>
          <MDBModalHeader toggle={this.modalDeliveryClose}>納品先が指定されていません。</MDBModalHeader>
          <MDBModalBody>
            アドレス帳から納品先が指定されていません。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeliveryClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalDeliveryDirect} toggle={this.modalDeliveryDirectClose}>
          <MDBModalHeader toggle={this.modalDeliveryDirectClose}>未入力項目があります。</MDBModalHeader>
          <MDBModalBody>
            直接入力の場合は全項目を入力してから「上長承認依頼ボタン」をクリックしてください。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.modalDeliveryDirectClose} color="" style={{backgroundColor: '#2D486A', color: '#ffffff'}}>確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}
                          className="text-center default-color white-text"
                          titleClass="w-100 font-weight-bold"
          >承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
            発注を承認しますか？<br />
            承認すると発注が確定し、販売会社に通知されます。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="default" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
          <MDBModalHeader toggle={this.rejectClose}
                          className="text-center warning-color-dark white-text"
                          titleClass="w-100 font-weight-bold"
          >却下（担当者に差戻）</MDBModalHeader>
          <MDBModalBody>
            発注を却下（担当者に差戻）しますか？<br />
            本発注書での注文はできなくなります。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.rejectClose} color="danger" data-id={this.state.rejectId}>却下（担当者に差戻）する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default Cart;
