import React from "react";
import {
    MDBSpinner,
    MDBCol,
    MDBRow,
    MDBFormInline,
    MDBBtn,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBChip,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBIcon,
} from 'mdbreact';
import "./OrderTableList.css";
import order from "./Order";
import firebase from "../../firebase";
import DataGrid, {
    Column,
    ColumnChooser,
    Paging,
    FilterRow, ColumnFixing, Scrolling,Export,Pager,ColumnChooserSearch,Sorting
} from "devextreme-react/data-grid";
import {Workbook} from 'exceljs';
import {saveAs} from "file-saver";
import {exportDataGrid} from 'devextreme/excel_exporter';
import Typesense from "typesense";

const TYPESENSE_CONFIG = {
    nodes: [
        {
            host: process.env.REACT_APP_TYPESENSE_HOST,
            port: "443",
            protocol: "https",
        },
    ],
    apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);
class OrderTableListV2 extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null
        this.state = {
            loading: false,
            dbOrders: [],
            last: null,
            sortType: 'desc',
            orderType: '',
            notMore: false,
            orderPerPage: 12,
            userType: "",
            selectedOrderList: [],
            searchwordA: '',
            algoriaPage: 1,
            algolia: false,
            pageIndex: 0,
            isSearch: false,
            checkAll: true,
        }
    }

    sortChange = (val) => {
        this.setState({last: null, notMore: false, sortType: val, dbOrders: [], algoriaPage: 1,isSearch: false}, () => {
            if (this.state.algolia) {
                this.getOrdersA(this.props, true, this.state.searchwordA)
            } else {
                this.getOrders(this.props, true);
            }
        })
    }

    selectOrderType = (e) => {
        this.setState({last: null, notMore: false, orderType: e.target.value * 1, dbOrders: [], algoriaPage: 1,isSearch: false}, () => {
            if (this.state.algolia) {
                this.getOrdersA(this.props, true, this.state.searchwordA)
            } else {
                this.getOrders(this.props, true);
            }
        })
    }

    clearFilter = () => {
        this.setState({
            last: null,
            notMore: false,
            orderType: '',
            sortType: 'desc',
            searchwordA: '',
            algolia: false,
            dbOrders: [],
            algoriaPage: 1,
            isSearch: false
        }, () => {
            if (this.state.algolia) {
                this.getOrdersA(this.props, true, this.state.searchwordA)
            } else {
                this.getOrders(this.props, true);
            }

        })
    }

    getOrdersA = async (props, newOrg, word) => {
        this.dataGrid.instance.pageIndex(0)
        if (this.state.loading) {
            return;
        }

        if (this.state.notMore) {
            return;
        }

        let toOrg = "";
        let fromOrg = "";
        let urigawa = false;
        let userType = "";

        if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
            userType = "seller";
            toOrg = this.props.user.organization;
            fromOrg = props.org.id;
            urigawa = true;
        }
        if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
            userType = "maker";
            toOrg = this.props.user.organization;
            fromOrg = props.org.id;
            urigawa = true;
        }
        if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
            userType = "sellerBuyer";
            toOrg = props.org.id;
            fromOrg = this.props.user.organization;
        }
        if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
            userType = "buyer";
            toOrg = props.org.id;
            fromOrg = this.props.user.organization;
        }

        // console.log(userType)

        if (fromOrg == "" || toOrg == "") {
            return;
        }

        let tempResults = [];
        this.setState({loading: true, userType, algolia: true});
        let filters = ``;

        // indexName = ;
        // client.initIndex("P4Order")
        let sort_by = 'sortDate:desc';
        if(props.userOrg && props.userOrg.maker){
            switch (this.state.orderType) {
                case 1:
                    if (this.state.sortType === 'desc') {
                        //indexName = 'sort_latestUpdate_desc'
                        sort_by = 'latestUpdate._seconds:desc'
                    } else {
                        //indexName = 'sort_latestUpdate_asc';
                        sort_by = 'latestUpdate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                    break;
                case 2:
                    if (this.state.sortType === 'desc') {
                        //indexName = 'sort_sellerConfirmDate_desc'
                        sort_by = 'sellerConfirmDate._seconds:desc'
                    } else {
                        //indexName = 'sort_sellerConfirmDate_asc'
                        sort_by = 'sellerConfirmDate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:true`
                    break;
                case 3:
                    if (this.state.sortType === 'desc') {
                        //indexName = 'sort_sortDate_desc'
                        sort_by = 'sortDate._seconds:desc'
                    } else {
                        //indexName = 'sort_sortDate_asc'
                        sort_by = 'sortDate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:false`
                    break;
                case 4:
                    if (this.state.sortType === 'desc') {
                        sort_by = 'latestUpdate._seconds:desc'
                    } else {
                        sort_by = 'latestUpdate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                    break;
                case 5:
                    if (this.state.sortType === 'desc') {
                        sort_by = 'latestUpdate._seconds:desc'
                    } else {
                        sort_by = 'latestUpdate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:false`
                    break;
                case 6:
                    if (this.state.sortType === 'desc') {
                        sort_by = 'latestUpdate._seconds:desc'
                    } else {
                        sort_by = 'latestUpdate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:true`
                    break;
                default:
                    if (this.state.sortType === 'desc') {
                        sort_by = 'sortDate._seconds:desc'
                    } else {
                        sort_by = 'sortDate._seconds:asc'
                    }
                    filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                    break;
            }
        } else {
            if(urigawa){
                switch (this.state.orderType) {
                    case 1:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                        break;
                    case 2:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'sellerConfirmDate._seconds:desc'
                        } else {
                            sort_by = 'sellerConfirmDate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:true`
                        break;
                    case 3:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'sortDate._seconds:desc'
                        } else {
                            sort_by = 'sortDate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:false`
                        break;
                    case 4:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                        break;
                    case 5:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:false`
                        break;
                    case 6:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:true`
                        break;
                    default:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'sortDate._seconds:desc'
                        } else {
                            sort_by = 'sortDate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
                        break;
                }
            } else {
                switch (this.state.orderType) {
                    case 1:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newReject:false && newApply:false`
                        break;
                    case 2:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'sortDate._seconds:desc'
                        } else {
                            sort_by = 'sortDate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newApply:true`
                        break;
                    case 3:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newReject:true`
                        break;
                    case 4:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
                        break;
                    case 5:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && isShipped:false`
                        break;
                    case 6:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'latestUpdate._seconds:desc'
                        } else {
                            sort_by = 'latestUpdate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && isShipped:true`
                        break;
                    default:
                        if (this.state.sortType === 'desc') {
                            sort_by = 'sortDate._seconds:desc'
                        } else {
                            sort_by = 'sortDate._seconds:asc'
                        }
                        filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
                        break;
                }
            }
        }
        // console.log(indexName, word, filters, newOrg)
        let searchParameters = {
            'q'         : word,
            'query_by'  : '*',
            'filter_by' : filters,
            'sort_by'   : sort_by,
            'page'      : this.state.algoriaPage,
            'per_page'  : this.state.orderPerPage
        }
        await typesense.collections('orders').documents().search(searchParameters).then((docs) => {
            //docs.page=this.state.algoriaPage,
            docs.hits.forEach((data) => {
                tempResults.push(data.document);
            })
        });
        /*await client.initIndex(indexName)
            .search(word, {
                page: this.state.algoriaPage,
                filters: filters
            })
            .then(function (responses) {
                tempResults = responses.hits;
                // console.log(tempResults)
            });*/

        let collection;
        if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
            // manufacturer
            collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
        } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/Purchase mode
            collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
            // purchasing user
            collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/sales mode
            collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
        }

        let nextPage = this.state.algoriaPage + 1;
        let orders = this.state.dbOrders
        if (newOrg) {
            orders = [];
        }

        let notMore = false;
        if (tempResults.length === 0) {
            notMore = true
        }

        // console.log(tempResults.length, tempResults)

        for (let i = 0; i < tempResults.length; i++) {
            let data = tempResults[i];

            let cart = data.cart;
            if (!data.newApply && !data.newReject) {
                delete cart['deletedItem'];
                delete cart['deletedText'];
                await Promise.all(cart.items.map(async (item) => {
                    let itemSell = await collection.doc(item.id).get();
                    let itemSellData = itemSell.data();

                    delete item['deletedItem'];
                    delete item['deletedText'];

                    if (!itemSellData) {
                        cart.deletedItem = true;
                        cart.deletedText = '販売終了商品が含まれています';
                        item.deletedItem = true;
                        item.deletedText = '販売終了商品が含まれています';
                    } else if (itemSellData && !itemSellData['price'] || itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                        cart.deletedItem = true;
                        cart.deletedText = '要見積商品が含まれています';
                        item.deletedItem = true;
                        item.deletedText = '要見積商品が含まれています';
                    } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                        cart.deletedItem = true;
                        cart.deletedText = '要見積商品が含まれています';
                        item.deletedItem = true;
                        item.deletedText = '要見積商品が含まれています';
                    } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                        let expiration_date = itemSellData['price'][0]['expiration_date'];
                        let expDate = expiration_date.toDate();

                        if ((expDate.getTime() - this.props.dateNow) < 0) {
                            cart.deletedItem = true;
                            cart.deletedText = '価格有効期限切れ要見積';
                            item.deletedItem = true;
                            item.deletedText = '価格有効期限切れ要見積';
                        } else {
                            item.price = itemSellData['price'][0]['price'];
                        }
                    } else {
                        item.price = itemSellData['price'][0]['price'];
                    }

                    // if (itemSellData && itemSellData['price']) {
                    //   item.price = itemSellData['price'][0]['price'];
                    // }
                }));
            }

            orders.push(data)
        }

        // console.log(orders)
        // let newResults = orders.concat(tempResults);

        this.setState({
            algoriaPage: nextPage,
            dbOrders: orders,
            loading: false,
            notMore: notMore,isSearch: false
        });
    }

    getOrders = async (props, newOrg) => {
        // console.log("###### getOrders ########", props, newOrg)
        if (this.state.loading) {
            return;
        }

        if (this.state.notMore) {
            return;
        }

        let toOrg = "";
        let fromOrg = "";
        let urigawa = false;
        let userType = "";

        if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
            userType = "seller";
            toOrg = this.props.user.organization;
            fromOrg = props.org.id;
            urigawa = true;
        }
        if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
            userType = "maker";
            toOrg = this.props.user.organization;
            fromOrg = props.org.id;
            urigawa = true;
        }
        if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
            userType = "sellerBuyer";
            toOrg = props.org.id;
            fromOrg = this.props.user.organization;
        }
        if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
            userType = "buyer";
            toOrg = props.org.id;
            fromOrg = this.props.user.organization;
        }

        // console.log(userType)

        if (fromOrg == "" || toOrg == "") {
            return;
        }

        let first;
        this.setState({loading: true, userType, algolia: false})

        if (props.userOrg && props.userOrg.maker) {
            // console.log()
            if (this.state.last) {
                switch (this.state.orderType) {
                    case 1:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            // .where("newReject", "==", false)
                            // .where("newApply", "==", false)
                            .orderBy('latestUpdate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    case 2:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("sellerConfirm", "==", true)
                            .orderBy('sellerConfirmDate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    case 3:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("sellerConfirm", "==", false)
                            .orderBy('sortDate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    case 4:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .orderBy('latestUpdate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    case 5:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("isShipped", "==", false)
                            .orderBy('latestUpdate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    case 6:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("isShipped", "==", true)
                            .orderBy('latestUpdate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage);
                        break;
                    default:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .orderBy('sortDate', this.state.sortType)
                            .startAfter(this.state.last)
                            .limit(this.state.orderPerPage)
                        break;
                }

            } else {
                switch (this.state.orderType) {
                    case 1:
                        first = this.props.db.collection("orders")
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            // .where("newApply", "==", false)
                            // .where("newReject", "==", false)
                            .orderBy('latestUpdate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    case 2:
                        // console.log("this maker load")
                        first = this.props.db.collection("orders")
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            // .where("newApply", "==", true)
                            .where("sellerConfirm", "==", true)
                            .orderBy('sellerConfirmDate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    case 3:
                        // console.log("this maker load 3")
                        first = this.props.db.collection("orders")
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            // .where("newReject", "==", true)
                            .where("sellerConfirm", "==", false)
                            .orderBy('sortDate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    case 4:
                        first = this.props.db.collection("orders")
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .orderBy('latestUpdate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    case 5:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("isShipped", "==", false)
                            .orderBy('latestUpdate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    case 6:
                        first = this.props.db.collection('orders')
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .where("isShipped", "==", true)
                            .orderBy('latestUpdate', this.state.sortType)
                            .limit(this.state.orderPerPage);
                        break;
                    default:
                        first = this.props.db.collection("orders")
                            .where("fromOrganization", "==", fromOrg)
                            .where("toOrganization", "==", toOrg)
                            .where("applied", "==", true)
                            .orderBy('sortDate', this.state.sortType)
                            .limit(this.state.orderPerPage)
                        break;
                }

            }
        } else {
            if (urigawa) {
                if (this.state.last) {
                    switch (this.state.orderType) {
                        case 1:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newApply", "==", false)
                                // .where("newReject", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 2:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newApply", "==", true)
                                .where("sellerConfirm", "==", true)
                                .orderBy('sellerConfirmDate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 3:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newReject", "==", true)
                                .where("sellerConfirm", "==", false)
                                .orderBy('sortDate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 4:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 5:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true)
                                .where("isShipped", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 6:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true)
                                .where("isShipped", "==", true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        default:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                .orderBy('sortDate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                    }

                } else {
                    // console.log('seller list', this.state.orderType)
                    switch (this.state.orderType) {
                        case 1:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newApply", "==", false)
                                // .where("newReject", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 2:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newApply", "==", true)
                                .where("sellerConfirm", "==", true)
                                .orderBy('sellerConfirmDate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 3:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                // .where("newReject", "==", true)
                                .where("sellerConfirm", "==", false)
                                .orderBy('sortDate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 4:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 5:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true)
                                .where("isShipped", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 6:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true)
                                .where("isShipped", "==", true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        default:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("applied", "==", true) // 承認済みのものだけ表示
                                .orderBy('sortDate', this.state.sortType)
                                .limit(this.state.orderPerPage)
                            break;
                    }
                }
            } else {
                if (this.state.last) {
                    switch (this.state.orderType) {
                        case 1:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newReject", '==', false)
                                .where("newApply", '==', false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 2:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newApply", '==', true)
                                .orderBy('sortDate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 3:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newReject", '==', true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 4:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 5:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("isShipped", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        case 6:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("isShipped", "==", true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                        default:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .orderBy('sortDate', this.state.sortType)
                                .startAfter(this.state.last)
                                .limit(this.state.orderPerPage);
                            break;
                    }


                } else {
                    console.log('this order load', this.state.orderType)
                    switch (this.state.orderType) {
                        case 1:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newReject", '==', false)
                                .where("newApply", '==', false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 2:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newApply", '==', true)
                                .orderBy('sortDate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 3:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("newReject", '==', true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 4:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 5:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("isShipped", "==", false)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        case 6:
                            first = this.props.db.collection('orders')
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .where("isShipped", "==", true)
                                .orderBy('latestUpdate', this.state.sortType)
                                .limit(this.state.orderPerPage);
                            break;
                        default:
                            first = this.props.db.collection("orders")
                                .where("fromOrganization", "==", fromOrg)
                                .where("toOrganization", "==", toOrg)
                                .orderBy('sortDate', this.state.sortType)
                                .limit(this.state.orderPerPage)
                            break;
                    }

                }
            }
        }

        let collection;
        if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
            // manufacturer
            collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
        } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/Purchase mode
            collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
            // purchasing user
            collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/sales mode
            collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
        }

        let o = await first.get();

        if (o.docs.length > 0 && collection) {
            let last = o.docs[o.docs.length - 1];

            this.setState({last: last});

            let orders = this.state.dbOrders;
            if (newOrg) {
                orders = [];
            }

            for (let i = 0; i < o.docs.length; i++) {
                let doc = o.docs[i];
                let data = doc.data();
                data.id = doc.id;

                // console.log(fromOrg, toOrg, data.id, data)

                let cart = data.cart;
                if (!data.newApply && !data.newReject) {
                    delete cart['deletedItem'];
                    delete cart['deletedText'];
                    await Promise.all(cart.items.map(async (item) => {
                        let itemSell = await collection.doc(item.id).get();
                        let itemSellData = itemSell.data();

                        delete item['deletedItem'];
                        delete item['deletedText'];

                        if (!itemSellData) {
                            cart.deletedItem = true;
                            cart.deletedText = '販売終了商品が含まれています';
                            item.deletedItem = true;
                            item.deletedText = '販売終了商品が含まれています';
                        } else if (itemSellData && !itemSellData['price'] || itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                            cart.deletedItem = true;
                            cart.deletedText = '要見積商品が含まれています';
                            item.deletedItem = true;
                            item.deletedText = '要見積商品が含まれています';
                        } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                            cart.deletedItem = true;
                            cart.deletedText = '要見積商品が含まれています';
                            item.deletedItem = true;
                            item.deletedText = '要見積商品が含まれています';
                        } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                            let expiration_date = itemSellData['price'][0]['expiration_date'];
                            let expDate = expiration_date.toDate();

                            if ((expDate.getTime() - this.props.dateNow) < 0) {
                                cart.deletedItem = true;
                                cart.deletedText = '価格有効期限切れ要見積';
                                item.deletedItem = true;
                                item.deletedText = '価格有効期限切れ要見積';
                            } else {
                                item.price = itemSellData['price'][0]['price'];
                            }
                        } else {
                            item.price = itemSellData['price'][0]['price'];
                        }
                        // if (itemSellData && itemSellData['price']) {
                        //   item.price = itemSellData['price'][0]['price'];
                        // }
                    }));
                }


                orders.push(data)
            }

            this.setState({dbOrders: orders, loading: false,isSearch: false}, () => {
                let wrapper1 = document.getElementById('wrapper1');
                let div1 = document.getElementById('div1');
                let wrapper2 = document.getElementsByClassName('table-responsive')[0];
                let div2 = document.getElementById('div2');

                if (wrapper1 && div1 && wrapper2 && div2) {
                    div1.style.width = div2.offsetWidth + 'px';

                    wrapper1.onscroll = function () {
                        wrapper2.scrollLeft = wrapper1.scrollLeft;
                    };
                    wrapper2.onscroll = function () {
                        wrapper1.scrollLeft = wrapper2.scrollLeft;
                    };
                }

            })
        } else {
            this.setState({notMore: true, loading: false});
            if (newOrg === true) {
                this.setState({dbOrders: []})
            }
        }

    }

    componentDidMount() {
        //this.getOrders();
        this.getOrders(this.props);
        this.props.loadBadge();

        //window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            last: null,
            notMore: false,
            algolia: false,
            searchwordA: '',
            isSearch: false,
            algoriaPage: 1,
            dbOrders: [],
            sortType: 'desc',
            orderType: ''
        }, () => {
            this.getOrders(nextProps, true);
            // console.log('loadBadge willReceive')
            this.props.loadBadge();
        });
    }

    componentWillUnmount() {
        //window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
    }

    /*watchCurrentPosition() {
        if (window.innerHeight + this.scrollTop() + 20 >= document.body.clientHeight) {
            console.log('reach end');
                this.onReachEnd();
           console.log(' window.innerHeight + this.scrollTop() + 20', window.innerHeight)
            window.scrollTo(0, window.innerHeight -20)
        }
    }*/

    scrollTop() {
        return Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
        );
    }

    onReachEnd = () => {
        if (this.state.loading === false) {
            if (this.state.algolia) {
                this.getOrdersA(this.props, false, this.state.searchwordA);

            } else {
                this.getOrders(this.props);
            }
        }


    }

    detail = (id, index) => {
        let orders = this.state.dbOrders;
        let order = orders[index];
        // console.log('order', order);
        if (this.state.userType === "buyer" || this.state.userType === "sellerBuyer") {
            let setObject = {};
            if (order.readBuyer === false) {
                setObject.readBuyer = true;
                this.props.readOrder(1);
            }
            if (order.readComment === false) {
                setObject.readComment = true;
            }

            // this.dbOrdersUpdate(id,"readBuyer",true);
            // this.dbOrdersUpdate(id,"readComment",true);

            let ordersRef = this.props.db.collection('orders').doc(id);
            ordersRef.set(setObject, {merge: true})
                .then(() => {
                    // this.props.cProps.history.push("/order/" + id);
                    window.open("/order/" + id, "_blank");
                })
        }
        if (this.state.userType === 'seller' || this.state.userType === 'maker') {
            let setObject = {};
            if (order.readSeller === false) {
                setObject.readSeller = true;
            }
            let ordersRef = this.props.db.collection('orders').doc(id);
            ordersRef.set(setObject, {merge: true})
                .then(() => {
                    window.open("/order/" + id, "_blank");
                })
        } else {
            window.open("/order/" + id, "_blank");
        }
    }


    openFile = (e) => {
        console.log("openFile:", e.currentTarget.dataset.id);
        /*
        let esId = e.currentTarget.dataset.id;

        let ordersRef = this.props.db.collection('orders').doc(esId);
        ordersRef.set({
          visited: {[this.props.user.id]: true},
        }, { merge: true });

        if(this.props.user.authorizer){
          ordersRef.set({
            authorizer_visited: true,
          }, { merge: true });
        }
        this.dbOrdersUpdate(esId,"visited",{[this.props.user.id]:true});
        */
    }

    applyFile = (e) => {
        console.log("applyFile:", e.currentTarget.dataset.id);
        let esId = e.currentTarget.dataset.id;
        let esTitle = e.currentTarget.dataset.title;
        this.setState({
            applyModal: true,
            applyId: esId,
            applyTitle: esTitle,

        })
    }
    rejectFile = (e) => {
        console.log("rejectFile:", e.currentTarget.dataset.id);
        let esId = e.currentTarget.dataset.id;
        let esTitle = e.currentTarget.dataset.title;
        this.setState({
            rejectModal: true,
            rejectId: esId,
            rejectTitle: esTitle,
        })
    }
    applyClose = async (e) => {
        this.setState({
            applyModal: false,
            applyId: null,
            applyTitle: null,
        });

        let esId = e.currentTarget.dataset.id;
        let collection;
        if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
            // manufacturer
            collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
        } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/Purchase mode
            collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
            // purchasing user
            collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/sales mode
            collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
        }

        if (esId && collection) {

            let o = await this.props.db.collection('orders').doc(esId).get();
            let data = o.data();
            let cart = data.cart || {};

            await Promise.all(cart.items.map(async (item) => {
                let itemSell = await collection.doc(item.id).get();
                let itemSellData = itemSell.data();
                if (itemSellData && itemSellData['price']) {
                    item.price = itemSellData['price'][0]['price'];
                }
            }));

            let cartRef = this.props.db.collection('carts').doc(data.fromUser)
                .collection('carts').doc(data.toOrganization);
            cartRef.set({
                [data.cartNo]: {},
            }, {merge: true});

            let deliveryAddress = {}
            if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
                deliveryAddress = cart.deliveryAddress;
            }

            if (cart.orderBasyo === '納品先を直接入力する') {
                deliveryAddress = {
                    propertyName: cart.orderPropertyName,
                    postCode: cart.orderZipcode,
                    address1: cart.orderAddr1,
                    address2: cart.orderAddr2,
                    officeName: cart.orderJigyosyo,
                    personInCharge: cart.orderTanto,
                    phoneNumber: cart.orderPhone
                }
            }

            if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
                Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
                cart.otasukeDeliveryAddress.postCode &&
                cart.otasukeDeliveryAddress.address1 &&
                cart.otasukeDeliveryAddress.address2 &&
                cart.otasukeDeliveryAddress.officeName &&
                cart.otasukeDeliveryAddress.personInCharge &&
                cart.otasukeDeliveryAddress.phoneNumber
            ) {
                deliveryAddress = cart.otasukeDeliveryAddress
            }

            let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
                .collection('carts_info').doc(data.toOrganization);
            if (Object.keys(deliveryAddress).length > 0) {
                cartInfoRef.set({
                    lastDeliveryAddress: deliveryAddress,
                    update: firebase.firestore.Timestamp.fromDate(new Date())
                }, {merge: true})
            }

            let ordersRef = this.props.db.collection('orders').doc(esId);

            let username = this.props.user.last_name + ' ' + this.props.user.first_name
            await ordersRef.set({
                apply: {[this.props.user.id]: true},
                applied: true,
                newApply: true,
                cart,
                applyUserName: username,
                orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
                latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
                sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
            }, {merge: true});

            this.props.loadBadge();

            this.clearFilter()

            if (this.props.user.authorizer) {
                ordersRef.set({
                    authorizer_visited: true,
                }, {merge: true});

                this.dbOrdersUpdate(esId, "authorizer_visited", true);
            }

            this.dbOrdersUpdate(esId, "applied", true);
            this.dbOrdersUpdate(esId, "newApply", true);
            this.dbOrdersUpdate(esId, "apply", {key: 1});
            this.dbOrdersUpdate(esId, "applyUserName", username);
            this.dbOrdersUpdate(esId, "orderDate", firebase.firestore.Timestamp.fromDate(new Date()));
            this.dbOrdersUpdate(esId, "latestUpdate", firebase.firestore.Timestamp.fromDate(new Date()));
            this.dbOrdersUpdate(esId, "sortDate", firebase.firestore.Timestamp.fromDate(new Date()));

        }


    }
    rejectClose = async (e) => {
        this.setState({
            rejectModal: false,
            rejectId: null,
            rejectTitle: null,
        });

        let esId = e.currentTarget.dataset.id;
        let collection;
        if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
            // manufacturer
            collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
        } else if (this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/Purchase mode
            collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
            // purchasing user
            collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
        } else if (this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
            // Distributor/sales mode
            collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
        }

        if (esId && collection) {
            let ordersRef = this.props.db.collection('orders').doc(esId);

            let o = await ordersRef.get();
            let data = o.data();
            let cart = data.cart || {};

            await Promise.all(cart.items.map(async (item) => {
                let itemSell = await collection.doc(item.id).get();
                let itemSellData = itemSell.data();
                if (itemSellData && itemSellData['price']) {
                    item.price = itemSellData['price'][0]['price'];
                }
            }));

            let cartRef = this.props.db.collection('carts').doc(data.fromUser)
                .collection('carts').doc(data.toOrganization);

            let setWithMerge = cartRef.set({
                [data.cartNo]: {
                    requested: false,
                    rejected: true,
                },
            }, {merge: true});

            let username = this.props.user.last_name + ' ' + this.props.user.first_name
            let t = this;
            await ordersRef.set({
                reject: {[this.props.user.id]: true},
                rejectUserName: username,
                newReject: true,
                cart,
                rejectDate: firebase.firestore.Timestamp.fromDate(new Date()),
                latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
                sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
            }, {merge: true})

            this.props.loadBadge();

            t.clearFilter()

            this.dbOrdersUpdate(esId, "reject", {key: 1});
            this.dbOrdersUpdate(esId, "newReject", true);
            this.dbOrdersUpdate(esId, "rejectUserName", username);
            this.dbOrdersUpdate(esId, "rejectDate", firebase.firestore.Timestamp.fromDate(new Date()),);
            this.dbOrdersUpdate(esId, "latestUpdate", firebase.firestore.Timestamp.fromDate(new Date()));
            this.dbOrdersUpdate(esId, "sortDate", firebase.firestore.Timestamp.fromDate(new Date()));
        }

    }

    waitFile = (e) => {
        console.log("waitFile:", e.currentTarget.dataset.id);
        let esId = e.currentTarget.dataset.id;
        let esTitle = e.currentTarget.dataset.title;
        this.setState({
            waitModal: true,
            waitId: esId,
            waitTitle: esTitle,
        })
    }
    waitClose = (e) => {
        this.setState({
            waitModal: false,
            waitId: null,
            waitTitle: null,
        })

        let esId = e.currentTarget.dataset.id;
        if (esId) {
            let ordersRef = this.props.db.collection('orders').doc(esId);
            ordersRef.set({
                wait: {[this.props.user.id]: true},
            }, {merge: true});
            this.dbOrdersUpdate(esId, "wait", {key: 1});
        }
    }

    dbOrdersUpdate = (id, target, value) => {
        let dbOrdersUpdated = this.state.dbOrders.map((es) => {
            let orderID = es.id;
            if (es.objectID) {
                orderID = es.objectID;
            }
            if (orderID === id) {
                if (target === 'visited') {
                    let visited = es.visited;
                    if (visited) {
                        es[target] = Object.assign(visited, value);
                    } else {
                        es[target] = value;
                    }
                } else {
                    es[target] = value;
                }
            }
            return es;
        })
        this.setState({
            dbOrders: dbOrdersUpdated,
        })
    }

    orderIsSelected = (docId) => {
        let filtered = this.state.selectedOrderList.filter(item => {
            if (item.objectID) {
                return item.objectID === docId
            } else {
                return item.id === docId
            }

        });
        return filtered.length > 0
    };

    orderSelectAll = (selected) => {
        this.setState({
            checkAll: !this.state.checkAll
        });
        let selectedOrderList = [];
        if (this.state.checkAll === true) {
            this.state.dbOrders.forEach((order, index) => {
                if (this.state.userType === "seller" && order.readSeller === false || this.state.userType === "buyer" && order.readBuyer === false || this.state.userType === "sellerBuyer" && order.readBuyer === false || this.state.userType === "maker" && order.readSeller === false) {
                    selectedOrderList.push(order);
                }
            });

            this.setState({
                selectedOrderList: selectedOrderList
            }, () => {
                // console.log(this.state.selectedOrderList)
            })
        } else {
            this.setState({
                selectedOrderList: selectedOrderList
            });
        }

    };

    orderSelect = (selected, order) => {
        let selectedOrderList;
        if (selected) {
            selectedOrderList = [...this.state.selectedOrderList, order];
        } else {
            selectedOrderList = this.state.selectedOrderList.filter(sOrder => {
                if (sOrder.objectID) {
                    return sOrder.objectID !== order.objectID
                } else {
                    return sOrder.id !== order.id
                }

            });
        }

        this.setState({
            selectedOrderList: selectedOrderList
        })
    }

    orderRead = () => {
        let batch = this.props.db.batch();

        this.state.selectedOrderList.forEach((doc) => {
            console.log(doc.id);
            let updateData = {};
            let orderID = doc.id;
            if (doc.objectID) {
                orderID = doc.objectID;
            }

            if (this.state.userType === "seller" && doc.readSeller === false || this.state.userType === "maker" && doc.readSeller === false) {
                updateData.readSeller = true;
                this.dbOrdersUpdate(orderID, "readSeller", true);
            }

            if (this.state.userType === "buyer" && doc.readBuyer === false || this.state.userType === "sellerBuyer" && doc.readBuyer === false) {
                updateData.readBuyer = true;
                this.props.readOrder(this.state.selectedOrderList.length);
                this.dbOrdersUpdate(orderID, "readBuyer", true);
            }

            const docRef = this.props.db.collection("orders").doc(orderID);
            if (Object.keys(updateData).length > 0)
                batch.update(docRef, updateData);
        })

        batch.commit().then(() => {
            console.log(`updated all documents inside "orders"`);
            this.setState({selectedOrderList: []})
        });
    }

    handleSearchwordAChange = (e) => {
        this.setState({
            searchwordA: e.currentTarget.value,
            isSearch: true,
        });
    }

    handleSearchwordAKeyDown = (e) => {
        e.stopPropagation();
        let val = this.state.searchwordA;
        if (e.key === 'Enter') {
            this.setState({
                dbOrders: [],
                last: null,
                notMore: false,
                algoriaPage: 1,
                isSearch: false
            }, () => {
                if (val) {
                    this.getOrdersA(this.props, true, val);
                } else {
                    this.getOrders(this.props, true)
                }

            });
        }
    }

    checkShipping = (cart) => {
        // console.log('checkShipping', cart)
        let items = cart.items;
        let isShipped = true;

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let okurijyoNo = item.okurijyoNo;

            if (okurijyoNo) {
                let shippingDate = okurijyoNo.slice(-1)[0].shippingDate;
                let value = okurijyoNo.slice(-1)[0].value;

                if (!shippingDate || !value) {
                    isShipped = false;
                    break;
                }
            } else if (item.divided) {

            } else {
                isShipped = false;
                break;
            }

            if (!isShipped) {
                break;
            }
        }

        if (isShipped) {
            return <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B'}}/>
        } else {
            return <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547'}}/>
        }

    }
    onCellPreparedHandler = (e) => {
        if (e.rowType === 'header') {
            e.cellElement.wordWrapEnabled = true;
            e.cellElement.style.color = "#fff";
            e.cellElement.style.alignment = "middle";
            e.cellElement.style.verticalAlign = "middle";
            e.cellElement.style.backgroundColor = "#2E75B6";
           // e.cellElement.style.alignSelf = "center";
        }
        if (e.rowType === 'data') {
            e.cellElement.style.verticalAlign = "middle";
        }

    }
     onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data') {
                    //console.log('gridCell', gridCell,window.location);
                    if (gridCell.column.dataField === '詳細') {
                        excelCell.value = { text: '表示',hyperlink:window.location.origin + '/order/' + gridCell.data.id};
                        excelCell.font = { color: { argb: '2bbbad' }, underline: true };
                        excelCell.alignment = { horizontal: 'left' };
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'OrderList' + firebase.firestore.Timestamp.now().toDate() + '.xlsx');
            });
        });
        e.cancel = true;
    }
    n =(n)=> {
        return n > 9 ? "" + n : "0" + n;
    }
    renderCell = (data,type) => {
        let order = data.data;
        let returnCell = ''
        let checked = this.orderIsSelected(order.id);
        let bold = this.state.userType === "seller" && order["readSeller"] === false ||
            this.state.userType === 'buyer' && order["readBuyer"] === false ||
            this.state.userType === 'maker' && order['readSeller'] === false ||
            this.state.userType === 'sellerBuyer' && order["readBuyer"] === false;
        let disabled = bold ? '' : 'disabled';
        let countDivided = 0;
        let countDividedConfirmed = 0;
        for (let i = 0; i < order.length; i++) {
            if (order[i].subDivided) {
                countDivided++;
                if (order[i].divideConfirm === true) {
                    countDividedConfirmed++;
                }
            }
        }
        let date = '';
        let time = '';
        let dateFilter = 'latestUpdate';
        let orderNoukiDate = '';
        let toVisited = '';
        let kaigawa = false;
        let urigawa = false;
        let title = order['cart'].items[0].name;
        let count = 0;
        let total = 0;
        let divided = false;
        let dividedText = '分納';
        let visited = '';
        let apply = '';
        let actionUser = '';
        let approveUser = '';
        let noukiSDate = '';
        let noukiEDate = '';
        order['cart'].items.forEach((item, index) => {
            count += item.count;

            if (!item.deletedItem && !item.subDivided) {
                total += item.count * item.price;
            }

            if (item.divided) {
                divided = true;
            }
        });

        if (kaigawa) {
            dividedText = '分納';
        }

        if ((this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
            || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
        ) {
            kaigawa = true;
        }
        if ((this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
            || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
        ) {
            urigawa = true;
        }
        if (urigawa) {
            if (this.state.orderType === 2) {
                dateFilter = 'sellerConfirmDate';
            } else if (this.state.orderType === 3) {
                dateFilter = 'sortDate';
            } else if (!this.state.orderType) {
                dateFilter = 'sortDate';
            }
        } else {
            if (this.state.orderType === 2) {
                dateFilter = 'sortDate';
            } else if (!this.state.orderType) {
                dateFilter = 'sortDate';
            }
        }
        if (this.state.algolia) {
            if (order[dateFilter]) {
                let dDate = new Date(order[dateFilter]._seconds * 1000 + order[dateFilter]._nanoseconds / 1000000);
                // console.log('dateFilter',dateFilter, order[dateFilter], dDate)
                date = dDate.getFullYear() + '/'
                    + this.n(dDate.getMonth() + 1) + '/'
                    + this.n(dDate.getDate());
                time = this.n(dDate.getHours()) + ':'
                    + this.n(dDate.getMinutes());
            } else {
                let dDate = new Date(order['created']._seconds * 1000 + order['created']._nanoseconds / 1000000);
                date = dDate.getFullYear() + '/'
                    + this.n(dDate.getMonth() + 1) + '/'
                    + this.n(dDate.getDate());
                time = this.n(dDate.getHours()) + ':'
                    + this.n(dDate.getMinutes());
            }

            if (order['cart'].orderNouki === '日付指定' && order['cart'].orderNoukiDate) {
                let cDate = new Date(order['cart'].orderNoukiDate._seconds * 1000 + order['cart'].orderNoukiDate._nanoseconds / 1000000);
                orderNoukiDate = cDate.getFullYear() + '/'
                    + (cDate.getMonth() + 1) + '/'
                    + cDate.getDate() + '';
            }
        } else {
            // console.log('odf', order[dateFilter], dateFilter)
            if (order[dateFilter]) {
                //console.log('orderorder', order);
                let dDate = new Date(order[dateFilter].seconds * 1000 + order[dateFilter].nanoseconds / 1000000);
                date = dDate.getFullYear() + '/' +
                    this.n(dDate.getMonth() + 1) + '/'
                    + this.n(dDate.getDate());
                time = this.n(dDate.getHours()) + ':'
                    + this.n(dDate.getMinutes());
            } else {
                date = order['created'].toDate().getFullYear() + '/'
                    + this.n(order['created'].toDate().getMonth() + 1) + '/'
                    + this.n(order['created'].toDate().getDate());
                time = this.n(order['created'].toDate().getHours()) + ':'
                    + this.n(order['created'].toDate().getMinutes());
            }

                 if (order['cart'].orderNouki === '日付指定') {
                     let dDate = new Date(order['cart'].orderNoukiDate.seconds * 1000 + order['cart'].orderNoukiDate .nanoseconds / 1000000);
                     orderNoukiDate = dDate ? dDate.getFullYear() + '/'
                         + (dDate.getMonth() + 1) + '/'
                         + dDate.getDate() + '' : '';
                 }
        }

        if (urigawa) {
            // if(order['visited'] && order['visited'][this.props.user.id]) {
            //   approveUser = order['approveUser'];
            //   visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
            // } else {
            //   visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
            // }
            if (order['apply'] && Object.keys(order['apply']).length > 0) {
                actionUser = order['applyUserName'];
                apply =
                    <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">受注済</MDBChip>;
            } else if (order['reject'] && Object.keys(order['reject']).length > 0) {
                actionUser = order['rejectUserName'];
                apply = <MDBChip bgColor=" pink lighten-2" text="white"
                                 className="text-nowrap disabled">却下</MDBChip>;
            } else if (order['wait'] && Object.keys(order['wait']).length > 0) {
                apply = <MDBChip bgColor="warning-color" text="white"
                                 className="text-nowrap disabled">未受注</MDBChip>;
            }

            if (order['approveUser']) {
                approveUser = order['approveUser'];
            }

            if (order['sellerConfirm'] === true) {
                // approveUser = order['approveUser']
                toVisited = <MDBChip className="text-nowrap disabled">受注確認済</MDBChip>;
            } else if (order['sellerConfirm'] === false) {
                toVisited = <MDBChip bgColor="danger-color" text="white"
                                     className="text-nowrap disabled">受注未確認</MDBChip>;
            }
        }

        if (kaigawa) {
            if (order['authorizer_visited']) {
                visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
            } else {
                visited = <MDBChip bgColor="danger-color " text="white"
                                   className="text-nowrap disabled">未確認</MDBChip>;
            }

            if (order['visited'] && order['visited'][order['toUser']] || order['approveUser']) {
                approveUser = order['approveUser'];
                toVisited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
            } else {
                toVisited =
                    <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
            }

            if (order['apply'] && Object.keys(order['apply']).length > 0) {
                actionUser = order['applyUserName'];
                apply =
                    <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">発注済</MDBChip>;
            } else if (order['reject'] && Object.keys(order['reject']).length > 0) {
                actionUser = order['rejectUserName'];
                apply = <MDBChip bgColor=" pink lighten-2" text="white"
                                 className="text-nowrap disabled">却下</MDBChip>;
            } else if (order['wait'] && Object.keys(order['wait']).length > 0) {
                apply = <MDBChip bgColor="warning-color" text="white"
                                 className="text-nowrap disabled">承認待</MDBChip>;
                let d = [];
                let deletedDescription = '';

                order['cart'].items.forEach((item, index) => {
                    if (item.deletedItem) {
                        if (!d.includes(item.deletedText)) {
                            d.push(item.deletedText)
                            deletedDescription = <>{deletedDescription}<span
                                style={{color: 'red'}}>{item.deletedText}</span><br/></>
                        }
                    }
                });
                if (order['cart'].deletedItem) {
                    apply = <>{apply}<br/><span style={{color: 'red'}}>{deletedDescription}</span></>
                }
            }
        }

        if(type=== 'check') {
            returnCell = <div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} disabled={disabled} id={"checkbox"+data.rowIndex} onChange={(e) => this.orderSelect(e.target.checked, data.data)} /> </div>
        }
        else if (type === 'orderCell') {
            returnCell = <><span style={{overflowWrap: 'anywhere', fontWeight: bold ? 'bold' : ''}}>{order['cart'].orderNo!==undefined ? order['cart'].orderNo : ''}</span>
                {countDivided > 0 && <div className={(countDivided === countDividedConfirmed ? 'divideView' : 'divideNotConfirmed') + ' text-nowrap'}><span style={{marginRight: 10}}>{countDivided === countDividedConfirmed ? '分納' : '分納手続中'}</span><span className={'divideConfirmed'}>{countDividedConfirmed}</span>/<span className={'divideTotal'}>{countDivided}</span></div>}
                {order.readComment === false && <div><MDBIcon icon="comments" className="mr-1 commentIcon" /></div>}
                {order.readComment === true && <div><MDBIcon icon="comments" className="mr-1 commentIconGray" /></div>}
                <br/>
                <span style={{overflowWrap: 'anywhere', fontWeight: bold ? 'bold' : ''}}>{order['id']}</span>
            </>
        }
        else if(type === 'visitedCell') {
            returnCell = <span className="text-nowrap">{order['toUserName']}<br/>{toVisited}<br/>{order['approveUser'] ? order['approveUser'] : ''}</span>
        }
        else if(type === 'dateCell') {
            returnCell = <span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{date}<br/>{time}</span>
        }
        else if(type === 'countCell') {
            returnCell =  <div style={{fontWeight: bold ? 'bold' : ''}}>{title} 計<span style={{fontSize: 18, fontWeight: 'bold'}}>{count.toLocaleString()}</span>点</div>
        }
        else if(type === 'totalCell') {
            returnCell = <div style={{fontWeight: bold ? 'bold' : '', textAlign: 'right'}}>{total.toLocaleString()}円</div>
        }
        else if(type === 'detailCell') {
            returnCell = <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={order.id} onClick={() => this.detail(order.id, data.rowIndex)}>表示</MDBChip>
        }
        else if(type === 'applyCell') {
            returnCell =  <span className="text-nowrap">{actionUser}{actionUser ? <br/> : ''}{apply}</span>
        }
        else if(type === 'orderNoukiCell') {
            returnCell =  <div style={{fontWeight: bold ? 'bold' : ''}}>{order['cart'].orderNouki == '最短' ? '最短' : order['cart'].orderNouki == '希望' ? order['cart'].orderNoukiDay + '日' : orderNoukiDate}</div>
        }
        else if(type === 'shippingCell') {
            returnCell = <div style={{display: 'flex', justifyContent: 'center'}}>
                {order['apply'] && Object.keys(order['apply']).length > 0 && this.checkShipping(order.cart)}
            </div>
        }
        else if(type === 'kaigawaVisitedCell') {
            returnCell = visited
        }
        else if(type === 'kaigawaEventCell') {
            if (kaigawa) {
                returnCell = ''
                if (this.props.user.authorizer) {
                    if (order['apply'] && Object.keys(order['apply']).length > 0) {
                    } else if (order['reject'] && Object.keys(order['reject']).length > 0) {
                    } else {
                        returnCell = (
                            <div className="text-nowrap">
                                {
                                    order.cart.deletedItem ?
                                        <MDBChip className="text-nowrap disabled" style={{cursor: 'default'}}
                                                 data-id={order.id}
                                                 data-title={title + ' 計' + count.toLocaleString() + '点'}>承認</MDBChip>
                                        :
                                        <MDBChip bgColor="default-color" text="white" className="text-nowrap"
                                                 data-id={order.id}
                                                 data-title={title + ' 計' + count.toLocaleString() + '点'}
                                                 onClick={this.applyFile}>承認</MDBChip>
                                }
                                <br/>
                                <MDBChip bgColor="warning-color-dark" text="white" className="text-nowrap"
                                         data-id={order.id} data-title={title + ' 計' + count.toLocaleString() + '点'}
                                         onClick={this.rejectFile}>却下</MDBChip>
                            </div>
                        )
                    }
                } else {
                    if ((order['wait'] && Object.keys(order['wait']).length > 0)
                        || (order['apply'] && Object.keys(order['apply']).length > 0)
                    ) {
                    } else {
                        if (order['toUser'] == this.props.user.id) {
                            returnCell = <MDBChip bgColor="default-color" text="white" className="text-nowrap"
                                                  data-id={order.id} data-title={order.title}
                                                  onClick={this.waitFile}>承認依頼</MDBChip>
                        }
                    }
                }
            }
        }
        return returnCell
    }

    render() {
        if (!this.props.org) {
            return (
                <>
                    取引先を選択してください。
                </>
            )
        }

        let kaigawa = false;
        let urigawa = false;
        if ((this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
            || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
        ) {
            kaigawa = true;
        }
        if ((this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
            || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
        ) {
            urigawa = true;
        }

        if (!kaigawa && !urigawa) {
            return (
                <>
                </>
            )
        }


        function n(n) {
            return n > 9 ? "" + n : "0" + n;
        }

        let selectOption = <>
            <option value={null}>すべて</option>
        </>;

        if (urigawa) {
            selectOption = <>
                <option value={null}>すべて</option>
                <option value={4}>最新更新順</option>
                <option value={3}>受注済</option>
                <option value={2}>受注確認済</option>
                <option value={5}>未出荷あり</option>
                <option value={6}>出荷済</option>
            </>
        } else if (kaigawa) {
            selectOption = <>
                <option value={null}>すべて</option>
                <option value={4}>最新更新順</option>
                <option value={1}>上長承認待ち</option>
                <option value={2}>発注済</option>
                <option value={3}>却下</option>
                <option value={5}>未出荷あり</option>
                <option value={6}>出荷済</option>
            </>
        }

        let dateLabel = '';
        // console.log(this.state.orderType)
        if (urigawa) {
            switch (this.state.orderType) {
                case 2:
                    dateLabel = "受注確認日時";
                    break;
                case 3:
                    dateLabel = "受注日時";
                    break;
                case 4:
                    dateLabel = "更新日時";
                    break;
                case 6:
                    dateLabel = "更新日時";
                    break;
                default:
                    dateLabel = "受注日時";
                    break;
            }
        }

        if (kaigawa) {
            switch (this.state.orderType) {
                case 1:
                    dateLabel = "承認依頼日時";
                    break;
                case 2:
                    dateLabel = "発注日時";
                    break;
                case 3:
                    dateLabel = "上長却下日時";
                    break;
                case 4:
                    dateLabel = "更新日時";
                    break;
                case 6:
                    dateLabel = "更新日時";
                    break;
                default:
                    dateLabel = "発注ステータス\n" +
                        "確定日時";
                    break;
            }
        }
        const orders = [] //!this.state.isSearch ? [] : this.dataGrid.props.dataSource;
        //if (!this.state.isSearch) {
            // console.log(urigawa, kaigawa, this.state.dbOrders);
            this.state.dbOrders.forEach((order, index) => {
                let title = order['cart'].items[0].name;
                let count = 0;
                let total = 0;
                order['cart'].items.forEach((item, index) => {
                    count += item.count;

                    if (!item.deletedItem && !item.subDivided) {
                        total += item.count * item.price;
                    }

                });
                let data = {
                    'id': order['id'],
                    'readSeller': order["readSeller"],
                    'readBuyer': order["readBuyer"],
                    'sellerBuyer': order["sellerBuyer"],
                    'cart': order["cart"],
                    //'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} disabled={disabled} id={"checkbox"+index} onChange={(e) => this.orderSelect(e.target.checked, order)} /> </div>,
                    'orderNo': order['id'].concat('\n' + order['cart'].orderNo),
                    'created' : order['created'],
                    'latestUpdate' : order['latestUpdate'],
                    'sortDate' : order['sortDate'],
                    'apply' : order['apply'],
                    'reject' : order['reject'],
                    'rejectUserName' : order['rejectUserName'],
                    'approveUser' : order['approveUser'],
                    'sellerConfirm' : order['sellerConfirm'],
                    'authorizer_visited' : order['authorizer_visited'],
                    'visited' : order['visited'],
                    'applyUserName' : order['applyUserName'],
                    'wait' : order['wait'],
                    'count': title || ' ' || count.toLocaleString(),
                    'total': total.toLocaleString(),
                    'readComment' : order['readComment'],
                    'title' :  order['title'],
                   /* '発注日時': <span style={{fontWeight: bold ? 'bold' : ''}}
                                  className="text-nowrap">{date}<br/>{time}</span>,*/
                    //'date': date || ' ' || time,
                    //'受取者': <span className="text-nowrap">{order['toUserName']}<br/>{toVisited}<br/>{order['approveUser'] ? order['approveUser'] : ''}</span>,
                   // 'approveUser': order['toUserName'] || ' ' || toVisited || ' ' || (order['approveUser'] ? order['approveUser'] : ''),
                    //'納期回答': <div>{noukiSDate}{(noukiSDate || noukiEDate) && <>〜</> }{noukiEDate}</div>,
                };

                data['処理'] = <div style={{display: 'flex', justifyContent: 'center'}}>
                    {order['apply'] && Object.keys(order['apply']).length > 0 && this.checkShipping(order.cart)}
                </div>;
                orders.push(data);
                this.state.loading && this.dataGrid.instance.pageIndex(this.dataGrid.instance.pageCount());
            });

            // console.log(urigawa, kaigawa)

       // }
        return (
            <div>
                <h2>{urigawa ? '注文書一覧' : '発注書一覧'}</h2>
                <MDBRow>
                    <MDBCol size={5} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <MDBBtn color="default" style={{padding: 10}} flat={true} outline={true}
                                disabled={this.state.selectedOrderList.length === 0} onClick={() => this.orderRead()}>
                            <MDBIcon icon="envelope-open" className="mr-1"/> 既読にする
                        </MDBBtn>
                        <div className="input-group md-form form-sm form-1 pl-0" style={{width: 200}}>
                            <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search"/>
                </span>
                            </div>
                            <input
                                className="form-control my-0 py-1"
                                type="text"
                                placeholder="文字列検索"
                                aria-label="文字列検索"
                                onChange={this.handleSearchwordAChange}
                                onKeyDown={this.handleSearchwordAKeyDown}
                                value={this.state.searchwordA}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol size={7} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" id="materialInline1"
                                   name="inlineMaterialRadiosExample" checked={this.state.sortType === 'asc'}
                                   onClick={() => this.sortChange('asc')}/>
                            <label className="form-check-label" htmlFor="materialInline1">昇順</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" id="materialInline2"
                                   name="inlineMaterialRadiosExample" checked={this.state.sortType === 'desc'}
                                   onClick={() => this.sortChange('desc')}/>
                            <label className="form-check-label" htmlFor="materialInline2">降順</label>
                        </div>

                        <div className="form-check form-check-inline" style={{marginLeft: 20}}>
                            並べ替え
                        </div>

                        <div className="form-check form-check-inline">
                            <select className="browser-default custom-select" value={this.state.orderType}
                                    onChange={(e) => this.selectOrderType(e)}>
                                {selectOption}
                            </select>
                        </div>

                        <div className={'form-check form-check-inline'}>
                            <MDBBtn
                                color="default" style={{padding: 10}} flat={true} outline={true}
                                onClick={() => this.clearFilter()}>
                                リセット
                            </MDBBtn>
                        </div>


                    </MDBCol>
                </MDBRow>
                <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
                    <div id="div1" style={{height: 20}}>
                    </div>
                </div>
                { <DataGrid id={"div2"}
                                               keyExpr="id"
                                               noDataText="No Data"
                                               dataSource={orders}
                                               allowColumnResizing={true}
                                               wordWrapEnabled={true}
                                               ref={(ref) => (this.dataGrid = ref)}
                                               columnAutoWidth={false}
                                               style={{alignment: 'center', alignItems: 'center'}}
                                               width={'100%'}
                                               showBorders={false} sorting={{ascendingText:'昇順',descendingText:'降順',clearText:'並び替え解除'}}
                                               showColumnLines={false}
                                               showRowLines={true}
                    //onOptionChanged={this.handleOptionChange}
                    //remoteOperations={true}
                                               paging={{true: true,pageSize:12}}// renderAsync={true
                                               onCellPrepared={this.onCellPreparedHandler}
                    //allowColumnReordering={true}
                                               onExporting={this.onExporting}
                >     <Export enabled={true} allowExportSelectedData={false} texts={{exportAll:'すべてのデータを Excel にエクスポートする'}}/>
                    <Sorting mode="none" />
                    <Scrolling
                        mode={'standard'} showScrollbar={'always'} useNative={'false'}
                        renderAsync={true}
                        preloadEnabled = {true} rowRenderingMode={'virtual'} columnRenderingMode={'virtual'}
                    />
                    <ColumnChooser
                        enabled={true} height={"80%"} allowSearch={true}
                        mode="select" title={'列セレクター'}>
                        <ColumnChooserSearch
                            editorOptions={{ placeholder: '見出し検索', mode: 'text'}}
                            enabled={true}
                            timeout={800}
                        />
                    </ColumnChooser>
                    <ColumnFixing enabled={true} texts={{fix:'固定',leftPosition:'最左列に',rightPosition:'最右列に',unfix:'固定解除'}}/>
                    {/*<SearchPanel visible={true} highlightSearchText={true} />*/}
                    <FilterRow visible={true} operationDescriptions={{contains:'次の語を含む',notContains:'次の語を含まない',startsWith:'次の語から始まる',endsWith:'次の語で終わる',equal:'等しい',notEqual:'等しくない',}} resetOperationText={'リセット'} />
                    {/* <Paging defaultPageSize={12} />*/}
                      <Pager
                        visible={!this.state.loading ? true : false}
                        allowedPageSizes={[12]}
                        displayMode={'full'}
                        //showPageSizeSelector={true}
                        showInfo={true}
                        infoText={`{0}/{1} ページ (全 {2} 注文)`}
                        showNavigationButtons={true}/>
                    <Column
                        alignment="center"
                        caption={'チェックボックス'}
                        width={70} allowSorting={false} allowFiltering={false}
                        //dataField={'check'}
                        renderAsync={true}
                        headerCellRender={() => {
                            return <div style={this.state.checkAll ? {backgroundColor:'#fff',padding:0,marginLeft:-20,marginRight:-65,marginTop:-3,marginBottom:-8.5} : {}}><MDBInput label=" " type="checkbox" id="checkboxAll" checked={!this.state.checkAll} onChange={(e) => this.orderSelectAll(e.target.checked)}/></div>
                        }}
                        cellRender={(e) => this.renderCell(e, 'check')}
                    />
                    <Column
                        caption={"発注番号\n発注書ID"}
                        headerCellRender={(e) => {
                            return <div>発注番号<br/>発注書ID</div>
                        }}
                        width={100}
                        alignment="left"
                        //dataField={'cart.orderNo'}
                        renderAsync={false}
                        /*cellRender={(item) => {
                            return item.data['番号']
                        }}*/
                        cellRender={(e) => this.renderCell(e, 'orderCell')}
                    />
                    <Column
                        caption={dateLabel}
                        alignment="left"
                        width={90}
                        renderAsync={true}
                        //dataField={'date'}
                        /* cellRender={(item) => {
                             return item.data['発注日時']
                         }}*/
                        cellRender={(e) => this.renderCell(e, 'dateCell')}
                    />
                    <Column
                        headerCellRender={(e) => {
                            return <div>発注書受取者<br/>受注確認者</div>
                        }}
                        caption={(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller )  || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) ? '受取者/確認者' : '承認者'}
                        alignment="left"
                        width={200}
                        dataField={'approveUser'}
                        renderAsync={false}
                        /*cellRender={(item) => {
                            return item.data['受取者']
                        }}*/
                        cellRender={(e) => this.renderCell(e, 'visitedCell')}
                    />
                    <Column
                        caption="発注内容"
                        alignment="left"
                        dataField={'count'}
                        minWidth={370}
                        renderAsync={false}
                        /*cellRender={(item) => {
                            return item.data['タイトル']
                        }}*/
                        cellRender={(e) => this.renderCell(e, 'countCell')}
                    />
                    <Column
                        headerCellRender={(e) => {
                            return <div style={{textAlign: 'center'}}>金額</div>
                        }}
                        alignment="left"
                        caption={(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller )  || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) ? '合計金額' : '合計'}
                        width={80}
                        dataField={'total'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'totalCell')}
                    />
                    <Column
                        caption={(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller )  || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) ? '詳細表示' : '詳細'}
                        alignment="left"
                        width={80}
                        //dataField={'詳細'}
                        allowFiltering={false}
                        renderAsync={true}
                        /*cellRender={(item) => {
                            return item.data['詳細']
                        }}*/
                        cellRender={(e) => this.renderCell(e, 'detailCell')}
                    />
                    <Column
                        caption= {(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller )  || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) ? '受注ステータス' : (kaigawa ? '状態' : '受注')}
                        //{kaigawa ? '状態' : '受注'}
                        width={200}
                        alignment="left"
                        //dataField={'orders'}
                        allowFiltering={true}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'applyCell')}
                    />
                    <Column
                        caption="希望納期"
                        alignment="left"
                        width={80}
                        //dataField={'orderNouki'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'orderNoukiCell')}
                    />
                    <Column
                        headerCellRender={(e) => {
                            return <div style={{
                                width: 100,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <row style={{display: 'flex', justifyContent: 'center'}}>処理</row>
                                <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 10,
                                        backgroundColor: '#19A22B',
                                        marginRight: 5
                                    }}/>
                                    出荷済
                                </row>
                                <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 10,
                                        backgroundColor: '#FF3547',
                                        marginRight: 5
                                    }}/>
                                    未出荷あり
                                </row>
                            </div>
                        }}
                        caption={(this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller )  || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer) ? '出荷ステータス' : '処理'}
                        alignment="left" allowFiltering={false}
                        width={200}
                        visible={urigawa ? true : false}
                        showInColumnChooser={urigawa ? true : false}
                        //dataField={'処理'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'shippingCell')}
                    />

                    <Column
                        caption={'上長確認'}
                        alignment="left"
                        width={80} allowFiltering={false}
                        visible={kaigawa ? true : false}
                        showInColumnChooser={kaigawa ? true : false}
                        //dataField={'確認'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'kaigawaVisitedCell')}
                    />
                    <Column
                        caption={'操作'}
                        alignment="left"
                        width={80} allowFiltering={false}
                        visible={kaigawa ? true : false}
                        showInColumnChooser={kaigawa ? true : false}
                        //dataField={'操作'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'kaigawaEventCell')}
                    />
                    <Column
                        headerCellRender={(e) => {
                            return <div style={{
                                width: 100,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 10,
                                        backgroundColor: '#19A22B',
                                        marginRight: 5
                                    }}/>
                                    出荷済
                                </row>
                                <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: 10,
                                        backgroundColor: '#FF3547',
                                        marginRight: 5
                                    }}/>
                                    未出荷あり
                                </row>
                            </div>
                        }}
                        alignment="left"
                        width={100} allowFiltering={false}
                        visible={kaigawa ? true : false}
                        showInColumnChooser={kaigawa ? true : false}
                        //dataField={'処理'}
                        renderAsync={false}
                        cellRender={(e) => this.renderCell(e, 'shippingCell')}
                    />
                    <Paging enabled={true}/>
                </DataGrid>}
                <div style={{display:'flex',justifyContent:'flex-end'}} hidden={this.state.notMore || orders.length < 12 ? true : false}>
                    <MDBBtn
                        color="default" style={{padding: 10}} flat={true} outline={true}
                        onClick={this.onReachEnd}>
                        次のページ
                    </MDBBtn>
                </div>
                {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner/></div>}

                <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
                    <MDBModalHeader toggle={this.applyClose}
                                    className="text-center default-color white-text"
                                    titleClass="w-100 font-weight-bold"
                    >承認(発注確定)</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.applyTitle} の発注を承認しますか？<br/>
                        承認すると発注が確定し、販売会社に通知されます。<br/>
                        よろしいですか？
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
                        <MDBBtn onClick={this.applyClose} color="default"
                                data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
                    <MDBModalHeader toggle={this.rejectClose}
                                    className="text-center warning-color-dark white-text"
                                    titleClass="w-100 font-weight-bold"
                    >却下（担当者に差戻）</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.rejectTitle} の発注を却下（担当者に差戻）しますか？<br/>
                        本発注書での注文はできなくなります。<br/>
                        よろしいですか？
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
                        <MDBBtn onClick={this.rejectClose} color="danger"
                                data-id={this.state.rejectId}>却下（担当者に差戻）する</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.waitModal} toggle={this.waitClose}>
                    <MDBModalHeader toggle={this.waitClose}>承認依頼</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.waitTitle} の発注書から発注するために、上長に承認依頼をします。<br/>
                        よろしいですか？
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={this.waitClose} color="secondary">キャンセル</MDBBtn>
                        <MDBBtn onClick={this.waitClose} color="danger" data-id={this.state.waitId}>上長に承認依頼する</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>

            </div>
        )

    }
}


export default OrderTableListV2;
